import {Injectable} from '@angular/core';
import {Campaign} from '@app/lib/campaign/models/campaign';
import {PaymentOrder} from '@app/lib/payments/models/payment-order';
import {NextObserver, of, Subject} from 'rxjs';
import {first, switchMap} from 'rxjs/operators';
import {User, UserData} from '../models/user';
import {AuthService} from './auth.service';

declare global {
  interface Window {
    dataLayer: TagManagerEventData[];
  }
}

interface TagManagerEventData {
  [k: string]: string | number | boolean | Record<string, any>;
}

export interface GATransactionProduct {
  name: string;
  sku: string;
  category?: string;
  price: number;
  quantity: number;
}

export interface GATransaction {
  transactionId: string;
  transactionAffiliation?: string;
  transactionTotal: number;
  transactionShipping?: number;
  transactionTax?: number;
  transactionProducts?: GATransactionProduct[];
}

// noinspection DuplicatedCode
export class TagManagerEvent {
  closed = false;
  protected data: {event: string} & TagManagerEventData;
  protected subjectPrePush = new Subject<this>();

  constructor(public type: string, protected dataLayer: TagManagerEventData[]) {
    this.data = {event: type};
  }

  withUser(user: User | UserData | null, replace = true): this {
    const values: TagManagerEventData = {};

    if (!replace && 'user.id' in this.data) {
      return this;
    }

    if (user) {
      values.id = user.id;
      values.country = user.country;
    }

    if (user instanceof User) {
      values.isAdmin = user.admin;
      values.balance = user.balance;
      values.provider = user.provider;
    }

    this.setDataContext('user.', values);

    return this;
  }

  withCampaign(campaign: Campaign | null): this {
    const values: TagManagerEventData = {};

    if (campaign) {
      values.id = campaign.id;
      values.title = campaign.title;
      values.status = campaign.status;
      values.currency = campaign.currency;
      values.my = campaign.my;
    }

    this.setDataContext('campaign.', values);

    return this;
  }

  withOrder(order: PaymentOrder<unknown> | null): this {
    const values: TagManagerEventData = {};

    if (order) {
      values.orderId = order.orderId;
      values.status = order.status;
      values.amount = order.amount;
      values.currency = order.currency;
      values.serviceFee = order.serviceFee;
    }

    this.setDataContext('order.', values);

    return this;
  }

  protected setDataContext(prefix: string, values: TagManagerEventData): void {
    Object.keys(this.data).forEach(key => {
      if (key.startsWith(prefix)) {
        delete this.data[key];
      }
    });

    Object.keys(values).forEach(key => {
      this.data[prefix + key] = values[key];
    });
  }

  onPrePush(cb: NextObserver<this>['next']): this {
    this.subjectPrePush.subscribe(cb);

    return this;
  }

  push(data?: TagManagerEventData, prefix = this.type + '.') {
    if (this.closed) {
      throw new Error('Event already pushed to dataLayer');
    }

    this.subjectPrePush.next(this);

    if (data) {
      const dataPrefixed: TagManagerEventData = {
        event: this.data.event,
      };

      Object.keys(data).forEach(key => {
        dataPrefixed[prefix + key] = data[key];
      });

      this.data = Object.assign({}, dataPrefixed, this.data, dataPrefixed);
    }

    this.dataLayer.push(this.data);
    this.finalize();
  }

  protected finalize() {
    this.closed = true;
  }
}

@Injectable({providedIn: 'root'})
export class TagManager {
  private dataLayer: TagManagerEventData[];

  protected user: User | UserData | null = null;

  constructor(private auth: AuthService) {
    this.initDataLayer();
    this.initUser();
  }

  protected initDataLayer() {
    try {
      this.dataLayer = window.dataLayer || [];
    } catch {
      // Fallback to meaningless array just to keep things working.
      this.dataLayer = [];
    }
  }

  protected initUser() {
    // Services provided in 'root' don't need to unsubscribe.
    this.auth.userData$
      .pipe(
        switchMap(userData => {
          if (this.auth.isRegistered()) {
            return this.auth.authorizedUser$.pipe(first());
          } else {
            return of(userData);
          }
        }),
      )
      .subscribe(user => this.user = user);
  }

  event(type: string, withUser = true): TagManagerEvent {
    const event = new TagManagerEvent(type, this.dataLayer);

    if (withUser) {
      event.onPrePush(e => {
        e.withUser(this.user, false);
      });
    }

    return event;
  }

  transaction(data: GATransaction): void {
    this.dataLayer.push(data as GATransaction & {[k: string]: any});
  }
}

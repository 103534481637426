import {FeatureFlagName, FeatureFlagRole, FeatureFlagRoles, FeatureFlagsData, FeatureFlagSettings} from './types';

export class FeatureSupportError extends Error {
  constructor(flag: FeatureFlag, message: string) {
    super(message);
    this.name = 'FeatureSupportError';
    this.message = `${flag.name}: ${message}`;

    Object.setPrototypeOf(this, FeatureSupportError.prototype);
  }
}

export class FeatureFlag<T extends FeatureFlagName = FeatureFlagName> {
  id: number;
  name: T;
  status = false;
  betaAccess = false;
  roles: FeatureFlagRoles = [];
  settings: FeatureFlagSettings[T];

  constructor(name: T, data: Partial<FeatureFlagsData>, authRoles: Set<FeatureFlagRole>) {
    this.name = name;

    const featData = data[name];
    if (featData) {
      this.id = featData.id;
      this.status = !!featData.status;
      this.roles = Object.values(featData.roles) as FeatureFlagRoles;
      this.betaAccess = this.roles.some(role => authRoles.has(role));
      this.settings = featData.settings ?? {} as FeatureFlagSettings[T];
    }
  }

  get isSupported(): boolean {
    return this.status || this.betaAccess;
  }

  assertSupported(): void | never {
    if (!this.isSupported) {
      this.throw('This function is not supported.');
    }
  }

  throw(message: string): never {
    throw new FeatureSupportError(this, message);
  }
}

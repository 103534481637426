import {ActivatedRoute, Data, IsActiveMatchOptions} from '@angular/router';
import {Observable} from 'rxjs';

// This RLA config seems to be useful in many places for the
// root routes of particular section (e.g. /profile)
export const rlaExactPaths: IsActiveMatchOptions = {
  paths: 'exact',
  matrixParams: 'ignored',
  fragment: 'ignored',
  queryParams: 'ignored',
};

export function routeData<D extends Data>(route: ActivatedRoute): Observable<D> {
  return route.data as Observable<D>;
}

export function routeDeepChild(route: ActivatedRoute): ActivatedRoute {
  while (route.firstChild) {
    route = route.firstChild;
  }

  return route;
}

export function routePath(route: ActivatedRoute): string {
  const snapshot = routeDeepChild(route).snapshot;

  return snapshot.pathFromRoot
    .filter(s => !!s.routeConfig?.path)
    .map(s => s.routeConfig.path)
    .join('/');
}

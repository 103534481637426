import {Component, Inject, OnInit, Optional} from '@angular/core';
import {RESPONSE} from '@nguniversal/express-engine/tokens';
import {Response} from 'express-serve-static-core';
import {PageMetaService} from '../../services/page-meta.service';

@Component({
  selector: 'page-err404',
  templateUrl: './err404.page.html',
  styleUrls: ['./err404.page.scss'],
})
export class Err404Page implements OnInit {
  constructor(private meta: PageMetaService,
              @Optional() @Inject(RESPONSE) private res?: Response) { }

  ngOnInit() {
    this.meta
      .setTitles('Page not found')
      .setDescription('The page you are trying to access is no longer available or has been moved to a different location.')
      .flush();

    if (this.res) {
      this.res.status(404);
    }
  }
}

import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Data, Route} from '@angular/router';
import {UserPermissions} from '@app/core/models/permissions';
import {AuthService} from '@app/core/services/auth.service';
import {firstValueFrom} from 'rxjs';

export interface RestrictPermissionGuardData extends Data {
  requiredPermissions: Array<keyof UserPermissions>;
}

@Injectable()
export class RestrictPermissionGuard {
  constructor(private auth: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot) {
    return this.doCheckPermission(route);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot) {
    return this.doCheckPermission(childRoute);
  }

  canMatch(route: Route) {
    return this.doCheckPermission(route);
  }

  private async doCheckPermission(route: Route | ActivatedRouteSnapshot) {
    const data = route.data as RestrictPermissionGuardData;
    const {allow} = await firstValueFrom(this.auth.userData$);

    if (data.requiredPermissions) {
      return data.requiredPermissions.every(perm => allow[perm]);
    } else {
      return false;
    }
  }
}

import {isPlatformBrowser} from '@angular/common';
import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {User, UserCryptoWallet} from '@app/core/models/user';
import {ApiError, ApiResponse} from '@app/core/services/api-interceptor.service';
import {AuthService} from '@app/core/services/auth.service';
import {CookieService} from '@app/core/services/cookie.service';
import {KnownError} from '@app/core/services/known-errors.service';
import {environment} from '@env/environment';
import {CoriteEnv} from '@util/env';
import type Moralis from 'moralis-v1';
import {EMPTY, firstValueFrom, Observable, ReplaySubject, switchMap, timer} from 'rxjs';
import {map} from 'rxjs/operators';
import {FeatureFlagsService} from '../../version/services/feature-flags.service';
import {MoralisResolver} from '../guards/moralis.resolver';
import {BlockchainNetwork} from './web3-loader.service';

export type BlockchainLoginDetails = null | UserCryptoWallet;
export type BlockchainLinkedDetails = null | UserCryptoWallet;

export interface BlockchainLoginOps {
  fetchAddress(): Promise<string>;
}

declare const ethereum: {
  get isMetaMask(): boolean;
  request(o: {method: string}): Promise<any>;
  providers?: Array<typeof ethereum>;
};

// TODO for Den: when enable strictNullChecks:
// | { isLoggedIn: undefined, isLinked: boolean | undefined, isConnected: undefined }
export type LoggedInBlockchainState =
  {isLoggedIn: true, isLinked: boolean | undefined, isConnected: boolean | undefined}
  & BlockchainLoginDetails
  & BlockchainLoginOps;
export type LoggedOutBlockchainState = {isLoggedIn: false, isLinked: boolean | undefined, isConnected: false};

export type BlockchainState = LoggedInBlockchainState | LoggedOutBlockchainState;

@Injectable({providedIn: 'root'})
export class OldCryptoAuthService {
  private loginDetails?: BlockchainLoginDetails;
  private linkedDetails?: BlockchainLinkedDetails;

  private stateSubject = new ReplaySubject<BlockchainState>(1);
  readonly state$ = this.stateSubject.asObservable();

  get state(): BlockchainState {
    const ethConnected = this.loginDetails?.ethAddress;
    const ethLinked = this.linkedDetails?.ethAddress;

    const isLoggedIn = 'loginDetails' in this ? !!ethConnected : undefined;
    const isLinked = 'linkedDetails' in this ? !!ethLinked : undefined;
    const isConnected = isLoggedIn === undefined || isLinked === undefined
      ? undefined
      : isLinked && ethConnected === ethLinked;

    switch (true) {
      case true === isLoggedIn:
        return {
          isLoggedIn: true, isLinked, isConnected, ...this.loginDetails,
          fetchAddress: (): Promise<string> => import('ethers')
            .then(ethers => ethers.utils.getAddress(this.loginDetails.ethAddress)),
        };
      case false === isLoggedIn:
        return {isLoggedIn: false, isLinked, isConnected: false};
      default:
        // Login details not provided. State is unknown.
        return {isLoggedIn: undefined, isLinked, isConnected: undefined};
    }
  }

  constructor(readonly moralis: MoralisResolver,
              private ff: FeatureFlagsService,
              private auth: AuthService,
              @Inject(PLATFORM_ID) private pId: object,
  ) {
    this.initState();
  }

  private initState() {
    this.auth.userData$.subscribe(userData => {
      this.storeLinkedDetails(userData.isFullData() && userData.crypto || null);
    });

    if (isPlatformBrowser(this.pId)) {
      if (this.hasEthereumProvider()) {
        // Temporary hack to avoid other injected wallet providers conflicts
        // with MetaMask.
        const mmEthereum = ethereum.providers?.find(p => p.isMetaMask);
        if (mmEthereum) {
          (window as any).ethereum = mmEthereum;
        }
      }

      // Delay state detection so that Trust Wallet can finish its magic
      // (wallet switching thing)
      setTimeout(() => {
        this.initLoginState().catch(() => {
          this.storeLoginDetails(null);
        });
      }, !this.hasEthereumProvider() || ethereum.isMetaMask ? 0 : 100);
    }
  }

  hasEthereumProvider(): boolean {
    return typeof ethereum !== 'undefined';
  }

  private async initLoginState() {
    let ethAddress: string;
    if (typeof ethereum !== 'undefined') {
      [ethAddress] = await ethereum.request({method: 'eth_accounts'});
    } else if (CookieService.isStorageAvailable) {
      const wc = localStorage.getItem('walletconnect');

      if (wc) {
        const wcParsed = JSON.parse(wc);
        [ethAddress] = wcParsed.accounts;
      }
    }

    this.storeLoginDetails(ethAddress ? {ethAddress} : null);
  }

  observeState(require: 'isLoggedIn', pollInterval?: number): Observable<LoggedInBlockchainState>;
  observeState(require: 'isLinked' | 'isConnected', pollInterval?: number): Observable<BlockchainState>;
  observeState(require: 'isLoggedIn' | 'isLinked' | 'isConnected', pollInterval?: number): Observable<BlockchainState> {
    return this.state$.pipe(
      switchMap(state => {
        if (state[require]) {
          return timer(0, pollInterval).pipe(
            map(() => state),
          );
        }

        return EMPTY;
      }),
    );
  }

  async authenticate(): Promise<BlockchainState> {
    const {chainId} = await this.fetchRequiredNetworkInfo();
    const provider: Moralis.Web3ProviderType = CoriteEnv.touchMobile().isValid()
      ? 'walletConnect'
      : 'metamask';

    if ('metamask' === provider && !this.hasEthereumProvider()) {
      window.location.reload();

      return new Promise<never>(undefined);
    }

    const web3 = await this.resolveWeb3(provider);

    const [ethAddress] = await web3.listAccounts();
    if (ethAddress) {
      await this.storeLoginDetails({ethAddress});

      if (this.auth.isRegistered()) {
        await this.doAuthenticate(web3);
      }
    } else {
      throw new KnownError(Object.assign(
        new Error('ethAddress is not defined during connect'),
        {provider, chainId},
      ));
    }

    return this.state;
  }

  private async resolveWeb3(provider: Moralis.Web3ProviderType) {
    const {chainId} = await this.fetchRequiredNetworkInfo();

    switch (provider) {
      case 'metamask':
        return await this.moralis.resolveWeb3({
          provider: 'metamask',
          anyNetwork: true,
        });

      case 'walletconnect':
      case 'walletConnect':
      case 'wc':
        return await this.moralis.resolveWeb3({
          provider: 'walletconnect',
          chainId: Number(chainId),
          projectId: environment.socialProviders.wcProjectId,
          rpcMap: {
            56: 'https://bsc-dataseed1.binance.org', // BSC Mainnet
            97: 'https://data-seed-prebsc-1-s1.binance.org:8545/', // BSC Testnet
          },
          qrModalOptions: {
            themeMode: 'light',
            explorerRecommendedWalletIds: [
              /* TrustWallet */ '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
              /* MetaMask    */ 'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
            ],
          },
        });

      default:
        throw new Error('Unsupported Web3 Provider!');
    }
  }

  private async doAuthenticate(web3: Moralis.MoralisWeb3Provider) {
    const isAutoLoggedIn = this.state.isLoggedIn;

    try {
      await this.coriteWalletLink();
    } catch (e) {
      const isWrong = ApiError.isErrorOf(e, {name: 'wrong_accountId'});
      const isDupe = ApiError.isErrorOf(e, {name: 'duplicated'});

      if (web3.provider.isMetaMask && isAutoLoggedIn && (isDupe || isWrong)) {
        // Give a chance to select proper account, but only once.
        await web3.send('wallet_requestPermissions', [{eth_accounts: {}}]);

        const [ethAddress] = await web3.listAccounts();
        await this.storeLoginDetails({ethAddress});
        await this.coriteWalletLink();
      } else {
        throw e;
      }
    }
  }

  async switchNetwork(): Promise<BlockchainState> {
    const sdk = await this.moralis.resolve();
    const chain = await this.fetchRequiredNetworkInfo();
    await this.authenticate();

    try {
      if (sdk.connector?.switchNetwork) {
        await sdk.switchNetwork(chain.chainId);
      } else {
        if (chain.chainId !== Number(sdk.connector.chainId)) {
          return await firstValueFrom(ApiResponse.throwAs({
            name: 'chain_mismatch',
            message: 'Wrong network',
            userMessage: `Make sure you're in correct network: ${chain.chainName}`,
          }));
        }
      }
    } catch (e) {
      if (e?.code === 4902) {
        await sdk.addNetwork(
          chain.chainId,
          chain.chainName,
          chain.currencyName,
          chain.currencySymbol,
          chain.rpcUrl,
          chain.blockExplorerUrl,
        );
        await sdk.switchNetwork(chain.chainId);
      } else {
        throw e;
      }
    }

    return this.state;
  }

  private async coriteWalletLink(): Promise<User> {
    const state = this.state;
    const user = await firstValueFrom(this.auth.authorizedUser$);

    if (!state.isLoggedIn) {
      throw new Error('Unable to link user that is not logged in');
    }

    if (state.isConnected) {
      return user;
    }

    const crypto: UserCryptoWallet = {
      ethAddress: await state.fetchAddress(),
    };

    return await firstValueFrom(this.auth.updateAuthorizedUser(user, {crypto}));
  }

  async fetchRequiredNetworkInfo(): Promise<BlockchainNetwork> {
    const sdk = await this.moralis.resolve();
    const flag = await this.ff.resolveFeature('blockchain');

    return flag.settings.bscScanUrl.includes('//bscscan')
      ? {
        network: 'binance',
        chainId: Number(sdk.Chains.BSC_MAINNET),
        chainName: 'BNB Chain',
        currencySymbol: 'BNB',
        currencyName: 'Binance Coin',
        rpcUrl: 'https://bsc-dataseed.binance.org/',
        blockExplorerUrl: 'https://bscscan.com',
      }
      : {
        network: 'binance-testnet',
        chainId: Number(sdk.Chains.BSC_TESTNET),
        chainName: 'BNB Chain Testnet',
        currencySymbol: 'BNB',
        currencyName: 'Binance Coin',
        rpcUrl: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
        blockExplorerUrl: 'https://testnet.bscscan.com',
      };
  }

  private storeLoginDetails(details: BlockchainLoginDetails): void {
    const normalized = this.normalizeDetails(details);
    if (JSON.stringify(normalized) !== JSON.stringify(this.loginDetails)) {
      this.loginDetails = normalized;
      this.notifyStateChanges();
    }
  }

  private storeLinkedDetails(details: BlockchainLinkedDetails): void {
    const normalized = this.normalizeDetails(details);
    if (JSON.stringify(normalized) !== JSON.stringify(this.linkedDetails)) {
      this.linkedDetails = normalized;
      this.notifyStateChanges();
    }
  }

  private normalizeDetails<T extends UserCryptoWallet>(details: T | null): T | null {
    if (details) {
      return Object.assign({}, details, {
        ethAddress: details.ethAddress.toLowerCase(),
      });
    }

    return details;
  }

  private notifyStateChanges(): void {
    if ('loginDetails' in this && 'linkedDetails' in this) {
      this.stateSubject.next(this.state);
    }
  }
}

export interface GenreData {
  id: number | null;
  title: string | null;
}

export class Genre {
  constructor(public id: number | null = null, public title: string | null = null) {}

  static fromData(data: Partial<GenreData>) {
    return Object.assign(new Genre(), data);
  }
}

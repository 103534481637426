import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {AppImgModule} from '../img/img.module';
import {UserPhotoComponent} from './user-photo/user-photo.component';


@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    AppImgModule,
  ],
  declarations: [UserPhotoComponent],
  exports: [UserPhotoComponent],
})
export class AppUserPhotoModule { }

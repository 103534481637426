import {DOCUMENT, isPlatformServer} from '@angular/common';
import {Component, Inject, Input, OnInit, PLATFORM_ID, Renderer2} from '@angular/core';
import {AppStateService} from '@app/core/services/app-state.service';

type LoaderColor = 'blue' | 'white' | 'red';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
  @Input() color?: LoaderColor;
  @Input() text?: string;
  @Input() showText?: boolean;

  get shouldShowText(): boolean {
    if (typeof this.showText === 'boolean') {
      return this.showText;
    }

    return !!this.text;
  }

  constructor(private renderer: Renderer2,
              private state: AppStateService,
              @Inject(DOCUMENT) private doc: Document,
              @Inject(PLATFORM_ID) private pId: object,
  ) {
    if (!this.state.staticStorage.has('app-loader')) {
      this.state.staticStorage.set('app-loader', []);
    }
  }

  ngOnInit(): void {
    this.initPreload();
  }

  private initPreload() {
    if (isPlatformServer(this.pId)) {
      const preloadApplied = this.state.staticStorage.get('app-loader');
      const color: LoaderComponent['color'] = this.color || 'blue';

      // Ensure "preload" link added only once per loader color.
      if (preloadApplied.includes(color)) {
        return;
      }

      const link = this.renderer.createElement('link');
      this.renderer.setAttribute(link, 'href', `assets/images/loaders/co-${color}.gif`);
      this.renderer.setAttribute(link, 'rel', 'preload');
      this.renderer.setAttribute(link, 'as', 'image');

      // Ensure loaders before CSS.
      const firstStyleSheet = this.doc.querySelector('head link[rel=stylesheet]');
      this.renderer.insertBefore(this.doc.head, link, firstStyleSheet);

      preloadApplied.push(color);
    }
  }
}

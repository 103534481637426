<mat-progress-bar role="progressbar" *ngIf="isBusy" mode="indeterminate" class="page-load" color="primary"></mat-progress-bar>

<app-header role="banner" aria-label="Header" *ngIf="pageOptions.withHeader"></app-header>

<div class="page" [class.with-footer]="pageOptions.withFooter">
  <router-outlet (activate)="onActivate($event)"></router-outlet>
</div>

<app-footer role="contentinfo" aria-label="Footer" *ngIf="pageOptions.withFooter"></app-footer>

<ng-template *ngIf="pageOptions.withPlayer"
             [cdkPortalOutlet]="playerPortal$ | async"></ng-template>

<app-cookie-consent *ngIf="pageOptions.withCookieConsent && isCookieConsentRequired"
                    data-e2e="cookie-consent"></app-cookie-consent>

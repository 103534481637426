import {HttpClient} from '@angular/common/http';
import {Inject, Injectable, Optional} from '@angular/core';
import {UploadData} from '@app/lib/forms/services/uploader.service';
import {FeatureFlagsData} from '@app/services/version/types';
import {FiatCurrency, MoneyAmount} from '@app/widgets/money/shared';
import {REQUEST} from '@nguniversal/express-engine/tokens';
import {fixNumberPrecision} from '@util/misc';
import {Request} from 'express';
import {firstValueFrom, lastValueFrom, Observable, of, ReplaySubject, Subject} from 'rxjs';
import {catchError, map, retry, single, switchMap, take, tap} from 'rxjs/operators';
import {isSupportedCountry} from '../../app.info';
import {
  User,
  UserCompleteness,
  UserData,
  UserFullData,
  UserPartialData,
  UserPasswordPayload,
  UserPayload,
  UserPayloadRegister,
} from '../models/user';
import {ApiResponse} from './api-interceptor.service';
import {CookieService} from './cookie.service';
import {UserService} from './user.service';

export type ConfirmationEmailOp = 'confirmRegistration' | 'confirmProviderRegistration' | 'confirmEmail' | 'resetPassword';

export interface MeApiResponse extends ApiResponse<UserPartialData | UserFullData> {
  features?: Partial<FeatureFlagsData>;
  feeFunc?: [string, string];
  payoutFeeFunc?: [string, string];
  stakeMpFunc?: [string, string];
}

@Injectable({providedIn: 'root'})
export class AuthService {
  resetPassToken?: string;
  protected userDataLocated?: UserData;
  protected userData = new UserData();

  protected authorizedUserSource = new ReplaySubject<User>(1);

  public get authorizedUser$(): Observable<User> {
    return this.authorizedUserSource.asObservable();
  }

  protected userDataSource = new ReplaySubject<UserData>(1);

  public get userData$(): Observable<UserData> {
    return this.userDataSource.asObservable();
  }

  /**
   * Always ROUNDED and POSITIVE user balance. For display only!
   * Must not be used for calculations.
   */
  public get balance$(): Observable<MoneyAmount<FiatCurrency>> {
    return this.authorizedUser$.pipe(
      map(user => {
        try {
          const [amount, currency] = user.balance;

          return [Math.max(fixNumberPrecision(amount, 2), 0), currency];
        } catch (e) {
          return [null, undefined];
        }
      }),
    );
  }

  protected initSource = new Subject<MeApiResponse>();
  public whenInitialized = lastValueFrom(this.initSource);

  protected logoutSource = new Subject<UserData>();
  public get logout$(): Observable<UserData> {
    return this.logoutSource.asObservable();
  }

  constructor(public cookie: CookieService,
              private http: HttpClient,
              private users: UserService,
              @Optional() @Inject(REQUEST) private req?: Request,
  ) {
    this.defineUser()
      .pipe(single(), retry(1))
      .subscribe({
        error: err => this.userDataSource.error(err),
      });
  }

  onAppInit(): Observable<boolean> {
    return this.userData$.pipe(
      take(1),
      map(() => this.isDefined()),
      catchError(() => of(true)),
    );
  }

  isDefined(): boolean {
    // =0 for Guests, =otherNumber for authenticated,
    return typeof this.userData.id !== 'undefined';
  }

  isLocated(): this is this & {userDataLocated: UserData} {
    return this.userDataLocated instanceof UserData && !!this.userDataLocated.email;
  }

  isAuthenticated() {
    return !!this.userData.id;
  }

  isRegistered() {
    return this.isAuthenticated() && this.hasCompleteness(['standard']);
  }

  hasCompleteness(completeness: UserCompleteness[], exclude: UserCompleteness[] = []) {
    const actual: UserCompleteness[] = this.userData.completeness ?? [];

    const required = completeness.every(c => actual.includes(c));
    const excluded = exclude.every(c => !actual.includes(c));

    return required && excluded;
  }

  isSupportedCountry(): boolean {
    return isSupportedCountry(this.userData.country);
  }

  getUserDataLocated(): UserData {
    if (!this.isLocated()) {
      throw new Error('No user located! Please check .isLocated() first');
    }

    return this.userDataLocated;
  }

  getUserData(): UserData {
    Object.freeze(this.userData);

    return this.userData;
  }

  getDisplayName(): string | null {
    return this.users.getDisplayName(this.userData);
  }

  getDisplayNameLocated(): string | null {
    if (!this.isLocated()) {
      throw new Error('No user located! Please check .isLocated() first');
    }

    return this.users.getDisplayName(this.userDataLocated);
  }

  check(email: string): Observable<Readonly<UserData>> {
    const payload = {email};

    if (this.isLocated() && email === this.userDataLocated.email) {
      return of(this.userDataLocated);
    }

    return this.http.post<API.Response<UserPartialData>>('<apiUrl>/user/check', payload).pipe(
      map(response => {
        response.data.email = email;
        const userData = UserData.fromResponseData(response.data);

        return Object.freeze(userData);
      }),
    );
  }

  locate(email: string, extraData?: Omit<Partial<UserPartialData>, 'email'>): Observable<Readonly<UserData>> {
    return this.check(email).pipe(
      map(userData => {
        const userDataLocated = Object.assign(new UserData(), userData, extraData ?? {});

        return this.userDataLocated = Object.freeze(userDataLocated);
      }),
    );
  }

  deLocate(): void {
    delete this.userDataLocated;
  }

  login(email: string, password: string): Observable<User> {
    return this.doLogin({email, password});
  }

  confirmLocatedUser(password: string): Observable<User> {
    if (!this.isLocated()) {
      throw new Error('Unable to login: User is not located yet.');
    }

    return this.login(this.userDataLocated.email, password);
  }

  async logout(): Promise<UserData> {
    const cachedUserData = this.getUserData();

    if (this.isAuthenticated()) {
      // Logout is XSRF-risky, use POST method: server should approve.
      const logout$ = this.http.post<API.Response<UserPartialData>>('<apiUrl>/user/logout', null)
        .pipe(map(response => this.acceptUserData(response.data)));

      await firstValueFrom(logout$);
    }

    this.deLocate();
    this.logoutSource.next(cachedUserData);

    return this.getUserData();
  }

  register(user: UserPayloadRegister): Observable<User> {
    if (this.isAuthenticated()) {
      return this.doUpdateUserData(user);
    } else {
      return this.doRegister(user);
    }
  }

  resetPassword(email?: string) {
    return this.sendConfirmationEmail('resetPassword', email);
  }

  sendConfirmationEmail(op: ConfirmationEmailOp, email?: string): Observable<API.Response> {
    const userData = this.isLocated() ? this.getUserDataLocated() : this.getUserData();

    if (!userData.email && !email) {
      throw new Error(`Unable to send ${op} (undetermined user email)`);
    }

    email = email || userData.email;

    return this.http.post<API.Response>('<apiUrl>/user/confirm', {email, op});
  }

  updateAuthorizedUser(user: User, values?: Partial<User>): Observable<User> {
    const payload = this.users.toRequestPayload(user, values ?? user);

    return this.doUpdateUserData(payload);
  }

  updateAuthorizedUserPassword(newPassword: string, currentPassword?: string): Observable<User> {
    if (!currentPassword && !this.resetPassToken) {
      throw new Error('Error updating password: neither `currentPassword` nor `resetPassToken` available.');
    }

    const payload: UserPasswordPayload = currentPassword
      ? {password: newPassword, currentPassword}
      : {password: newPassword, token: this.resetPassToken};

    return this.doUpdateUserData(payload).pipe(
      // Remove one-time token if password update succeed
      tap(() => (delete this.resetPassToken)),
    );
  }

  alterAuthorizedUserImage(data: UploadData) {
    if (data.files[0].uploadName === 'user_image') {
      const userData = Object.assign({}, this.getUserData(), {
        userImage: data.files[0].path,
      });

      this.acceptAuthorizedUser(userData);
    }
  }

  defineUser(forceNoCache = false): Observable<UserData> {
    const url = forceNoCache
      ? `<apiUrl>/user/me?ts=${Date.now()}`
      : `<apiUrl>/user/me`;

    return this.http.get<MeApiResponse>(url).pipe(
      switchMap(response => {
        // if ('extra' in response.data && !response.data.id) {
        //   response.data = {
        //     id: 666,
        //     email: response.data.extra.email,
        //     firstName: response.data.extra.firstName,
        //     lastName: response.data.extra.lastName,
        //     currency: 'USD',
        //     userImage: response.data.extra.userImage,
        //     provider: response.data.extra.provider,
        //     emptypwd: true,
        //     completeness: ['basic'],
        //     extra: response.data.extra,
        //   } satisfies UserPartialData;
        //
        //   console.log('DEBUG: Simulate user soft login', response.data);
        // }

        this.initSource.next(response);
        this.initSource.complete();

        // Set userData temporary without notification of subscribers
        // as we may log out immediately
        this.userData = UserData.fromResponseData(response.data);

        this.setupDynamicFunctions(response);

        return of(this.acceptUserData(response.data));
      }),
      tap(userData => {
        if (this.isAuthenticated()) {
          this.acceptAuthorizedUser(userData);
        }
      }),
    );
  }

  async defineUserOnce(): Promise<UserData> {
    return await firstValueFrom(this.defineUser(true));
  }

  private setupDynamicFunctions(response: MeApiResponse) {
    if (response.feeFunc) {
      try {
        this.investFeeFunc = new Function(...response.feeFunc) as (a: number) => number;
      } catch (e) {
        console.warn(e);
      }
    }

    if (response.payoutFeeFunc) {
      try {
        this.payoutFeeFunc = new Function(...response.payoutFeeFunc) as (a: number) => number;
      } catch (e) {
        console.warn(e);
      }
    }

    if (response.stakeMpFunc) {
      try {
        this.stakeMpFunc = new Function(...response.stakeMpFunc) as (a: number) => number;
      } catch (e) {
        console.warn(e);
      }
    }
  }

  acceptUserData(data: UserPartialData): UserData {
    this.userData = UserData.fromResponseData(data);

    const userData = this.getUserData();

    this.userDataSource.next(this.userData);

    return userData;
  }

  acceptAuthorizedUser(data: UserPartialData | UserFullData): User {
    if (!(data instanceof UserData)) {
      this.acceptUserData(data);
    }

    if (this.isAuthenticated() && this.userData.isFullData()) {
      const user = this.users.fromResponseData(this.userData);
      this.authorizedUserSource.next(user);

      return user;
    }

    throw new Error('User is NOT authenticated');
  }

  protected doLogin(payload: Record<string, unknown>): Observable<User> {
    return this.http.post<API.Response<UserPartialData>>('<apiUrl>/user/login', payload).pipe(
      map(response => this.acceptAuthorizedUser(response.data)),
    );
  }

  protected doRegister(userData: UserPayloadRegister): Observable<User> {
    return this.http.post<API.Response<UserFullData>>('<apiUrl>/user/register', userData)
      .pipe(map(response => this.users.fromResponseData(response.data)));
  }

  protected doUpdateUserData(payload: UserPayload | UserPasswordPayload) {
    return this.http.post<API.Response<UserFullData>>('<apiUrl>/user/update', payload).pipe(
      map(response => this.acceptAuthorizedUser(response.data)),
    );
  }

  investFeeFunc: (a: number) => number = () => undefined;
  payoutFeeFunc: (a: number) => number = () => undefined;
  stakeMpFunc: (a: number) => number = () => undefined;
}

import {Injectable, isDevMode} from '@angular/core';
import {Meta, MetaDefinition, Title} from '@angular/platform-browser';
import {ActivatedRoute, Event, NavigationCancel, NavigationEnd, NavigationStart, ResolveEnd, Router} from '@angular/router';
import {environment} from '@env/environment';
import {logDebugMessageInBrowser} from '@util/misc';
import {site} from '../../app.info';
import {ImgPreviewOptions, ImgPreviewStrategy, ImgPreviewUrlBuilder} from '../../common/services/img-preview.service';
import {AppErrorHandler} from './app-error-handler';
import {AppStateService} from './app-state.service';
import {LocationHelper} from './location-helper.service';
import {PageMetaValidator} from './page-meta-validator';
import {TagManager} from './tag-manager.service';

export interface PageMetaDefaults {
  fbAppId: string;
  titleSuffix?: string;
  description?: string;
  image?: {url: string, raw: boolean, dimensions?: [number, number]};
}

@Injectable({
  providedIn: 'root',
})
export class PageMetaService {
  protected elements = new Set<HTMLMetaElement>();

  protected defaults: PageMetaDefaults = {
    fbAppId: environment.socialProviders.facebook.clientId,
    titleSuffix: site.name,
  };

  protected commandsQueue: Array<() => void> = [];
  protected currentPath: string;

  constructor(public state: AppStateService,
              protected errHandler: AppErrorHandler,
              protected router: Router,
              protected title: Title,
              protected meta: Meta,
              protected gtm: TagManager,
              protected imgUrlBuilder: ImgPreviewUrlBuilder,
              protected locHelper: LocationHelper,
              protected validator: PageMetaValidator,
  ) {
    this.router.events.subscribe(event => {
      this.routerSetupUrl(event);
    });

    if (isDevMode()) {
      this.validator.initialize({
        provideCommandsQueue: () => this.commandsQueue,
      });
    }
  }

  initialize(defaults?: Partial<PageMetaDefaults>) {
    Object.assign(this.defaults, defaults);

    this.meta.addTag({property: 'fb:app_id', content: this.defaults.fbAppId});
    this.commandsReset();
  }

  /**
   * Resets commands queue, sets up the default meta tags
   * and returns old commandsQueue
   *
   * @returns Old commandsQueue
   */
  private commandsReset(): PageMetaService['commandsQueue'] {
    const queue = this.commandsQueue;

    // Construct new array! Otherwise, it won't be possible to determine
    // whether current page flushed its meta tags.
    // See this.checkPageMetaTags() for usage.
    this.commandsQueue = [];

    this.setTitles(null).setType('website');

    if (this.defaults.description) {
      this.setDescription(this.defaults.description);
    }

    if (this.defaults.image) {
      if (this.defaults.image.raw) {
        this.setRawImage(this.defaults.image.url, this.defaults.image.dimensions);
      } else {
        this.setImage(this.defaults.image.url, this.defaults.image.dimensions);
      }
    }

    return queue;
  }

  /**
   * Clears all meta tags and execute pending commands.
   */
  private commandsApply(queue: PageMetaService['commandsQueue']): void {
    this.reset();
    queue.forEach(fn => fn());

    const tags = Array.from(this.elements)
      .filter(Boolean)
      .map(el => [el.getAttribute('property') || el.name, el.content]);
    logDebugMessageInBrowser('PageMeta', 'Flushed all enqueued meta tags', {tags, queue});
  }

  /**
   * Add/update all the titles for the current page.
   *
   * @param title <title> tag in HEAD.
   *   In case this is an Array<string> this parameter treated as
   *   title parts and will be concatenated via " | " sign.
   *
   * @param socialTitle Various meta tags for social networks.
   *   - null - no social meta tags will be present
   *   - 'auto' - fallback to <title> if simple, nothing otherwise.
   */
  setTitles(title: string | string[] | null,
            socialTitle: string | null | 'auto' = 'auto',
  ): this {
    // Page <title> tag (set or unset).
    this.setHeadTitle(title);

    // Social sharing tags.
    if (socialTitle === 'auto') {
      if (Array.isArray(title)) {
        socialTitle = title.length === 1 ? title[0] : null;
      } else {
        socialTitle = title;
      }
    }
    this.setSocialTitle(socialTitle);

    return this;
  }

  setHeadTitle(title: string | string[] | null): this {
    const segments: string[] = Array.isArray(title) ? Array.from(title) : [title];

    if (this.defaults.titleSuffix) {
      segments.push(this.defaults.titleSuffix);
    }

    this.commandsQueue.push(() => {
      this.title.setTitle(segments.filter(v => !!v).join(' | '));
    });

    return this;
  }

  setSocialTitle(socialTitle: string | null): this {
    if (socialTitle) {
      this.commandsQueue.push(() => {
        this.addTag({property: 'og:title', content: socialTitle});
      });
    }

    return this;
  }

  setDescription(description: string | null,
                 socialDescription: string | null = description,
                 shorten: number | false = 200,
  ): this {

    if (description) {
      this.commandsQueue.push(() => {
        this.addTag({
          name: 'description',
          content: this.normalizeText(description, shorten),
        });
      });
    }

    if (socialDescription) {
      this.commandsQueue.push(() => {
        this.addTag({
          property: 'og:description',
          content: this.normalizeText(socialDescription, shorten),
        });
      });
    }

    return this;
  }

  private normalizeText(text: string, shorten: number | false): string {
    text = text.trim().replace(/(<([^>]+)>)/ig, '');

    if (shorten) {
      text = text
        .substring(0, shorten)
        .replace(/([.?!…])[^.?!…]+$/, '$1');
    }

    return text.trim();
  }

  setUrl(url: string = this.router.url): this {
    if (!url) {
      console.warn('Failed to setUrl due to empty URL');

      return this;
    }

    this.commandsQueue.push(() => {
      this.addTag({property: 'og:url', content: this.absoluteUrl(url)});
    });

    return this;
  }

  private absoluteUrl(urlOrPath: string): string {
    return this.locHelper.absoluteURL(urlOrPath);
  }

  /**
   * Add/update social IMAGE meta tag(s).
   *
   * @param url One of the following:
   *   - Absolute URL to the Image.
   *   - Relative URL to the main (Front-end) website.
   *
   * @param dimensions Optional tuple of width and height of the image.
   */
  setRawImage(url: string, dimensions?: readonly [number, number]): this {
    if (!url) {
      console.warn('Failed to setRawImage due to empty URL');

      return this;
    }

    this.commandsQueue.push(() => {
      this.addTag({property: 'og:image', content: this.absoluteUrl(url)});
      this.addTag({property: 'twitter:image', content: this.absoluteUrl(url)});
    });

    if (dimensions) {
      this.commandsQueue.push(() => {
        this.addTag({property: 'og:image:width', content: dimensions[0].toString()});
        this.addTag({property: 'og:image:height', content: dimensions[1].toString()});
        this.addTag({property: 'twitter:image:width', content: dimensions[0].toString()});
        this.addTag({property: 'twitter:image:height', content: dimensions[1].toString()});
      });
    } else {
      this.commandsQueue.push(() => {
        this.meta.removeTag('property="og:image:width"');
        this.meta.removeTag('property="og:image:height"');
        this.meta.removeTag('property="twitter:image:width"');
        this.meta.removeTag('property="twitter:image:height"');
      });
    }


    return this;
  }

  /**
   * Add/update social IMAGE meta tag(s) with uploaded image preview.
   *
   * @param url One of the following:
   *   - Relative URL to the static (Back-end) website.
   *
   * @param dimensions Optional tuple of width and height of the image.
   */
  setImage(url: string, dimensions?: readonly [number, number]): this {
    const opts: ImgPreviewOptions = {strategy: 'thumb'};

    if (dimensions) {
      opts.width = dimensions[0];
      opts.height = dimensions[1];
    }

    try {
      this.setRawImage(this.imgUrlBuilder.build(url, opts), dimensions);
    } catch (e) {
      this.errHandler.logErrorAsError(e, 'PageMeta: failed to setImage()');
    }


    return this;
  }

  /**
   * Add/update social IMAGE meta tag(s) with uploaded image preview.
   * Image will be adopted to best dimensions accepted by Facebook.
   *
   * @param url One of the following:
   *   - Relative URL to the static (Back-end) website.
   * @param strategy One of the ImgPreviewStrategy.
   */
  setAdoptedImage(url: string, strategy: ImgPreviewStrategy = 'fb'): this {
    const [width, height] = [1200, 630];
    const opts: ImgPreviewOptions = {strategy, width, height};

    try {
      this.setRawImage(this.imgUrlBuilder.build(url, opts), [width, height]);
    } catch (e) {
      this.errHandler.logErrorAsError(e, 'PageMeta: failed to setAdoptedImage()');
    }

    return this;
  }

  setType(type: 'website' | 'article' | 'product') {
    this.commandsQueue.push(() => {
      this.addTag({property: 'og:type', content: type});
    });

    return this;
  }

  /**
   * Add/update social VIDEO meta tag(s).
   *
   * @param url One of the following:
   *   - Relative URL to the static (Back-end) website.
   *
   * @param dimensions Optional tuple of width and height of the image.
   */
  setVideo(url: string, dimensions?: [number, number]): this {
    this.commandsQueue.push(() => {
      this.addTag({property: 'og:video', content: this.absoluteUrl(url)});
    });

    if (dimensions) {
      this.commandsQueue.push(() => {
        this.addTag({property: 'og:video:width', content: dimensions[0].toString()});
        this.addTag({property: 'og:video:height', content: dimensions[1].toString()});
      });
    }

    return this;
  }

  setTwitterCard(type: 'summary' | 'summary_large_image' | 'player'): this {
    this.commandsQueue.push(() => {
      this.addTag({property: 'twitter:card', content: type});
      this.addTag({property: 'twitter:site', content: '@Coritecom'});
    });

    return this;
  }

  /**
   * Add/update twitter:player meta tag(s). Only for 'player' card!
   *
   * @param url One of the following:
   *   - Relative URL to the static (Back-end) website.
   *
   * @param dimensions Optional tuple of width and height of the image.
   */
  setTwitterPlayer(url: string, dimensions?: [number, number]): this {
    this.commandsQueue.push(() => {
      this.addTag({property: 'twitter:player', content: this.absoluteUrl(url)});
    });

    if (dimensions) {
      this.commandsQueue.push(() => {
        this.addTag({property: 'twitter:player:width', content: dimensions[0].toString()});
        this.addTag({property: 'twitter:player:height', content: dimensions[1].toString()});
      });
    }

    return this;
  }

  setNoIndex(): this {
    this.commandsQueue.push(() => {
      this.addTag({name: 'robots', content: 'noindex'});
    });

    return this;
  }

  private addTag(tag: MetaDefinition): HTMLMetaElement {
    const meta = this.meta.updateTag(tag);

    this.elements.add(meta);

    return meta;
  }

  private reset(): this {
    this.elements.forEach(meta => this.meta.removeTagElement(meta));
    this.elements.clear();

    return this;
  }

  /**
   * Required to execute in route components after all the meta tags
   * are set up.
   *
   * There aren't any reliable mechanisms to do this automatically because
   * meta tags can be set far later than NavigationEnd event
   *
   * As for now the most stable and reliable approach is to require routed
   * component to tell explicitly when all the meta tags are set.
   */
  flush(): void {
    // Cache commandsQueue in local variable for proper handling
    // of simultaneous .flush() calls.
    const commandsQueue = this.commandsReset();


    // Ensure next tick to prevent updating previous page's title
    // in browser History.
    setTimeout(() => {
      this.commandsApply(commandsQueue);

      // Ensure 'page.view' is sent to GTM only on first URL change.
      if (!this.currentPath || !this.locHelper.location.isCurrentPathEqualTo(this.currentPath)) {
        this.currentPath = this.locHelper.location.path();
        this.gtmPageView();
      }

      this.validator.registerFlushResolution();
    }, 0);
  }

  private gtmPageView() {
    let route: ActivatedRoute = this.router.routerState.root;
    while (route.firstChild) {
      route = route.firstChild;
    }

    // Construct path template a-la "releases/:id/release".
    const path = route.pathFromRoot
      .filter(r => r.routeConfig && r.routeConfig.path)
      .map(r => r.routeConfig.path)
      .join('/');

    this.gtm.event('page.view').push({path});
  }

  /**
   * Auto-setup URL properties upon navigations.
   */
  private routerSetupUrl(event: Event): void {
    if (event instanceof NavigationStart) {
      this.setUrl(event.url);
    }

    if (event instanceof ResolveEnd) {
      this.setUrl(event.urlAfterRedirects);
    }

    if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
      this.setUrl(this.locHelper.absoluteURL());
    }
  }

}

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CampaignApiData} from '@app/lib/campaign/models/campaign';
import {firstValueFrom} from 'rxjs';

export enum SlugType {
  None = 0,
  Campaign = 1,
  CampaignList = 2,
  Redirect = 3,
}

export enum SlugRedirectType {
  // Shorten = 1,
  // Campaign = 2,
  // Artist = 3,
  // NewsArticle = 4,
  Invite = 6,
}

interface SlugCommonApiData {
  resType: SlugType;
}

export interface NoneSlugApiData extends SlugCommonApiData {
  resType: SlugType.None;
}

export interface RedirectSlugApiData extends SlugCommonApiData {
  resType: SlugType.Redirect;
  redirect: {
    type: SlugRedirectType,
    url: string,
  };
}

export interface CampaignSlugApiData extends SlugCommonApiData, CampaignApiData {
  resType: SlugType.Campaign;
}

export interface CampaignListSlugApiData extends SlugCommonApiData {
  resType: SlugType.CampaignList;
  campaigns: CampaignApiData[];
}

export type SlugApiData = NoneSlugApiData | RedirectSlugApiData
  | CampaignSlugApiData | CampaignListSlugApiData;

@Injectable({providedIn: 'root'})
export class SlugService {
  constructor(private http: HttpClient) {}

  async fetchSlugData(slug: string): Promise<SlugApiData> {
    const {data} = await firstValueFrom(
      this.http.get<API.Response<SlugApiData>>(
      `<apiUrl>/search/by-path/${slug}`,
    ));

    if (data.resType === SlugType.Redirect) {
      if (!data.redirect.url) {
        if (data.redirect.type === SlugRedirectType.Invite) {
          data.redirect.url = '/';
        } else {
          throw new Error('Missing target for redirect');
        }

      }

      if (data.redirect.url[0] !== '/') {
        data.redirect.url = `/${data.redirect.url}`;
      }
    }

    return data;
  }
}

import {environment} from '@env/environment';
import {satisfies} from 'compare-versions';

function isTouchMobile() {
  if (typeof window === 'undefined') {
    return false;
  }

  return 'ontouchstart' in window && 'orientation' in window;
}

function isIOS() {
  if (typeof navigator === 'undefined') {
    return false;
  }

  return navigator.userAgent.indexOf('like Mac OS X') > 0;
}

function isAndroid() {
  if (typeof navigator === 'undefined') {
    return false;
  }

  return navigator.userAgent.indexOf('Android') > 0;
}

function isCoriteWVApp(os?: 'ios' | 'android'): boolean {
  if (typeof navigator === 'undefined') {
    return false;
  }

  const isMobileApp = null !== navigator.userAgent.match(/Corite\w*\/[\d.]+/);
  if (isMobileApp && os) {
    switch (os) {
      case 'ios':
        return isIOS();
      case 'android':
        return isAndroid();
    }
  }

  return isMobileApp;
}

export class CoriteEnv {
  static experimental(): boolean {
    return environment.experimental;
  }

  static touchMobile(): CoriteEnvDevice {
    return new CoriteEnvDevice();
  }

  static app(): CoriteEnvApp {
    return new CoriteEnvApp();
  }
}

class CoriteEnvDevice {
  private platform?: 'ios' | 'android';

  andPlatform(is: CoriteEnvDevice['platform']): this {
    this.platform = is;

    return this;
  }

  isValid(): boolean {
    if (!isTouchMobile()) {
      return false;
    }

    switch (this.platform) {
      case 'ios':
        if (!isIOS()) {
          return false;
        }
        break;
      case 'android':
        if (!isAndroid()) {
          return false;
        }
        break;
    }

    return true;
  }

  isInvalid(): boolean {
    return !this.isValid();
  }
}

class CoriteEnvApp {
  private versionRange?: string;

  andVersion(semver: string) {
    this.versionRange = semver;

    return this;
  }

  isValid(): boolean {
    if (!isCoriteWVApp()) {
      return false;
    }

    if (this.versionRange) {
      const version = navigator.userAgent.match(/Corite\w*\/([\d.]+)/)[1];

      if (false === satisfies(version, this.versionRange)) {
        return false;
      }
    }

    return true;
  }

  isInvalid(): boolean {
    return !this.isValid();
  }
}

import {DOCUMENT} from '@angular/common';
import {Inject, Injectable, NgZone, RendererFactory2} from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class PageEventsService {
  private renderer = this.rendererFactory.createRenderer(null, null);
  private scrollingElement: Document['scrollingElement'];

  private closeToBottom$: Observable<number>;

  constructor(
    private rendererFactory: RendererFactory2,
    private zone: NgZone,
    @Inject(DOCUMENT) private doc: Document,
  ) {}

  observeCloseToBottom(): Observable<number> {
    if (!this.closeToBottom$) {
      const source = new Subject<number>();
      this.closeToBottom$ = source.asObservable();

      const handler = () => {
        this.setupScrollingElement();

        const se = this.scrollingElement;
        const delta = se.scrollHeight - se.clientHeight - se.scrollTop;

        if (delta < 2000) {
          this.zone.run(() => {
            source.next(delta);
          });
        }
      };

      this.zone.runOutsideAngular(() => {
        this.renderer.listen('window', 'scroll', handler);

        // Execute handler also initially in case of user scrolls
        // page down while platform browser isn't activated yet
        // (e.g. when slow network).
        // TODO for Den: Remove this once PagedList can queue the .next() calls.
        setTimeout(handler, 3000);
      });
    }

    return this.closeToBottom$;
  }

  private setupScrollingElement(): void {
    if (!this.scrollingElement) {
      this.scrollingElement = this.getScrollingElement();
    }
  }

  private getScrollingElement(): Element {
    if (!this.doc.scrollingElement) {
      const B = this.doc.body; // IE 'quirks'
      const D = this.doc.documentElement; // IE with doctype

      return D.clientHeight ? D : B;
    }

    return this.doc.scrollingElement;
  }

}

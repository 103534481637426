import {Directive, TemplateRef} from '@angular/core';

@Directive({
  selector: '[appLayoutMainTitle]',
})
export class LayoutMainTitleDirective {
  constructor(public template: TemplateRef<unknown>) {}
}

@Directive({
  selector: '[appLayoutMainTitleFull]',
})
export class LayoutMainTitleFullDirective {
  constructor(public template: TemplateRef<unknown>) {}
}

@Directive({
  selector: '[appLayoutContent]',
})
export class LayoutContentDirective {
  constructor(public template: TemplateRef<unknown>) {}
}

@Directive({
  selector: '[appLayoutContentFull]',
})
export class LayoutContentFullDirective {
  constructor(public template: TemplateRef<unknown>) {}
}

@Directive({
  selector: '[appLayoutSidebarLeft]',
})
export class LayoutSidebarLeftDirective {
  constructor(public template: TemplateRef<unknown>) {}
}

@Directive({
  selector: '[appLayoutSidebarRight]',
})
export class LayoutSidebarRightDirective {
  constructor(public template: TemplateRef<unknown>) {}
}

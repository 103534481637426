import {Component, Inject, OnInit, Optional} from '@angular/core';
import {Router} from '@angular/router';
import {RESPONSE} from '@nguniversal/express-engine/tokens';
import {Response} from 'express-serve-static-core';
import {PageMetaService} from '../../services/page-meta.service';

@Component({
  selector: 'page-err403',
  templateUrl: './err403.page.html',
})
export class Err403Page implements OnInit {
  constructor(private router: Router,
              private meta: PageMetaService,
              @Optional() @Inject(RESPONSE) private res: Response) { }

  ngOnInit() {
    this.meta
      .setTitles('Access denied')
      .setDescription(`This page is restricted to authorized users only. Please log in or register for an account if you don't have one.`)
      .flush();

    if (this.res) {
      this.res.status(403);
    }
  }

}

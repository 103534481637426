export interface CampaignAuctionGroupData {
  id: number;
  cnt: number;
  bought: number;
  my?: number;
}

export interface CampaignAuctionSettingsData {
  id: number;
  active: boolean;
  startTime: number;
  endTime: number;
  activeGroup: number;
  groups: CampaignAuctionGroupData[];
}

export interface CampaignAuctionSettingsNormalized {
  id: number;
  active: boolean;
  startTime: Date;
  endTime: Date;
  groups: CampaignAuctionGroupData[];
  activeGroup?: CampaignAuctionGroupData;
}


export class CampaignAuctionSettings implements CampaignAuctionSettingsNormalized {
  get isStarted(): boolean {
    return this.startTime.getTime() < Date.now();
  }

  get isEnded(): boolean {
    return this.endTime.getTime() < Date.now();
  }

  get isActive(): boolean {
    return this.active && this.isStarted && !this.isEnded;
  }

  constructor(
    public id: number,
    public active: boolean,
    public startTime: Date,
    public endTime: Date,
    public groups: CampaignAuctionSettingsData['groups'],
    public activeGroup?: CampaignAuctionGroupData,
  ) {}

  static fromData(data: CampaignAuctionSettingsData): CampaignAuctionSettings {
    const normalized: CampaignAuctionSettingsNormalized = Object.assign({}, data, {
      startTime: new Date(data.startTime * 1000),
      endTime: new Date(data.endTime * 1000),
      activeGroup: data.groups.find(g => g.id === data.activeGroup),
    });

    return Object.assign(new CampaignAuctionSettings(
      normalized.id,
      normalized.active,
      normalized.startTime,
      normalized.endTime,
      normalized.groups,
      normalized.activeGroup,
    ), normalized);
  }
}

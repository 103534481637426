export interface CampaignSkillsData {
  skills: string[] | null;
  otherSkills: string | null;
}

export class CampaignSkills {
  skills: string[] | null = null;
  otherSkills: string | null = null;

  get hasSkills(): boolean {
    return !!(this.skills && this.skills.length);
  }

  static fromData(data: CampaignSkillsData): CampaignSkills {
    return Object.assign(new this(), data);
  }
}

import {Directive, HostBinding, inject, OnInit} from '@angular/core';
import {RouterLink} from '@angular/router';
import {FeatureFlagsService} from '@app/services/version/services/feature-flags.service';
import {LinkTargetDirective} from '@app/shared/directives/link-target.directive';
import {ForArtistsDirective} from './for-artists.directive';

@Directive({
  selector: 'a[appForArtistsLink]',
  standalone: true,
  hostDirectives: [RouterLink, LinkTargetDirective],
})
export class ForArtistsLinkDirective implements OnInit {
  private readonly routerLink = inject(RouterLink);
  private readonly hostDirective = inject(ForArtistsDirective);
  private readonly targetDirective = inject(LinkTargetDirective);
  private readonly ff = inject(FeatureFlagsService);

  @HostBinding('attr.target') target?: string;
  @HostBinding('attr.rel') rel?: string;
  @HostBinding('attr.href') href?: string;

  async ngOnInit() {
    if (this.hostDirective.canCreateCampaign) {
      this.routerLink.routerLink = ['/releases'];
      this.routerLink.queryParams = {dialog: 'create'};
    } else if (this.hostDirective.canRequestCampaign) {
      // Hacky workaround to prevent RouterLink from removing href attribute.
      this.routerLink['isAnchorElement'] = false;
      this.routerLink['subscription'].unsubscribe();

      this.ff.resolveFeature('campaigns').then(flag => {
        this.href = flag.settings.fanCampaignRequestURL;
        this.target = '_blank';
        this.rel = 'nofollow';

        setTimeout(() => {
          this.targetDirective.href = flag.settings.fanCampaignRequestURL;
        });
      });
    }
  }
}

import {createNgModule, Injectable, Injector, NgModuleRef, Type} from '@angular/core';

export interface AppLazyModuleRef<T> {
  loadCallback: () => Promise<Type<T>>;
  ngModuleRef: NgModuleRef<T>;
}

@Injectable({providedIn: 'root'})
export class AppModuleLoader {
  private moduleRefs: AppLazyModuleRef<unknown>[] = [];

  constructor(private injector: Injector) {}

  async loadModule<T>(loadCallback: AppLazyModuleRef<T>['loadCallback']): Promise<NgModuleRef<T>> {
    const existingModuleRef = this.moduleRefs
      .find(ref => ref.loadCallback === loadCallback) as AppLazyModuleRef<T>;
    if (existingModuleRef) {
      return existingModuleRef.ngModuleRef;
    }

    const ngModule = await loadCallback();
    const ngModuleRef = await this.resolveModuleRef(ngModule);

    this.moduleRefs.push({ngModuleRef, loadCallback});

    return ngModuleRef;
  }

  async resolveModuleRef<T>(ngModule: Type<T>): Promise<NgModuleRef<T>> {
    return createNgModule(ngModule, this.injector);
  }
}

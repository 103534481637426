import {ComponentType} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {Injectable, Injector} from '@angular/core';
import {AudioComponent} from '@app/widgets/audio/components/audio.component';
import {ReplaySubject, Subject, Subscription} from 'rxjs';
import {Campaign} from '../../campaign/models/campaign';
import {CampaignService} from '../../campaign/services/campaign.service';
import {PlayerOutletComponent} from '../components/outlet/player-outlet.component';
import {PlayerComponent} from '../components/player/player.component';

interface PlayerOutletPortalOptions {
  componentType: ComponentType<any>;
  injector: Injector;
}

@Injectable({
  providedIn: 'root',
})
export class PlayerService {
  protected subscriptionOfCampaign: Subscription;

  protected player: PlayerComponent;
  protected currentCampaign: Campaign;

  protected currentCampaignSource = new Subject<Campaign>();
  currentCampaign$ = this.currentCampaignSource.asObservable();

  protected portalSource = new ReplaySubject<ComponentPortal<PlayerOutletComponent>>(1);
  portal$ = this.portalSource.asObservable();

  constructor(protected campaigns: CampaignService) {}

  initializeOutlet(outlet: PlayerOutletPortalOptions) {
    if (!this.portalSource.closed) {
      const portal = new ComponentPortal(
        outlet.componentType,
        null,
        outlet.injector,
      );

      this.portalSource.next(portal);
      this.portalSource.complete();
    }
  }

  setCampaign(campaign: Campaign, startPlaying = false): this {
    if (campaign === this.currentCampaign) {
      if (startPlaying) {
        this.play();
      }

      return this;
    }

    if (!campaign.audioSnippet.file) {
      throw new TypeError('Unable to play campaign with no "audioSnippet" field.');
    }

    if (this.subscriptionOfCampaign) {
      this.subscriptionOfCampaign.unsubscribe();
    }

    if (this.isPlaying()) {
      this.pause();
    }

    this.subscriptionOfCampaign = this.campaigns
      .observeExisting(campaign)
      .subscribe(freshCampaign => {
        this.currentCampaign = freshCampaign;
        this.currentCampaignSource.next(this.currentCampaign);

        if (startPlaying) {
          this.play();
        }
      });

    return this;
  }

  getCampaign(): Campaign {
    return this.currentCampaign;
  }

  setPlayer(player: PlayerComponent): this {
    this.player = player;

    return this;
  }

  hasPlayer(): boolean {
    return !!this.player;
  }

  play() {
    if (!this.hasPlayer()) {
      throw new Error('Unable to play with no player attached.');
    }

    this.player.audio.playOn();
  }

  pause() {
    if (!this.hasPlayer()) {
      throw new Error('Unable to pause with no player attached.');
    }

    this.player.audio.playOff();
  }

  getAudio(): AudioComponent {
    if (!this.hasPlayer()) {
      throw new Error('Unable get audio with no player attached.');
    }

    return this.player.audio;
  }

  isPlaying(campaign?: Campaign): boolean {
    if (!this.hasPlayer() || !this.getAudio().isPlaying) {
      return false;
    }

    if (campaign) {
      const file = campaign.audioSnippet.file;

      return this.getCampaign().audioSnippet.file === file;
    }

    return true;
  }

  reset(): void {
    delete this.player;
    delete this.currentCampaign;
  }
}

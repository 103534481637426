import {Injectable} from '@angular/core';
import {AuthService} from '@app/core/services/auth.service';
import {delayWhen, distinctUntilKeyChanged} from 'rxjs/operators';
import {FeatureFlag} from '../models';
import {FeatureFlagName, FeatureFlagRole, FeatureFlagsData} from '../types';

@Injectable({providedIn: 'root'})
export class FeatureFlagsLoader {
  protected data?: Partial<FeatureFlagsData>;
  protected loadedCache = new Map<FeatureFlagName, FeatureFlag>();
  protected allowedRoles = new Set<FeatureFlagRole>(['all']);

  constructor(
    private auth: AuthService,
  ) {
    this.observeAuthChanges();
  }


  getFeature<T extends FeatureFlagName>(name: T): FeatureFlag<T> {
    if (!this.hasFFData()) {
      throw new Error('No VersionInfo available. Did you forget to .ensureFFData()?');
    }

    if (!this.loadedCache.has(name)) {
      this.loadedCache.set(name, new FeatureFlag(name, this.data, this.allowedRoles));
    }

    return this.loadedCache.get(name) as FeatureFlag<T>;
  }

  hasFFData(): boolean {
    return 'data' in this;
  }

  async ensureFFData(): Promise<Partial<FeatureFlagsData> | never> {
    if (!this.hasFFData()) {
      const me = await this.auth.whenInitialized;

      this.data = me.features;
    }

    return this.data;
  }

  private observeAuthChanges() {
    this.auth.userData$.pipe(
      distinctUntilKeyChanged('admin'),
      delayWhen(() => this.ensureFFData()),
    ).subscribe(userData => {
      this.loadedCache.clear();

      if (userData.admin) {
        this.allowedRoles.add('admin');
      } else {
        this.allowedRoles.delete('admin');
      }
    });
  }
}

<ng-template #textTpl>
  <ng-container *ngIf="text">{{ text }}</ng-container>
  <ng-container i18n="Loader: Fallback text [VERIFIED]@@loader"
                *ngIf="!text">Please wait...</ng-container>
</ng-template>


<div class="loader" [ngClass]="color">
  <ng-container *ngIf="!shouldShowText" [ngTemplateOutlet]="textTpl"></ng-container>
</div>
<div *ngIf="shouldShowText" class="text">
  <ng-container [ngTemplateOutlet]="textTpl"></ng-container>
</div>

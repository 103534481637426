import {Injectable} from '@angular/core';
import {CoriteEnv} from '@util/env';
import {firstValueFrom, of, ReplaySubject} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {CookieService} from './cookie.service';

@Injectable({providedIn: 'root'})
export class CookieConsentService {
  private readonly agreedSource = new ReplaySubject<boolean>(1);
  readonly isAgreed$ = this.agreedSource.asObservable();

  private _isAgreed: boolean;
  get isAgreed(): boolean {
    return this._isAgreed;
  }

  get isRequired(): boolean {
    return !this.isAgreed && CoriteEnv.app().isInvalid();
  }

  constructor() {
    this.setAgreedState(this.lsIsConsentAgreed());
  }

  agree(): void {
    this.lsSaveConsent();
    this.setAgreedState(true);
  }

  whenDismissed(): Promise<void> {
    if (!this.isRequired) {
      return Promise.resolve();
    }

    const whenAgreed$ = this.isAgreed$.pipe(filter(Boolean), map(() => null));

    return firstValueFrom(whenAgreed$);
  }

  private setAgreedState(isAgreed: boolean) {
    this._isAgreed = isAgreed;
    this.agreedSource.next(isAgreed);
  }

  private lsSaveConsent(): void {
    if (CookieService.isStorageAvailable) {
      localStorage.setItem('se-consent', 'agree');
      location.reload();
    }
  }

  private lsIsConsentAgreed(): boolean {
    if (CookieService.isStorageAvailable) {
      return localStorage.getItem('se-consent') === 'agree';
    }

    return false;
  }
}

@Injectable()
export class SSRCookieConsentService implements Pick<CookieConsentService, keyof CookieConsentService> {
  readonly isAgreed$ = of(false);
  readonly isAgreed = false;
  readonly isRequired = false;

  agree(): void { /* nothing to do in SSR */ }

  async whenDismissed(): Promise<void> { /* nothing to do in SSR */ }
}

<div class="title" [ngSwitch]="headingLevel">
  <ng-template #contentTpl>
    <ng-content></ng-content>
  </ng-template>

  <h1 *ngSwitchCase="'h1'">
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  </h1>
  <h2 *ngSwitchCase="'h2'">
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  </h2>
  <h3 *ngSwitchCase="'h3'">
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  </h3>
  <ng-container *ngSwitchDefault>
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  </ng-container>
</div>

<div class="action" *ngFor="let layoutGridAction of actions?.toArray()">
  <ng-container [ngTemplateOutlet]="layoutGridAction.template"></ng-container>
</div>

<!-- Workaround: https://github.com/angular/angular/issues/50543#issuecomment-1572900259 -->
<ng-content></ng-content>

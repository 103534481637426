import {PortalModule} from '@angular/cdk/portal';
import {APP_ID, Injector, NgModule} from '@angular/core';
import {MatLegacyProgressBarModule as MatProgressBarModule} from '@angular/material/legacy-progress-bar';
import {BrowserModule, provideClientHydration} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CoreModule} from '@app/core/core.module';
import {PageMetaService} from '@app/core/services/page-meta.service';
import {AppLayoutModule} from '@app/lib/layout/layout.module';
import {AppNavigationModule} from '@app/lib/navigation/navigation.module';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    PortalModule,
    MatProgressBarModule,
    AppLayoutModule,
    AppNavigationModule,
    AppRoutingModule,
  ],
  providers: [
    {provide: APP_ID, useValue: 'corite-ui'},
    provideClientHydration(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(injector: Injector) {
    // Some services must be initialized early.
    injector.get(PageMetaService);
  }
}

import {NgModule} from '@angular/core';
import {MAT_LEGACY_DIALOG_DEFAULT_OPTIONS as MAT_DIALOG_DEFAULT_OPTIONS, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';


@NgModule({
  declarations: [],
  imports: [MatDialogModule],
  exports: [MatDialogModule],
  providers: [
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: Object.assign(new MatDialogConfig(), {
        maxWidth: '90vw',
      }),
    },
  ],
})
export class DialogModule { }

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {AppNavigationModule} from '../navigation/navigation.module';
import {LayoutArticleComponent} from './layouts/layout-article/layout-article.component';
import {LayoutBlankComponent} from './layouts/layout-blank/layout-blank.component';
import {LayoutComplexComponent} from './layouts/layout-complex/layout-complex.component';
import {LayoutSimpleComponent} from './layouts/layout-simple/layout-simple.component';
import {
  LayoutContentDirective,
  LayoutContentFullDirective,
  LayoutMainTitleDirective,
  LayoutMainTitleFullDirective,
  LayoutSidebarLeftDirective,
  LayoutSidebarRightDirective,
} from './sections/layout-regions.directive';
import {TitleComponent} from './sections/title.component';
import {TitleActionDirective} from './sections/title.directive';

@NgModule({
  imports: [CommonModule, AppNavigationModule],
  declarations: [
    LayoutMainTitleDirective,
    LayoutMainTitleFullDirective,
    TitleActionDirective,
    LayoutContentFullDirective,
    LayoutContentDirective,
    LayoutSidebarRightDirective,
    LayoutSidebarLeftDirective,

    LayoutBlankComponent,
    LayoutSimpleComponent,
    LayoutComplexComponent,
    LayoutArticleComponent,
    TitleComponent,
  ],
  exports: [
    LayoutBlankComponent,
    LayoutSimpleComponent,
    LayoutComplexComponent,
    LayoutArticleComponent,

    TitleComponent,
    TitleActionDirective,
    LayoutMainTitleDirective,
    LayoutMainTitleFullDirective,
    LayoutContentFullDirective,
    LayoutContentDirective,
    LayoutSidebarRightDirective,
    LayoutSidebarLeftDirective,
  ],
})
export class AppLayoutModule {}

import {HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Inject, Injectable, OnDestroy, Optional} from '@angular/core';
import {environment} from '@env/environment';
import {REQUEST} from '@nguniversal/express-engine/tokens';
import {logDebugMessage} from '@util/misc';
import {getClientInfo, getServerTimespan, isServerTimingEnabled} from '@util/ssr';
import {Request} from 'express';
import {defer, Observable, switchMap, throwError} from 'rxjs';
import {catchError, map, tap, timeout} from 'rxjs/operators';
import {CookieService} from './cookie.service';

export class ApiError extends Error {
  static isErrorOf(err: unknown, criteria: string | API.Raw.BodyError): err is ApiError {
    if (typeof criteria === 'string') {
      criteria = {name: criteria};
    }

    if (err instanceof ApiError) {
      return err.is(criteria);
    }

    return false;
  }

  constructor(public readonly response: ApiResponse, request?: HttpRequest<unknown>) {
    super(`${response.getMainErrorMessage()} (${request?.url || 'apiUrl unknown'})`);
    this.name = 'ApiError';

    Object.setPrototypeOf(this, ApiError.prototype);
  }

  is(criteria: API.Raw.BodyError): boolean {
    const err = this.response.getMainError();

    return Object.keys(criteria).every(key => criteria[key] === err[key]);
  }

  setUserMessage(message: string): void {
    const err = this.response.getMainError();
    err.userMessage = message;
  }
}

export class ApiTypeError extends TypeError {
  constructor(message: string,
              public readonly response: ApiResponse,
              public readonly request?: HttpRequest<unknown>,
  ) {
    super(message);
    this.name = 'ApiTypeError';

    Object.setPrototypeOf(this, ApiTypeError.prototype);
  }
}

export function isApiResponseError(err: unknown): err is API.Raw.BodyError {
  if (err && typeof err === 'object') {
    return 'name' in err && !(err instanceof Error);
  }

  return false;
}

export class ApiResponse<D = unknown> {
  status: number;
  data?: D;
  error?: API.Raw.BodyException;
  errors?: API.Raw.BodyError[];
  xsrfToken?: string;

  constructor(response: HttpResponse<API.Raw.Response>,
              request?: HttpRequest<unknown>,
              private cookie?: CookieService,
  ) {
    Object.assign(this, response.body);
    // Update XSRF token as early as possible.
    this.updateCookies();

    this.adaptBCLayer();
    this.adaptExceptions();

    if (!this.data && !this.errors && !this.error) {
      throw new ApiTypeError('Either `data`, `errors` or `error` keys must be provided', this, request);
    }

    if (this.isFailed()) {
      if (this.errors.some(err => !err.name)) {
        throw new ApiTypeError('each error in `errors` array must contain at least `name` property', this, request);
      } else {
        throw new ApiError(this, request);
      }
    }
  }

  static throwAs(error: API.Raw.BodyError, req?: HttpRequest<unknown>): Observable<never> {
    return defer(() => {
      const response = new HttpResponse<API.Raw.Response>({
        body: {
          status: 0,
          errors: [error],
        },
      });

      const res = new ApiResponse<never>(response, req);

      return throwError(() => res);
    });
  }

  static throwAs404<T = any>(req?: HttpRequest<unknown>): Observable<never> {
    return this.throwAs({name: 'not_found'}, req);
  }

  isSuccess(): this is API.Response<D> {
    return !!this.data && !this.isFailed();
  }

  isFailed(): this is API.ErrorResponse {
    return !!this.errors || !!this.error;
  }

  getError(name: string, field?: string): API.Raw.BodyError | undefined {
    // Find first error with given name and (optionally) field, if provided
    return this.errors?.find(err => err.name === name && (!field || err.field === field));
  }

  getMainError(): API.Raw.BodyError {
    return this.errors?.find(err => !err.field) || this.errors?.[0] || {
      name: 'unknown',
      message: 'Unknown error',
    };
  }

  getMainErrorMessage(): string {
    const error = this.getMainError();

    return error.message || error.name;
  }

  private updateCookies() {
    if (this.xsrfToken && this.cookie && this.cookie.isWritable) {
      this.cookie.remove('XSRF-TOKEN');
      this.cookie.set('XSRF-TOKEN', this.xsrfToken);
    }
  }

  private adaptBCLayer() {
    if (this.errors) {
      if (!Array.isArray(this.errors)) {
        this.errors = Object.values(this.errors);
      }
    }
  }

  private adaptExceptions() {
    if (!this.errors && this.error) {
      this.errors = [{name: 'exception', ...this.error}];
    }
  }

}

@Injectable()
export class ApiInterceptor implements HttpInterceptor, OnDestroy {
  constructor(private cookie: CookieService,
              private http: HttpClient,
              @Optional() @Inject(REQUEST) private req?: Request,
  ) {
    if (isServerTimingEnabled()) {
      logDebugMessage('API', 'Initialization', `${getServerTimespan()}s`);
    }
  }

  ngOnDestroy(): void {
    if (isServerTimingEnabled()) {
      logDebugMessage('API', 'Destroy', `${getServerTimespan()}s`);
    }
  }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let nextHandler: Observable<HttpEvent<unknown>>;

    const setHeaders = this.getForwardHeaders();

    if (req.url.startsWith('<apiUrl>')) {
      const apiReq = req.clone({
        url: encodeURI(req.url.replace('<apiUrl>', environment.apiUrl)),
        withCredentials: true,
        setHeaders,
      });

      nextHandler = next.handle(apiReq).pipe(
        map(event => {
          if (event instanceof HttpResponse) {
            return event.clone({body: new ApiResponse(event, apiReq, this.cookie)});
          }

          return event;
        }),
      );
    } else if (req.url.startsWith(environment.adminUrl)) {
      nextHandler = next.handle(req.clone({withCredentials: true, setHeaders}));
    } else {
      nextHandler = next.handle(req);
    }

    if (this.cookie.isWritable) {
      this.setupCookie();
    }

    const d = new Date();

    return nextHandler.pipe(
      tap((event) => {
        if (event instanceof HttpResponse && isServerTimingEnabled()) {
          const timeBefore: number = getServerTimespan(d);
          const timeAfter: number = getServerTimespan();
          const timespan: number = (Date.now() - d.getTime()) / 1000;

          logDebugMessage(
            'API', `XHR ${timeAfter.toString().padEnd(6, '0')}`, req.method, event.url,
            `${timeBefore}..${timeAfter}s (duration: ${timespan}s)`,
            timespan > 0.3 ? '!!! [SLOW]' : '',
          );
        }
      }),
      // As Angular HttpClient does not implement xhr ontimeout event,
      // ensure we won't hang forever...
      timeout({each: environment.constOpts.apiTimeoutRequest}),
      catchError(err => {
        // Try to handle some expected errors. If failed on retry,
        // don't retry further to prevent infinite loop.
        if (!req.params.has('retry')) {
          if (ApiError.isErrorOf(err, {name: 'wrong_csrf'})) {
            return this.handleCSRFError(req);
          }
        }

        err.request = req;

        throw err;
      }),
    );
  }

  private getForwardHeaders() {
    const headers: Record<string, string> = {};

    if (this.req) {
      for (const [field, value] of Object.entries(getClientInfo(this.req))) {
        headers[`x-${field}`] = value;
      }

      for (const hName of ['cookie']) {
        const header = this.req.header(hName);
        if (header && typeof header === 'string') {
          headers[hName] = header;
        }
      }
    }

    return headers;
  }

  protected setupCookie(): void {
    // TODO for Den: use CookieService for this also
    if (location.hostname !== 'localhost') {
      const d = new Date();
      const offset = d.getTimezoneOffset() + (this.isNowDST() ? '.1' : '.0');

      d.setTime(d.getTime() + 6 * 60 * 60 * 1000);
      const expires = d.toUTCString();

      const domain = `.${location.hostname}`;

      document.cookie = `_timeOffset=${offset};expires=${expires};path=/;domain=${domain}`;
    }
  }

  private isNowDST() {
    const now = new Date();
    const jan = new Date(now.getFullYear(), 0, 1);
    const jul = new Date(now.getFullYear(), 6, 1);
    const stdTZOffset = Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());

    return now.getTimezoneOffset() < stdTZOffset;
  }

  private handleCSRFError(req: HttpRequest<unknown>) {
    return this.http.get<API.Response>('<apiUrl>/user/start').pipe(
      switchMap(response => this.http.request(req.clone({
        setParams: {retry: '1'},
        setHeaders: {'X-XSRF-TOKEN': response.xsrfToken},
      }))),
    );
  }
}

import {Location} from '@angular/common';
import {Injectable, Type} from '@angular/core';
import {NavigationCancel, NavigationEnd, NavigationError, NavigationExtras, NavigationStart, Router} from '@angular/router';
import {OnBoardingService} from '@app/services/on-boarding/services/on-boarding.service';
import {debounceTime, filter, skip, switchMap, take, takeWhile} from 'rxjs/operators';
import {AuthNextService} from './auth-next.service';
import {CookieConsentService} from './cookie-consent.service';
import {IconsService} from './icons.service';

export const appBreakpoints = {
  cssMob: '(min-width: 375px)', // mobile
  cssMob2: '(min-width: 480px)', // wide mobile
  cssTab: '(min-width: 720px)', // tablet
  cssNb: '(min-width: 1024px)', // notebook
  cssDesk: '(min-width: 1250px)', // desktop

  thin: '(max-width: 719px)',
  medium: '(min-width: 720px) and (max-width: 1023px)',
  wide: '(min-width: 1024px)',
  ltWide: '(max-width: 1023px)',
};

@Injectable({providedIn: 'root'})
export class AppStateService {
  public static lastStableLocation: string;
  public fixedComponents = new WeakMap<Type<any>, any>();
  public isNavigating = false;
  public staticStorage = new Map<string, any>();

  constructor(private router: Router,
              private cookieConsent: CookieConsentService,
              private onBoarding: OnBoardingService,
              private icons: IconsService,
              private authNext: AuthNextService,
              private location: Location,
  ) {
    this.updateStableLocation();
    this.icons.addDefaultIcons();
    this.onBoarding.initialize();
    this.authNext.initialize();

    this.setupRouterEvents();
  }

  isCookieConsentRequired(): boolean {
    return this.cookieConsent.isRequired;
  }

  handleInitialNavigation(...fallbackNavigation: [any[], NavigationExtras?]) {
    return this.router.events
      .pipe(
        // Take until initial navigation ends but also emit last NavigationCancel
        takeWhile(event => !this.router.navigated || event instanceof NavigationCancel),
        // Filter only NavigationCancel and ONLY if it is the last in this task.
        debounceTime(0),
        filter((event): event is NavigationCancel => event instanceof NavigationCancel),
        // Only in this case redirect to the fallback url
        switchMap(() => this.router.navigate(...fallbackNavigation)),
        take(1), // just in case (ensure only once)
      );
  }

  private setupRouterEvents() {
    this.router.events
      .pipe(skip(1))
      .subscribe(event => {
        if (event instanceof NavigationStart) {
          this.isNavigating = true;
        }
        if (
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel ||
          event instanceof NavigationError
        ) {
          this.isNavigating = false;

          if (event instanceof NavigationEnd) {
            this.updateStableLocation();
          }
        }
      });
  }

  private updateStableLocation() {
    AppStateService.lastStableLocation = this.location.path();
  }
}

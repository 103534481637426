export interface CampaignSpendOnData {
  fundsDestinations: string[] | null;
  otherDestination: string | null;
}

export class CampaignSpendOn {
  fundsDestinations: string[] = null;
  otherDestination: string = null;

  get hasDestinations(): boolean {
    return !!(this.fundsDestinations && this.fundsDestinations.length);
  }

  static fromData(data: CampaignSpendOnData): CampaignSpendOn {
    return Object.assign(new this(), data);
  }
}

import {HttpClient} from '@angular/common/http';
import {Injectable, NgZone} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {AppErrorHandler} from '@app/core/services/app-error-handler';
import {environment} from '@env/environment';
import {firstValueFrom, of, ReplaySubject, Subject} from 'rxjs';
import {catchError, retry, switchMap, tap, throttleTime} from 'rxjs/operators';
import {VersionInfo} from '../types';

@Injectable({
  providedIn: 'root',
})
export class VersionLoader {
  private readonly URL = `${environment.staticUrl}/version.json`;
  private readonly THROTTLE = 10_000;

  private requests = new Subject<void>();
  private cache = new ReplaySubject<VersionInfo | undefined>(1);

  constructor(private http: HttpClient,
              private zone: NgZone,
              private errHandler: AppErrorHandler,
  ) {
    this.requests.asObservable().pipe(
      takeUntilDestroyed(),
      throttleTime(this.THROTTLE),
      tap(() => {
        this.cache.complete();
        this.cache = new ReplaySubject(1);
      }),
      switchMap(() => this.http.get<VersionInfo>(this.URL).pipe(
        retry(2),
        catchError(err => {
          this.errHandler.logErrorAsWarn(err, 'VersionLoader: Error fetching version info');

          return of(undefined);
        }),
      )),
    ).subscribe(versionInfo => {
      this.cache.next(versionInfo);
    });
  }

  async loadVersionInfo(): Promise<VersionInfo | undefined> {
    this.zone.runOutsideAngular(() => {
      this.requests.next();
    });

    return await firstValueFrom(this.cache, {defaultValue: undefined});
  }
}

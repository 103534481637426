import {Request} from 'express';

export function getClientInfo(req: Request) {
  return {
    ip: req.header('x-forwarded-for')?.split(',')[0] || req.ip,
    hostname: req.header('x-forwarded-host') || req.hostname,
  };
}

let init: Date;
export function resetServerTimer(): Date {
  return init = new Date();
}

export function isServerTimingEnabled(): boolean {
  return init instanceof Date;
}

export function getServerTimespan(to = new Date()): number {
  return (to.getTime() - init.getTime()) / 1000;
}

import {Directive, inject, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {AuthService} from '@app/core/services/auth.service';
import {FeatureFlagsService} from '@app/services/version/services/feature-flags.service';
import {mergeWith, Subscription} from 'rxjs';

@Directive({
  selector: '[appForArtists]',
  standalone: true,
})
export class ForArtistsDirective implements OnInit, OnDestroy {
  private readonly auth = inject(AuthService);
  private readonly ff = inject(FeatureFlagsService);
  private readonly templateRef = inject(TemplateRef);
  private readonly vcRef = inject(ViewContainerRef);

  private subscription: Subscription;

  private isRendered = false;
  private isAllowedByConsumer = false;

  @Input() set appForArtists(condition: boolean) {
    this.isAllowedByConsumer = condition;
    this.ensureRendered();
  }

  get canCreateCampaign(): boolean {
    return this.auth.getUserData().allow?.createCampaign;
  }

  get canRequestCampaign(): boolean {
    return this.ff.isFeatureSupported('campaigns', 'fanCampaignRequestURL');
  }

  ngOnInit(): void {
    this.subscription = this.auth.userData$.pipe(
      mergeWith(this.ff.resolveFeature('campaigns')),
    ).subscribe(() => this.ensureRendered());
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  private ensureRendered() {
    const isVisible = this.isAllowedByConsumer
      && (this.canCreateCampaign || this.canRequestCampaign);

    if (isVisible && !this.isRendered) {
      this.vcRef.createEmbeddedView(this.templateRef);
      this.isRendered = true;
    } else if (!isVisible && this.isRendered) {
      this.vcRef.clear();
      this.isRendered = false;
    }
  }
}

import {Injectable} from '@angular/core';
import {User, UserFullData, UserPartialData, UserPayload} from '../models/user';

@Injectable({providedIn: 'root'})
export class UserService {
  getDisplayName(user: UserPartialData): string | null {
    const name = [user.firstName, user.lastName]
      .filter(p => !!p)
      .join(' ').trim();

    return name || null;
  }

  toFormModel(user: User) {
    return {
      userImage: user.userImage,
      firstName: user.firstName,
      lastName: user.lastName,
      phone: user.phone,
      website: user.website,
      address: {
        country: [user.country, user.currency] as const,
        city: user.city,
        address: user.address,
        postal_code: user.postNumber,
      },
      socials: user.socials.map(profile => ({
        type: profile.type,
        url: profile.url,
      })),
    };
  }

  fromFormModel(user: User, formModel: ReturnType<UserService['toFormModel']>): Readonly<User> {
    // Required User fields.
    const updatedUser: UserFullData = {
      id: user.id,
      admin: user.admin,
      allow: user.allow,
      provider: user.provider,
      email: user.email,
      completeness: user.completeness,
      emptypwd: user.emptypwd,
      savedCard: user.savedCard,
      balance: user.balance,
      dateCreated: user.dateCreated,
      firstName: formModel.firstName,
      lastName: formModel.lastName,
      userImage: formModel.userImage,
      phone: formModel.phone,
      country: formModel.address.country[0],
      currency: formModel.address.country[1],
      website: formModel.website,
      city: formModel.address.city,
      address: formModel.address.address,
      postNumber: formModel.address.postal_code,
      socials: formModel.socials.map(ppFormModel => {
        return Object.assign({}, this.getPublicProfile(user, ppFormModel.type), ppFormModel);
      }),
    };

    // Optional user fields.
    if (user.token) {
      updatedUser.token = user.token;
    }

    return this.fromResponseData(updatedUser);
  }

  toRequestPayload(user: Readonly<User>, values: Partial<User> = {}): UserPayload {
    const data: UserPayload = Object.assign({}, values);
    delete data.id;

    if (data.socials) {
      data.socials = data.socials.map(({type, url}) => ({type, url}));
    }

    return data;
  }

  fromResponseData(data: Readonly<UserFullData>): Readonly<User> {
    const user = new User();

    if (!data.id) {
      throw new Error('Attempt to create User object without user.id');
    }

    const completeness = data.completeness ?? ['basic', 'standard'];
    if (completeness.includes('standard')) {
      Object.keys(user).forEach(prop => {
        if (!(prop in data)) {
          throw new Error(`Unable to load user: missing ${prop} property`);
        }
      });
    }

    Object.assign(user, data);

    user.socials = user.socials.map(social => Object.freeze(social));

    return Object.freeze(user);
  }

  getPublicProfile(user: User, type: string) {
    return user.socials.find(profile => profile.type === type);
  }
}

import {environment} from '@env/environment';

let devInfo = '';
if (environment.debug && typeof window !== 'undefined' && window.location) {
  devInfo = ` (${window.location.hostname})`;
  (window as any).ngEnv = environment;
}

export const site = {
  name: 'Corite' + devInfo,
  meta: {
    image: {url: '/assets/images/welcome/socials.jpg', dimensions: [2800, 1400]} as const,
    description: $localize`:SEO default description [VERIFIED]:
Corite lets artists and fans join forces.

Back music you love and share the success when it’s streamed on Spotify and Apple Music.`,
  },
};

export function isSupportedCountry(country: string): boolean {
  // All countries (those allowed for register) are now supported.
  return !!country;
}

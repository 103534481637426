import {ChangeDetectionStrategy, Component, ContentChildren, Host, Input, Optional, QueryList} from '@angular/core';
import {
  LayoutMainTitleDirective,
  LayoutMainTitleFullDirective,
  LayoutSidebarLeftDirective,
  LayoutSidebarRightDirective,
} from './layout-regions.directive';
import {TitleActionDirective} from './title.directive';

export type TitleLevel = 'h1' | 'h2' | 'h3' | 'custom';

@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TitleComponent {
  @Input() level: TitleLevel | 'auto' = 'auto';

  @ContentChildren(TitleActionDirective)
  actions?: QueryList<TitleActionDirective>;

  constructor(
    @Host() @Optional() private dirTitle?: LayoutMainTitleDirective,
    @Host() @Optional() private dirTitleFull?: LayoutMainTitleFullDirective,
    @Optional() private dirSidebarLeft?: LayoutSidebarLeftDirective,
    @Optional() private dirSidebarRight?: LayoutSidebarRightDirective,
  ) {
  }

  get headingLevel(): TitleLevel {
    if ('auto' === this.level) {
      if (this.isPageTitle) {
        return 'h1';
      } else if (this.isAsideTitle) {
        return 'h3';
      } else {
        return 'h2';
      }
    }

    return this.level;
  }

  get isPageTitle(): boolean {
    return !!(this.dirTitle || this.dirTitleFull);
  }

  get isAsideTitle(): boolean {
    return !!(this.dirSidebarLeft || this.dirSidebarRight);
  }
}

import {Injectable} from '@angular/core';
import {FeatureFlag} from '../models';
import {FeatureFlagName, FeatureFlagSettings} from '../types';
import {FeatureFlagsLoader} from './ff-loader';


@Injectable({providedIn: 'root'})
export class FeatureFlagsService {
  constructor(public readonly loader: FeatureFlagsLoader) {}

  async resolveFeature<T extends FeatureFlagName>(name: T): Promise<FeatureFlag<T>> {
    await this.loader.ensureFFData();

    return this.loader.getFeature(name);
  }

  isFeatureSupported<T extends FeatureFlagName>(name: T, requireProp?: keyof FeatureFlagSettings[T]): boolean | undefined {
    if (this.loader.hasFFData()) {
      const feature = this.loader.getFeature(name);

      if (feature.isSupported && requireProp) {
        return !!feature.settings[requireProp];
      }

      return feature.isSupported;
    }

    return undefined;
  }
}

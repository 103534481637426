import {Component} from '@angular/core';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'page-wait',
  templateUrl: './wait.page.html',
})
export class WaitPage {
  constructor(private readonly title: Title) {
    this.title.setTitle('Loading...');
  }
}

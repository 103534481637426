import {APP_BASE_HREF, DOCUMENT, Location} from '@angular/common';
import {Inject, Injectable, Optional} from '@angular/core';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {environment} from '@env/environment';

export class LocationHelperError extends Error {
  constructor(message: string, public source: string | any[]) {
    super(message);
    this.name = 'LocationHelperError';

    Object.setPrototypeOf(this, LocationHelperError.prototype);
  }
}

@Injectable({providedIn: 'root'})
export class LocationHelper {
  readonly origin: string = this.baseHref || this.document.location.origin;

  constructor(public location: Location,
              private router: Router,
              @Inject(DOCUMENT) private document: Document,
              @Optional() @Inject(APP_BASE_HREF) protected baseHref?: string,
  ) { }

  isAbsolute(path: string): boolean {
    return path.includes('://');
  }

  isExternal(path: string): boolean {
    if (!this.isAbsolute(path)) {
      return false;
    }

    const trustedHosts = [
      this.origin,
      environment.adminUrl,
      environment.staticUrl,
      environment.cdnUrl,
      environment.uiUrl,
    ];

    return !trustedHosts.some(host => path.startsWith(host));
  }

  absolutePath(path: string): string;
  absolutePath(commands: any[], navigationExtras?: NavigationExtras): string;
  absolutePath(source: string | any[], navigationExtras?: NavigationExtras): string {
    let path: string;

    if (typeof source === 'string') {
      if (this.isAbsolute(source)) {
        throw new LocationHelperError('Generating paths from absolute URLs is not supported', source);
      }

      // Remove double-slashes just in case.
      path = ('/' + source).replace(/\/{2,}/g, '/');
    } else {
      path = this.router.createUrlTree(source, navigationExtras).toString();
    }

    return path === '/' ? path : Location.stripTrailingSlash(path);
  }

  absoluteURL(path?: string): string;
  absoluteURL(commands: any[], navigationExtras?: NavigationExtras): string;
  absoluteURL(source?: string | any[], navigationExtras?: NavigationExtras): string;
  absoluteURL(source?: string | any[], navigationExtras?: NavigationExtras): string {
    let path: string;
    if (typeof source === 'string') {
      if (this.isExternal(source)) {
        throw new LocationHelperError('Unacceptable Origin!', source);
      } else if (this.isAbsolute(source)) {
        return source;
      }

      path = this.absolutePath(source);
    } else if (source && Array.isArray(source)) {
      path = this.absolutePath(source, navigationExtras);
    } else {
      path = this.location.path();
    }

    return this.origin + this.location.normalize(path);
  }

  historyPush(commands: any[], relativeTo?: ActivatedRoute): void {
    return this.location.go(this.nextHistoryPath(commands, relativeTo));
  }

  historyReplace(commands: any[], relativeTo?: ActivatedRoute): void {
    return this.location.replaceState(this.nextHistoryPath(commands, relativeTo));
  }

  private nextHistoryPath(commands: any[], relativeTo?: ActivatedRoute) {
    if (relativeTo) {
      return this.absolutePath(commands, {relativeTo});
    } else {
      return this.absolutePath(commands);
    }
  }
}

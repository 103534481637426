<div class="simple app-layout-center">
  <div class="page-title" *ngIf="layoutGridTitle?.template" data-e2e="page-title">
    <ng-container [ngTemplateOutlet]="layoutGridTitle.template"></ng-container>
  </div>

  <div class="content">
    <ng-container *ngIf="layoutGridContent?.template" [ngTemplateOutlet]="layoutGridContent.template"></ng-container>
  </div>

  <div class="sidebar-left" *ngIf="layoutGridSidebarLeft?.template">
    <ng-container [ngTemplateOutlet]="layoutGridSidebarLeft.template"></ng-container>
  </div>
</div>

<!-- Workaround: https://github.com/angular/angular/issues/50543#issuecomment-1572900259 -->
<ng-content></ng-content>

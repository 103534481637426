<app-layout-simple>
  <app-title *appLayoutMainTitle>Access denied</app-title>

  <main *appLayoutContent>
    <p i18n="Error page 403: text [VERIFIED]">Sorry, you don't have permission to access this page.</p>

    <div class="app-buttons">
      <a mat-flat-button color="primary" routerLink="/user/login"
         i18n="Error page 403: /user/login link [VERIFIED]">Sign In</a>
    </div>
  </main>
</app-layout-simple>

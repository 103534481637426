import {HttpClient} from '@angular/common/http';
import {Injectable, NgZone} from '@angular/core';
import {ApiResponse} from '@app/core/services/api-interceptor.service';
import {PagedList, PagedListData, PagedListFactory} from '@app/core/services/paged-list-factory.service';
import {mapObject} from '@util/misc';
import {defer, distinct, firstValueFrom, Observable, ObservableInput, of, Subject, throwError} from 'rxjs';
import {delayWhen, map, share, startWith, switchMap, tap} from 'rxjs/operators';
import {Campaign, CampaignApiData, CampaignPayload, CampaignRevId, CampaignStatus} from '../models/campaign';
import {CampaignSlug, CampaignSlugApiData, CampaignSlugType} from '../models/campaign/slug';
import {CampaignPurgeStatus, CampaignVisibility} from '../models/campaign/status';
import {CampaignBacker} from '../models/campaign/users';
import {Genre} from '../models/genre';
import {ReleasePayload} from '../models/release';
import {CampaignNormalizer} from './campaign-normalizer.service';

export interface CampaignListData extends PagedListData {
  campaigns: CampaignApiData[];
}

export type CampaignPagedList = PagedList<Campaign, CampaignListData>;

export type CampaignApiResponse = ApiResponse<CampaignApiData>;

export interface CampaignListFilters {
  status?: CampaignStatus | CampaignStatus[];
  visibility?: CampaignVisibility | CampaignVisibility[];
  order?: 'name',
}

@Injectable({providedIn: 'root'})
export class CampaignService {
  protected campaigns: {
    id: number,
    update$: Observable<Campaign>,
    rev: number | string,
    current: Campaign,
    source: Subject<Campaign>,
  }[] = [];

  constructor(private http: HttpClient,
              private zone: NgZone,
              private normalizer: CampaignNormalizer,
              private pagedListFactory: PagedListFactory<Campaign>,
  ) {}

  create(distributionOnly = false): Observable<Campaign> {
    const campaign = new Campaign();
    const campaign$ = this.getCampaignSourceByKeys(null, 'last');

    if (distributionOnly) {
      campaign.visibility = CampaignVisibility.DistributionOnly;
    }

    campaign$.next(campaign);

    return campaign$.pipe(startWith(campaign));
  }

  load(id: number, rev?: CampaignRevId, full = true, skipErrors = false): Observable<Campaign> {
    const campaign$ = this.getCampaignSourceByKeys(id, rev);

    let url = `<apiUrl>/campaign/${id}`;
    if (rev) {
      url += `/${rev}`;
    }

    this.http.get<CampaignApiResponse>(url, {params: {full: full ? '1' : '0'}}).subscribe({
      next: response => this.acceptCampaignData(response.data, [campaign$], rev),
      error: err => {
        if (!skipErrors) {
          campaign$.error(err);
        }
      },
    });

    return campaign$.asObservable();
  }

  loadSlug(slug: string): Observable<CampaignSlug> {
    const req = {
      url: `<apiUrl>/search/by-path/${slug}`,
    };

    return this.http.get<API.Response<CampaignSlugApiData>>(req.url)
      .pipe(
        map(response => {
          if (typeof response.data.resType !== 'number') {
            throw new TypeError('resType property of type number expected');
          }

          return response.data;
        }),
        switchMap((slugData: CampaignSlugApiData): ObservableInput<CampaignSlug> => {
          if (slugData.resType === CampaignSlugType.Redirect) {
            // TODO for Den: Unify all of these into SlugService.
            if (slugData.redirect.url[0] !== '/') {
              slugData.redirect.url = `/${slugData.redirect.url}`;
            }

            return of({
              resType: slugData.resType,
              redirect: slugData.redirect,
            });
          } else if (slugData.resType === CampaignSlugType.List) {
            return of({
              resType: slugData.resType,
              campaigns: this.acceptListData(slugData, 'published'),
            });
          } else if (slugData.resType === CampaignSlugType.Campaign) {
            const campaign$ = this.getCampaignSourceByKeys(slugData.id, 'published');

            return of({
              resType: slugData.resType,
              campaign$: this.getCampaignSourceByKeys(slugData.id, 'published').pipe(
                startWith(this.acceptCampaignData(slugData, [campaign$])),
                distinct(),
              ),
            });
          }

          // Treat CampaignSlugType.None and any unexpected `reType`s as 404.
          return ApiResponse.throwAs404();
        }),
      );
  }

  async reload(campaign: Campaign): Promise<Campaign> {
    if (!campaign.revRequested) {
      throw new Error('Unable to reload existing campaign as revRequested is empty.');
    }

    return await firstValueFrom(this.load(campaign.id, campaign.revRequested));
  }

  observeExisting(campaign: Campaign): Observable<Campaign> {
    if (!campaign.revRequested) {
      return throwError(() =>
        new Error('Unable to observe existing campaign as revRequested is empty.'),
      );
    }

    const source = this.getCampaignSourceByKeys(campaign.id, campaign.revRequested);
    const campaign$ = source
      ? source.asObservable()
      : this.load(campaign.id, campaign.revRequested);

    return campaign$.pipe(startWith(campaign));
  }

  listOwn(filters?: CampaignListFilters): CampaignPagedList {
    return this.list(`<apiUrl>/campaign/list`, filters, 'last');
  }

  listByAuthor(userId: number, filters?: CampaignListFilters): CampaignPagedList {
    return this.list(`<apiUrl>/campaign/list/${userId}`, filters);
  }

  listAll(filters?: CampaignListFilters): CampaignPagedList {
    return this.list(`<apiUrl>/campaign/list/all`, filters);
  }

  listBacked(filters?: CampaignListFilters): CampaignPagedList {
    return this.list(`<apiUrl>/campaign/list/backed`, filters);
  }

  /**
   * Updates Campaign values partially.
   */
  update(campaign: Campaign, values: CampaignPayload): Observable<Campaign> {
    if (campaign.id) {
      return this.doUpdate(campaign, values);
    }

    if (this.getAllCampaignSources(campaign).length > 1) {
      throw new Error('More than ONE source of New campaign detected.');
    }

    const cacheItem = this.campaigns.find(item => item.current === campaign);
    if (cacheItem.update$) {
      return of(true)
        .pipe(delayWhen(() => cacheItem.update$))
        .pipe(switchMap(() => this.doUpdate(cacheItem.current, values)));
    } else {
      return cacheItem.update$ = this.doUpdate(cacheItem.current, values)
        .pipe(tap({
          next: () => { cacheItem.update$ = null; },
          error: () => { cacheItem.update$ = null; },
          complete: () => { cacheItem.update$ = null; },
        }));
    }
  }

  updateReleaseInfo(campaign: Campaign, values: ReleasePayload): Observable<Campaign> {
    return defer(() => {
      const payload = this.normalizer.exportReleaseData(campaign, values);
      const campaigns$ = this.getAllCampaignSources(campaign);

      return this.http.post<CampaignApiResponse>('<apiUrl>/campaign/update/release_info', payload)
        .pipe(
          share(),
          map(response => this.acceptCampaignData(response.data, campaigns$, 'last')),
        );
    });
  }

  delete(campaign: Campaign | Campaign[], withRelease = false): Observable<{[id: number]: CampaignPurgeStatus}> {
    if (campaign instanceof Campaign) {
      campaign = [campaign];
    }

    const ids = campaign.map(c => c.id);

    return this.http.post<API.Response<{[id: number]: CampaignPurgeStatus}>>('<apiUrl>/campaign/delete',
      {id: ids, detach: !withRelease},
    ).pipe(map(response => response.data));
  }

  getGenresList(): Observable<Genre[]> {
    return this.http.get<API.Response<{genres: string[]}>>('<apiUrl>/campaign/list/genres')
      .pipe(map(response => {
        const list: Genre[] = [];

        Object.keys(response.data.genres).forEach(id => {
          list.push(new Genre(+id, response.data.genres[+id]));
        });

        return list;
      }));
  }

  getBackers(campaign: Campaign): Observable<CampaignBacker[]> {
    return this.http.get<API.Response<CampaignBacker[]>>(`<apiUrl>/campaign/${campaign.id}/backers`)
      .pipe(map(response =>
        response.data.map(backer => {
          return Object.assign(new CampaignBacker(), backer, {
            percent: backer.share / 100,
          });
        }),
      ));
  }

  protected getAllCampaignSources(campaign: Campaign): Subject<Campaign>[] {
    const sources: Subject<Campaign>[] = [];

    this.campaigns.forEach(item => {
      if (item.current === campaign) {
        sources.push(item.source);
      }
    });

    if (!sources.length) {
      throw new Error('Attempt to access source of campaign that is not known to the CampaignService.');
    }

    return sources;
  }

  protected getCampaignSourceByKeys(id: number, rev?: number | string, mustExist = false): Subject<Campaign> | never {
    let cacheItem = id && this.campaigns.find(item => item.id === id && item.rev === rev);

    if (!cacheItem) {
      if (mustExist) {
        throw new Error('Attempt to access source of campaign that is not known to the CampaignService.');
      }

      cacheItem = {
        id,
        rev,
        update$: null,
        current: null,
        source: new Subject<Campaign>(),
      };

      cacheItem.source.subscribe({
        next: campaign => cacheItem.current = campaign,
        error: () => { /* not a big deal, ignore */ },
      });

      this.campaigns.push(cacheItem);
    }

    return cacheItem.source;
  }

  protected acceptCampaignData(data: CampaignApiData,
                               campaigns$?: Subject<Campaign>[],
                               revRequested: CampaignRevId = 'published',
  ): Campaign {
    const campaign = this.normalizer.importCampaignData(data, revRequested);

    // Reserve id:revId cache item, so it can be found in the future.
    this.getCampaignSourceByKeys(campaign.id, campaign.revId);

    this.campaigns.forEach(item => {
      const isSameSource = !!campaigns$ && campaigns$.includes(item.source);
      const isSameKeys = item.id === campaign.id && item.rev === campaign.revId;

      if (isSameSource || isSameKeys) {
        // Update id for NEW campaigns just saved. Existing campaigns' ID stay the same.
        item.id = campaign.id;

        // Notify all subscribers.
        // - Postpone emission on next tick to ensure all subscribers will get
        //   the campaign from cached SSR request
        // - Also ensure NgZone as sometimes it's necessary to run whole
        //   CRUD functions outside of NgZone.
        setTimeout(() => {
          this.zone.run(() => item.source.next(campaign));
        });
      }
    });

    return campaign;
  }

  protected doUpdate(campaign: Campaign, values: CampaignPayload): Observable<Campaign> {
    const campaigns$ = this.getAllCampaignSources(campaign);

    const payload = this.normalizer.exportCampaignData(campaign, values);

    return this.http.post<CampaignApiResponse>('<apiUrl>/campaign/update', payload)
      .pipe(
        share(),
        map(response => this.acceptCampaignData(response.data, campaigns$, 'last')),
      );
  }

  protected list(url: string,
                 filters: CampaignListFilters = {},
                 revRequested: CampaignRevId = 'published',
  ): CampaignPagedList {
    const list = this.pagedListFactory.createPagedList<CampaignListData>(url)
      .setAcceptTransformer(response => this.acceptListData(response.data, revRequested));

    if (filters) {
      list.setParams(mapObject(filters, value => value.toString()));
    }

    return list;
  }

  protected acceptListData(data: {campaigns: CampaignApiData[]}, revRequested: CampaignRevId): Campaign[] {
    return data.campaigns.map(campaignData => this.normalizer.importCampaignData(campaignData, revRequested));
  }
}

import {Component, OnInit} from '@angular/core';
import {PageMetaService} from '@app/core/services/page-meta.service';

@Component({
  selector: 'app-wait',
  templateUrl: './wait.component.html',
  styleUrls: ['./wait.component.scss'],
})
export class WaitComponent implements OnInit {
  constructor(private meta: PageMetaService) {}

  ngOnInit() {
    this.meta.setTitles('Loading...').setNoIndex().flush();
  }
}

import {Directive, ElementRef, HostBinding, HostListener, Input} from '@angular/core';
import {LinkTarget} from '@util/types';
import {CoriteWVApp} from '@util/wv';

@Directive({
  standalone: true,
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'a[href][target=_blank]',
})
export class LinkTargetDirective {
  @HostBinding('attr.href') hrefAttr: string;
  @HostBinding('attr.rel') relAttr = 'noopener';

  @Input() set href(value: string) {
    const target = this.el.nativeElement.target as LinkTarget;
    this.hrefAttr = CoriteWVApp.urlAppendLinkTarget(value, target);
  }

  @Input() set rel(value: string) {
    this.relAttr = `noopener ${value}`;
  }

  constructor(private el: ElementRef<HTMLAnchorElement>) {}

  @HostListener('window:load')
  onWindowLoad() {
    CoriteWVApp.elementAppendLinkTarget(this.el);
  }
}

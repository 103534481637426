import {Observable} from 'rxjs';
import {Campaign, CampaignApiData} from '../campaign';

export enum CampaignSlugType {
  None = 0,
  Campaign = 1,
  List = 2,
  Redirect = 3,
}

export interface CampaignSlugRedirect {
  url: string;
}

export type CampaignSlugApiData = {resType: CampaignSlugType.None}
  | {resType: CampaignSlugType.Campaign} & CampaignApiData
  | {resType: CampaignSlugType.List} & {campaigns: CampaignApiData[]}
  | {resType: CampaignSlugType.Redirect} & {redirect: CampaignSlugRedirect};

export interface CampaignSlug {
  resType: CampaignSlugType;
  campaigns?: Campaign[];
  campaign$?: Observable<Campaign>;
  redirect?: CampaignSlugRedirect;
}

import {Inject, Injectable, Optional} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {VERSION} from '@env/version';
import {APP_SERVER_BASE_HREF} from '../../app.tokens';

class SVGIconsFactory {
  constructor(private iconRegistry: MatIconRegistry,
              private sanitizer: DomSanitizer,
              private baseUrl?: string,
              private namespace?: string) {
  }

  // noinspection JSUnusedGlobalSymbols
  addIcon(name: string, filename = name): this {
    if (this.namespace) {
      this.iconRegistry.addSvgIconInNamespace(this.namespace, name, this.getSafeURL(filename));
    } else {
      this.iconRegistry.addSvgIcon(name, this.getSafeURL(filename));
    }

    return this;
  }

  // noinspection JSUnusedGlobalSymbols
  addIconSet(filename: string, setNamespace?: string): this {
    setNamespace = setNamespace || this.namespace || filename;
    this.iconRegistry.addSvgIconSetInNamespace(setNamespace, this.getSafeURL(filename));

    return this;
  }

  protected getSafeURL(filename: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(`${this.baseUrl}/${filename}.svg?v${VERSION}`);
  }
}

@Injectable()
export class IconsService {

  constructor(private iconRegistry: MatIconRegistry,
              private sanitizer: DomSanitizer,
              @Optional() @Inject(APP_SERVER_BASE_HREF) private origin: string) {
    this.iconRegistry.setDefaultFontSetClass('font-icon', 'mat-ligature-font');
  }

  createSVGFactory(dir?: string, namespace?: string): SVGIconsFactory {
    const directory = dir || 'icons';

    let baseUrl = `assets/${directory}`;
    if (this.origin) {
      baseUrl = `${this.origin}/${baseUrl}`;
    }

    return new SVGIconsFactory(this.iconRegistry, this.sanitizer, baseUrl, namespace);
  }

  addDefaultIcons() {
    this.createSVGFactory('iconsets')

      // google
      .addIconSet('login-social')

      // stripe
      // swish
      // paypal
      // visa
      // mastercard
      // visa-mastercard
      .addIconSet('payment')

      // risks
      // goal
      // experience
      .addIconSet('risks')

      // app-store
      .addIconSet('apps')

      // pancakeswap
      .addIconSet('pancakeswap')

      // currency
      .addIconSet('currency');
  }
}

import {Component, HostBinding, Input, OnChanges, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-spacer',
  templateUrl: './spacer.component.html',
  styleUrls: ['./spacer.component.scss'],
})
export class SpacerComponent implements OnChanges {
  @Input() height: number | string;
  @Input() width: number | string | boolean;
  @Input() @HostBinding('class.flex') flex: boolean | string;
  @HostBinding('class.inline') inline = false;

  protected widthIsNumber: boolean;
  protected heightIsNumber: boolean;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.flex) {
      if (typeof this.flex === 'string') {
        this.flex = (this.flex === '');
      }
    }

    if (changes.width) {
      // Type of width is number or string with only number
      this.widthIsNumber = (this.width + '').match(/^\d+$/) !== null;
      this.inline = this.widthIsNumber;
    }

    if (changes.height) {
      // Type of width is number or string with only number
      this.heightIsNumber = (this.height + '').match(/^\d+$/) !== null;
    }
  }

  getStyles() {
    const result: {[prop: string]: number | string} = {};

    if (this.widthIsNumber) {
      result['width.px'] = this.width as number | string;
    }

    if (this.heightIsNumber) {
      result['height.px'] = this.height;
    }

    if (this.width === undefined && this.height === undefined && this.flex === undefined) {
      result['height.px'] = 15;
    }

    return result;
  }
}

<strong>
  <a [href]="href" [target]="target">
    <!--  Unable to use <img> due to different color in header/footer  -->
    <!--  TODO for Julia: Replace this with font ligature in favor of svg/aria/visually-hidden approach  -->
    <svg class="logo-icon" width="90" height="29" viewBox="0 0 90 29" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
      <g>
        <path transform="translate(70.4844 9.26367)" d="M19.1087 11.4303C19.3597 7.62017 18.5351 4.58655 16.7067 2.61831C15.0934 0.884807 12.7631 -1.37766e-07 9.7874 -1.37766e-07C3.94364 -1.37766e-07 2.18819e-06 3.95454 2.18819e-06 9.82316C2.18819e-06 15.7279 3.88986 19.538 9.9308 19.538C13.265 19.538 16.0793 18.3643 18.0511 16.1613L18.069 16.1432L15.183 13.1999L15.1651 13.2179C13.8744 14.5181 12.1715 15.2403 10.3789 15.2403C8.03069 15.2403 6.2202 13.8861 5.55695 11.5928L5.5211 11.4483H19.1267L19.1087 11.4303ZM5.39561 8.17995L5.41354 8.03549C5.89753 5.54359 7.52877 4.06289 9.80532 4.06289C12.2074 4.06289 13.8386 5.63387 13.9641 8.05355V8.17995H5.39561Z"></path>
        <path transform="translate(35.1699 9.24512)" d="M10.2893 5.63387C10.7195 5.63387 11.3469 5.65193 12.046 5.79639V0.162515C11.6517 0.0541716 11.1139 1.37766e-07 10.612 1.37766e-07C8.92697 1.37766e-07 6.63249 0.722292 5.70035 2.74471L5.48525 3.23225V0.180573H-5.47047e-07V19.3935H5.6645V11.0149C5.6645 6.15753 8.90904 5.63387 10.2893 5.63387Z"></path>
        <path transform="translate(49.1523 9.42578)" d="M5.6645 0H2.18819e-06V19.213H5.6645V0Z"></path>
        <path transform="translate(48.6504)" d="M3.29831 0C1.4699 0 -1.09409e-06 1.51681 -1.09409e-06 3.35865C-1.09409e-06 5.2005 1.48783 6.71731 3.29831 6.71731C5.16258 6.71731 6.68626 5.2005 6.68626 3.35865C6.68626 1.51681 5.16258 0 3.29831 0Z"></path>
        <path transform="translate(56.6445 3.75586)" d="M9.76947 25.2441C11.4545 25.2441 12.4045 24.8829 12.7272 24.7385V20.0255C12.3508 20.0978 11.8309 20.188 11.2932 20.188C10.2355 20.188 9.03452 19.9352 9.03452 18.0392V10.6357H12.7989V5.66999H9.03452V-1.37766e-07H3.97949V2.38356C3.97949 4.40598 2.7964 5.66999 0.878355 5.66999H0V10.6357H3.4238V19.0865C3.4238 22.8966 5.86168 25.2441 9.76947 25.2441Z"></path>
        <path transform="translate(0 9.28125)" d="M23.8232 19.538C29.165 19.538 33.5209 15.1501 33.5209 9.76899C33.5209 4.38792 29.165 2.75532e-07 23.8232 2.75532e-07C18.8219 2.75532e-07 14.9321 3.93649 14.1254 9.8051C13.8386 11.9359 12.064 14.2291 9.69777 14.2291C7.25988 14.2291 5.27014 12.2248 5.27014 9.76899C5.27014 7.3132 7.25988 5.30884 9.69777 5.30884C10.361 5.30884 11.3111 5.50747 12.0102 5.97696C12.5479 4.27958 13.3546 2.78082 14.6273 1.37235C13.014 0.39726 11.2573 2.75532e-07 9.69777 2.75532e-07C4.35593 2.75532e-07 0 4.38792 0 9.76899C0 15.1501 4.35593 19.538 9.69777 19.538C12.3328 19.538 14.7886 18.5087 16.6171 16.6488L16.7784 16.4863L16.9397 16.6488C18.7861 18.5087 21.224 19.538 23.8232 19.538ZM23.8232 5.30884C26.2611 5.30884 28.2508 7.3132 28.2508 9.76899C28.2508 12.2248 26.2611 14.2291 23.8232 14.2291C21.3853 14.2291 19.3955 12.2248 19.3955 9.76899C19.3955 7.3132 21.3674 5.30884 23.8232 5.30884Z"></path>
      </g>
    </svg>
    <span class="cdk-visually-hidden">{{ site.name }}</span>
  </a>
</strong>

import {FacebookPageConfig} from '@app/widgets/facebook/models';
import {pick} from '@util/misc';

export interface CampaignFacebookData extends FacebookPageConfig {}

export class CampaignFacebook implements CampaignFacebookData {
  pageId: `${number}` = null;
  accessToken?: string = null;
  fbFans = true;
  igFollowers = true;
  igMedia = true;

  public static fromData(data: CampaignFacebookData): CampaignFacebook {
    const fb = new CampaignFacebook();

    Object.assign(fb, pick(data, [
      'pageId', 'accessToken', 'fbFans', 'igFollowers', 'igMedia',
    ]));

    return fb;
  }

  toData(): CampaignFacebookData | null {
    if (this.pageId) {
      return {
        pageId: this.pageId,
        accessToken: this.accessToken,
        fbFans: this.fbFans,
        igFollowers: this.igFollowers,
        igMedia: this.igMedia,
      };
    }

    return null;
  }
}

export class CampaignInstagramUser {
  id: number = null;
  username: string = null;
}

export class CampaignInstagramMedia {
  caption?: string;
  mediaUrl: string = null;
  mediaType: 'IMAGE' | 'VIDEO' | 'CAROUSEL_ALBUM' = null;
  permalink: string = null;
}

export interface CampaignInstagramFeedData {
  user: Partial<CampaignInstagramUser>;
  feed?: Partial<CampaignInstagramMedia>[];
}

export class CampaignInstagramFeed {
  user = new CampaignInstagramUser();
  feed: CampaignInstagramMedia[] = [];

  get hasMedia(): boolean {
    return !!this.user.username && this.feed.length > 0;
  }

  // noinspection JSUnusedGlobalSymbols (implements CampaignDataChunk)
  public static fromData(data: CampaignInstagramFeedData) {
    const instance = new CampaignInstagramFeed();

    Object.assign(instance.user, data.user);

    if (data.feed) {
      instance.feed = data.feed.map(item => {
        return Object.assign(new CampaignInstagramMedia(), item);
      });
    }

    return instance;
  }
}

export type CampaignNetworkType = 'spotify' | 'soundcloud' | 'youtube'
  | 'instagram' | 'instagramBusiness' | 'facebook' | 'tiktok' | 'twitter';

export interface CampaignSocialActivityData {
  type: CampaignNetworkType;
  url: string;
  count: number | null;
}

export class CampaignSocialActivity implements CampaignSocialActivityData {
  static readonly NETWORKS: CampaignNetworkType[] = [
    'spotify', 'soundcloud', 'youtube', 'instagram', 'facebook', 'tiktok',
  ];

  url: string = null;
  count: number | null = null;
  error?: boolean;

  constructor(public type: CampaignNetworkType) {}

  static fromData(data: CampaignSocialActivityData, asType?: CampaignNetworkType): CampaignSocialActivity {
    const instance = new CampaignSocialActivity(asType || (data.type as CampaignNetworkType));

    instance.url = data.url as string;
    instance.count = data.count as number;

    return instance;
  }
}

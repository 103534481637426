import {DOCUMENT, Location} from '@angular/common';
import {Component, Inject, OnInit, Optional, Renderer2, ViewEncapsulation} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import {environment} from '@env/environment';
import {RESPONSE} from '@nguniversal/express-engine/tokens';
import {retryWhenDocumentVisible} from '@util/rxjs/repeat-retry';
import {Response} from 'express-serve-static-core';
import {timer} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {PageWithOptions} from '../../abstracts/page';
import {AuthService} from '../../services/auth.service';
import {PageMetaService} from '../../services/page-meta.service';

@Component({
  selector: 'page-err503',
  templateUrl: './err503.page.html',
  styleUrls: ['./err503.page.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class Err503Page implements PageWithOptions, OnInit {
  readonly assetsBase = `${environment.staticUrl}/assets/maintenance`;
  withHeader = false;
  withFooter = false;
  withCookieConsent = false;
  withPlayer = false;

  assets = (() => {
    return {
      css: this.sanitizer.bypassSecurityTrustResourceUrl(
        `${this.assetsBase}/maintenance.css`,
      ),
      img: this.sanitizer.bypassSecurityTrustResourceUrl(
        `${this.assetsBase}/maintenance-bg.jpg`,
      ),
    };
  })();

  constructor(private meta: PageMetaService,
              private auth: AuthService,
              private location: Location,
              private route: ActivatedRoute,
              private sanitizer: DomSanitizer,
              private renderer: Renderer2,
              @Inject(DOCUMENT) private document: Document,
              @Optional() @Inject(RESPONSE) private res: Response,
  ) {
    // The this that should not be but...
    // ...if user ends up to /503, /500, /404, etc. URL show root page
    // after reload.
    if (this.location.path().match(/^\/\d+$/)) {
      this.location.replaceState('/');
    }
  }

  ngOnInit() {
    this.meta.setTitles('Service unavailable').flush();

    if (this.res) {
      const script: HTMLScriptElement = this.renderer.createElement('script');
      script.src = '/503.js';
      script.setAttribute('data-ssr-preserve', '');
      script.setAttribute('data-url', `${environment.apiUrl}/user/start?css`);
      this.renderer.appendChild(this.document.head, script);

      this.res.locals.onlySSR = true;
      this.res.status(503);
    } else {
      timer(5000).pipe(
        switchMap(() => this.auth.defineUserOnce()),
        retryWhenDocumentVisible(),
      ).subscribe(() => window.location.reload());
    }
  }
}

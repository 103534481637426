import {ComponentPortal} from '@angular/cdk/portal';
import {DOCUMENT} from '@angular/common';
import {Component, HostListener, Inject, OnDestroy, OnInit, PLATFORM_ID, TemplateRef} from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {AppStateService} from '@app/core/services/app-state.service';
import {AuthService} from '@app/core/services/auth.service';
import {BackButtonService} from '@app/core/services/back-button.service';
import {SafeAreaService} from '@app/core/services/safe-area.service';
import {OldCryptoAuthService} from '@app/services/blockchain/services/old-crypto-auth.service';
import {UserNavPopupComponent} from '@app/widgets/user-menu/components/nav-popup/user-nav-popup.component';
import {environment} from '@env/environment';
import {asyncScheduler, Observable} from 'rxjs';
import {observeOn} from 'rxjs/operators';
import {BreakpointsService} from '../../../../common/modules/responsive/breakpoints.service';
import {AppHeaderMenuLoader} from '../../services/menu-loader.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  protected readonly environment = environment;

  readonly topHeight = 48;
  readonly mainMenuHeight = 48;
  readonly heightMin = this.topHeight;
  readonly heightMax = this.topHeight + this.mainMenuHeight;

  height: number;
  heightStyle: number;
  isClosed: boolean;
  isAnimation: boolean;
  menuPortal$: Observable<ComponentPortal<UserNavPopupComponent>>;

  private scrollTop: number;
  private hasMainMenuMobile = false;
  private tabMobileVisibility = 'auto';
  private scrollingElement: Element = this.document.scrollingElement || this.document.documentElement;

  get shouldShowSignIn(): boolean {
    return !this.auth.isAuthenticated();
  }

  get shouldShowActivity(): boolean {
    return this.auth.isRegistered();
  }

  get shouldShowWallet() {
    return this.auth.isRegistered() && this.cryptoAuth.state.isConnected === false;
  }

  private get documentScrollTop(): number {
    return this.scrollingElement.scrollTop;
  }

  constructor(public backButton: BackButtonService,
              private auth: AuthService,
              private appState: AppStateService,
              private breakpoints: BreakpointsService,
              private loader: AppHeaderMenuLoader,
              private dialog: MatDialog,
              private cryptoAuth: OldCryptoAuthService,
              private safeArea: SafeAreaService<'header-top' | 'header-max'>,
              @Inject(DOCUMENT) private document: Document,
              @Inject(PLATFORM_ID) private pId: object,
  ) {
    this.breakpoints.observe(['cssTab']).pipe(
      // Schedule to next macrotask due to BreakpointObserver/rxJS/Safari bug
      // (MediaMatcher fires twice, debounceTime() swallows both events, layout broken)
      observeOn(asyncScheduler),
    ).subscribe((state) => {
      this.hasMainMenuMobile = !state.matches;

      if (this.tabMobileVisibility !== 'never') {
        this.resetMainMenuMobile();
      }
    });

    this.menuPortal$ = this.loader.observeMenuPortal();

    this.resetMainMenuMobile();

    this.appState.fixedComponents.set(HeaderComponent, this);
  }

  ngOnInit(): void {
    this.safeArea.setSafeTop('header-top', this.heightMin);
  }

  ngOnDestroy(): void {
    this.safeArea
      .resetSafeArea('header-top')
      .resetSafeArea('header-max');
  }

  @HostListener('window:scroll')
  onWindowScroll() {
    if (this.hasMainMenuMobile && this.tabMobileVisibility === 'auto') {
      this.mainMenuMobileMove();
    }
  }

  setMainMenuMobileVisibility(visibility: 'always' | 'never' | 'auto') {
    this.tabMobileVisibility = visibility;
    switch (visibility) {
      case 'auto':
        this.resetMainMenuMobile();
        break;
      case 'never':
        this.hideMainMenuMobile();
        break;
      case 'always':
        this.showMainMenuMobile();
        break;
    }
  }

  openDialog(tpl: TemplateRef<any>) {
    this.dialog.open(tpl, {width: '375px'});
  }

  private resetMainMenuMobile() {
    this.isClosed = false;
    this.isAnimation = false;

    if (this.hasMainMenuMobile) {
      this.height = this.heightMax;

      // Seems to be obsolete but too risky for removal so far...
      // this.scrollTop = this.documentScrollTop;
    } else {
      this.height = this.heightMin;
    }
  }

  private mainMenuMobileMove() {
    if (this.documentScrollTop < 0) {
      return;
    }

    if (this.scrollingElement.clientHeight === this.scrollingElement.scrollHeight) {
      return;
    }

    const scrollDifference = this.documentScrollTop - this.scrollTop;

    // Scrolling at the top of the page
    if (this.scrollTop < this.mainMenuHeight) {
      // When you scrolled down less than a header height
      // then move main menu together with a finger.
      this.moveGranularMainMenuMobile(scrollDifference);

      // Scroll down
    } else if (scrollDifference > 0) {
      if (this.height > this.heightMin) {
        // When you are in the middle of the page and main menu are visible
        // then hide main menu immediately.
        this.hideMainMenuMobile();
      } else {
        // Main menu closed, do nothing
      }

      // Scroll up (with 5px-per-event threshold)
    } else if (scrollDifference < -5) {
      this.showMainMenuMobile();
    }

    this.scrollTop = this.documentScrollTop;
  }

  private moveGranularMainMenuMobile(scrollDifference: number) {
    this.isAnimation = false;
    const diff = this.height - scrollDifference;
    this.height = Math.min(Math.max(diff, this.heightMin), this.heightMax);
    this.heightStyle = this.height;

    if (this.height === this.heightMin) {
      this.isClosed = true;
      delete this.heightStyle;
    } else if (this.height === this.heightMax) {
      this.isClosed = false;
      delete this.heightStyle;
    }

    this.scrollTop = this.documentScrollTop;
  }

  private hideMainMenuMobile() {
    if (this.hasMainMenuMobile) {
      this.isAnimation = true;
      this.height = this.heightMin;
      this.isClosed = true;

      this.scrollTop = this.documentScrollTop;
    }

    if (this.tabMobileVisibility === 'never') {
      this.isClosed = true;
    }

    delete this.heightStyle;

    this.safeArea.resetSafeArea('header-max');
  }

  private showMainMenuMobile() {
    if (this.hasMainMenuMobile) {
      this.isClosed = false;
      this.isAnimation = true;
      this.height = this.heightMax;

      this.scrollTop = this.documentScrollTop;
    }

    delete this.heightStyle;

    this.safeArea.setSafeTop('header-max', this.heightMax);
  }
}

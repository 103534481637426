import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import {Inject, Injectable, InjectionToken} from '@angular/core';
import {merge, Observable} from 'rxjs';
import {distinctUntilKeyChanged, map} from 'rxjs/operators';

export interface AppBreakpoints {
  [name: string]: string;
}

export const APP_BREAKPOINTS = new InjectionToken<AppBreakpoints>('App Breakpoints');

export interface AppBreakpointState extends BreakpointState {
  name: string;
}

@Injectable({providedIn: 'root'})
export class BreakpointsService {

  constructor(@Inject(APP_BREAKPOINTS) private breakpoints: AppBreakpoints, private observer: BreakpointObserver) {}

  observe(breakpoints: string[]): Observable<BreakpointState> {
    return this.observer
      .observe(breakpoints.map(name => this.breakpoints[name]))
      .pipe(distinctUntilKeyChanged('matches'));
  }

  observeEach(names?: string[]): Observable<AppBreakpointState> {
    names = names || Object.keys(this.breakpoints);

    const breakpoint$List = names.map(name => {
      const query = this.breakpoints[name];

      return this.observer.observe(query)
        .pipe(map(result => ({name, ...result})));
    });

    return merge(...breakpoint$List);
  }
}

<div class="container">
  <div class="app-layout-center footer">
    <div class="bottom">
      <div class="bottom__left">
        <p class="copyright">
          <span i18n="Footer: Copyright [VERIFIED]"><strong>Corite </strong>copyright &copy; {{ year }}.</span>
          &ngsp;
          <span i18n="Footer: Copyright [VERIFIED]">All Rights Reserved.</span>
        </p>
        <ul class="links">
          <li><a i18n="Footer: /news link [VERIFIED]@@newsLink"
                 routerLink="/news">News</a></li>
          <li><a i18n="Footer: /terms-of-use link [VERIFIED]"
                 routerLink="/terms-of-use">Terms of use</a></li>
          <li><a i18n="Footer: /privacy-policy link [VERIFIED]"
                 routerLink="/privacy-policy">Privacy policy</a></li>
          <li><a href="https://support.corite.com/" target="_blank"
                 i18n="Footer: support.corite.com link [VERIFIED]">Support</a></li>
        </ul>
      </div>
      <div class="bottom__right">
        <div class="social-links">
          <a href="https://www.facebook.com/corite/" target="_blank" aria-label="Corite on Facebook">
            <mat-icon class="social-icon">facebook2</mat-icon>
          </a>
          <a href="https://www.instagram.com/corite/" target="_blank" aria-label="Corite on Instagram">
            <mat-icon class="social-icon">instagram2</mat-icon>
          </a>
          <a href="https://www.youtube.com/channel/UCIuP596K0Db-i2uNWKIRTNQ" target="_blank" aria-label="Corite on YouTube">
            <mat-icon class="social-icon">youtube2</mat-icon>
          </a>
          <a href="https://twitter.com/coritecom/" target="_blank" aria-label="Corite on Twitter">
            <mat-icon class="social-icon">twitter2</mat-icon>
          </a>
          <a href="https://discord.gg/corite" target="_blank" aria-label="Corite on Discord">
            <mat-icon class="social-icon">discord2</mat-icon>
          </a>
          <a href="https://t.me/corite/" target="_blank" aria-label="Corite on Telegram">
            <mat-icon class="social-icon">telegram2</mat-icon>
          </a>
          <a href="https://www.tiktok.com/@corite" target="_blank" aria-label="Corite on Tiktok">
            <mat-icon class="social-icon">tiktok2</mat-icon>
          </a>
        </div>
    </div>
    </div>
  </div>
</div>

<h2 class="app-text-center" matDialogTitle>Give your next release a boost!</h2>
<mat-dialog-content>
  <div class="mat-typography app-text-center">
    <img class="image" src="/assets/images/on-boarding/ob-shout.svg" alt="" aria-hidden="true" />

    <p i18n>
      Create a fan campaign to let your fans be part of the success by backing you.
    </p>
  </div>
  <app-spacer></app-spacer>
  <div class="app-buttons">
    <a i18n
       routerLink="/releases/new/campaign"
       mat-flat-button color="primary"
       mat-dialog-close="campaign"
       data-e2e="create-campaign-release-btn">Fan campaign & Release</a>
  </div>
  <div class="app-buttons">
    <a i18n
       routerLink="/releases/new"
       mat-stroked-button color="primary"
       mat-dialog-close="release"
       data-e2e="create-release-only-btn">Release only</a>
  </div>
  <div class="app-buttons">
    <button type="button" i18n
            mat-button mat-dialog-close
            data-e2e="create-campaign-cancel-btn">I changed my mind
    </button>
  </div>
</mat-dialog-content>

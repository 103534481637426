import {Breakpoints, LayoutModule} from '@angular/cdk/layout';
import {CommonModule} from '@angular/common';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {APP_BREAKPOINTS, AppBreakpoints} from './breakpoints.service';

@NgModule({
  imports: [
    CommonModule,
    LayoutModule,
  ],
})
export class ResponsiveModule {
  static forRoot(breakpoints: AppBreakpoints = Breakpoints): ModuleWithProviders<ResponsiveModule> {
    return {
      ngModule: ResponsiveModule,
      providers: [
        {provide: APP_BREAKPOINTS, useValue: breakpoints},
      ],
    };
  }
}

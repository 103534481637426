import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {isPlainObject} from '@util/misc';
import {TimeoutError} from 'rxjs';
import {Logger} from './logger.service';

export type KnownErrorInfo = {
  isKnown: true;
  routerCommands?: any[];
} | {isKnown: false};

export type KnownErrorHandler = (err: unknown) => KnownErrorInfo | false;

export class KnownError extends Error {
  constructor(public readonly originalError: Error) {
    super(originalError.message);
    this.name = originalError.name;
    this.stack = originalError.stack;

    Object.setPrototypeOf(this, KnownError.prototype);
  }
}

export function isChunkLoadError(err: unknown): err is Error & {type: string, request: string} {
  if (isPlainObject(err)) {
    return err.name === 'ChunkLoadError' || `${err.message}`.startsWith('Loading chunk');
  }

  return false;
}

@Injectable({providedIn: 'root'})
export class KnownErrorsService {
  readonly handlers: KnownErrorHandler[] = [
    err => { // MatIcon errors.
      if (this.isMatIconError(err)) {
        this.logger.oopsWarn(err, 'KnownError: MatIcon loading failed.');

        return {isKnown: true};
      }

      return false;
    },

    err => {
      if (typeof navigator !== 'undefined') {
        switch (true) {
          case navigator.userAgent.includes('Opera/'): // Opera
          case navigator.userAgent.includes('Opera Mini/'): // Opera Mini
          case navigator.userAgent.includes('Trident/'): // IE
          case navigator.userAgent.includes('Googlebot/'): // Googlebot
            this.logger.oopsWarn(err, 'KnownError: Unsupported browser');

            return {isKnown: true, routerCommands: ['/500']};
        }
      }

      return false;
    },

    err => {
      if (this.isExtensionError(err)) {
        return {isKnown: true};
      }

      return false;
    },

    err => {
      if (err instanceof Error || this.isCryptoError(err)) {
        switch (true) {
          case err.message.startsWith('Please unlock Finnie wallet'):
          case err.message.startsWith('No RPC Url available for chainId'):
          case err.message.startsWith('PollingBlockTracker - '):
            this.logger.oopsWarn(err, 'KnownError: WalletConnect non-critical error.');

            return {isKnown: true};
        }
      }

      if (this.isCryptoError(err)) {
        switch (true) {
          case err.code === -32603:
            this.logger.oopsWarn(err, 'KnownError: Crypto TX failed to prepare.');

            return {isKnown: true};
        }
      }

      return false;
    },

    err => { // Analytics errors.
      if (err instanceof Error) {
        if (err.stack.includes('analytics.tiktok.com')) {
          this.logger.oopsWarn(err, 'KnownError: TikTok Pixel has thrown something.');

          return {isKnown: true};
        }

        if (err.stack.includes('www.google-analytics.com')) {
          this.logger.oopsWarn(err, 'KnownError: Google Analytics has thrown something.');

          return {isKnown: true};
        }

        if (err.stack.includes('www.googletagmanager.com')) {
          this.logger.oopsWarn(err, 'KnownError: Google Tag Manager has thrown something.');

          return {isKnown: true};
        }

        if (err.stack.includes('fbevents.js')) {
          this.logger.oopsWarn(err, 'KnownError: Facebook pixel has thrown something.');

          return {isKnown: true};
        }
      }

      return false;
    },

    err => { // Apply Pay & Google Pay errors.
      if (this.isPaymentRequestButtonError(err)) {
        this.logger.oopsWarn(err, 'KnownError: Apple/Google Pay error');

        return {isKnown: true};
      }

      return false;
    },

    err => { // ChunkLoadError exceptions.
      if (isChunkLoadError(err)) {
        this.logger.oopsWarn(err, 'KnownError: Failed to load a piece of code.');

        return {isKnown: true, routerCommands: ['/500']};
      }

      return false;
    },

    err => { // HttpErrorResponse exceptions.
      if (err instanceof HttpErrorResponse && err.status === 0) {
        this.logger.oopsWarn(err, 'KnownError: Unknown HTTP error response');

        return {isKnown: true, routerCommands: ['/500']};
      }

      return false;
    },

    err => {
      if (err === Error) {
        this.logger.oopsWarn(err, 'KnownError: Incorrectly thrown error');

        return {isKnown: true, routerCommands: ['/500']};
      }

      return false;
    },

    err => { // FeatureFlagsLoader exceptions.
      if (err instanceof Error && err.message === 'Unable to fetch VersionInfo') {
        this.logger.oopsWarn(err, 'KnownError: FeatureFlagsLoader failed to get VersionInfo');

        return {isKnown: true, routerCommands: ['/500']};
      }

      return false;
    },

    err => { // TimeoutError exceptions.
      if (err instanceof TimeoutError) {
        this.logger.oopsWarn(err, 'KnownError: Timeout Error');

        return {isKnown: true, routerCommands: ['/500']};
      }

      return false;
    },

    err => { // Known NG exceptions exception.
      if (err instanceof Error && err.message.startsWith('NG04002')) {
        this.logger.oopsWarn(err, `KnownError: ${err.message}`);

        return {isKnown: true, routerCommands: ['/404']};
      }

      return false;
    },

    err => { // Known Corite exception.
      if (err instanceof Error && err.message === 'Missing or invalid `op` parameter') {
        this.logger.oopsWarn(err, `KnownError: ${err.message}`);

        return {isKnown: true};
      }

      return false;
    },

    err => { // Known errors thrown specifically in other code
      if (err instanceof KnownError) {
        this.logger.oopsWarn(err, `KnownError: unhandled ${err.name} thrown`);

        return {isKnown: true, routerCommands: ['/500']};
      }

      return false;
    },
  ];


  constructor(private logger: Logger) {}

  private isMatIconError(err: unknown) {
    if (err instanceof Error) {
      if (err.message.includes('Loading icon set URL')) {
        return true;
      }

      if (err.message.includes('Error retrieving icon')) {
        return true;
      }
    }

    return false;
  }

  isPaymentRequestButtonError(err: unknown): err is {error: string, details: string, message?: string} {
    if (isPlainObject(err) && typeof err.message === 'string') {
      switch (true) {
        case err.message.includes('paymentRequestButton'):
        case err.message === 'Page already has an active payment session.':
        case err.message === 'This Element has already been destroyed. Please create a new one.':
          return true;
      }
    }

    return false;
  }

  isCryptoError(err: unknown): err is {code: number, message: string, data?: {message: string}} {
    return isPlainObject(err) && ('code' in err && 'message' in err);
  }

  isExtensionError(err: unknown): err is Error {
    if (err instanceof Error) {
      const first = err.stack?.split('\n')[1];

      return first?.includes('extension://') || false;
    }

    return false;
  }

  private isRejectedPromise(err: unknown): err is {rejection: unknown} {
    return isPlainObject(err) && 'rejection' in err;
  }

  handleIfKnown(error: unknown): KnownErrorInfo {
    if (this.isRejectedPromise(error)) {
      error = error.rejection;
    }

    for (const handler of this.handlers) {
      const info = handler(error);

      if (info && info.isKnown) {
        return info;
      }
    }

    return {isKnown: false};
  }
}

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {CountryGuide} from './country.guide';
import {SupportGuide} from './support.guide';

@NgModule({
  imports: [CommonModule],
  declarations: [SupportGuide, CountryGuide],
  exports: [SupportGuide, CountryGuide],
})
export class AppGuidesModule {}

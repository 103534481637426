export class DateConvertor {
  static parse(source: string | number): Date {
    if (typeof source === 'number') {
      source = Math.round(source / 86400) * 86400_000;
    }

    const utcDate = new Date(source);
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    date.setFullYear(
      utcDate.getUTCFullYear(),
      utcDate.getUTCMonth(),
      utcDate.getUTCDate(),
    );

    return date;
  }

  static toDate(date: Date, utc = true): Date {
    const dateClone = new Date(date.getTime());

    if (utc) {
      dateClone.setUTCHours(0, 0, 0, 0);
      dateClone.setUTCFullYear(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
      );
    } else {
      dateClone.setHours(0, 0, 0, 0);
    }

    return dateClone;
  }

  static toDateStamp(date: Date, utc = true): number {
    return Math.round(this.toDate(date, utc).getTime() / 1000);
  }

  static toDateISOString(date: Date): string {
    return this.toDate(date).toISOString().substring(0, 'yyyy-mm-dd'.length);
  }
}

import {Component} from '@angular/core';
import {AuthService} from '@app/core/services/auth.service';

@Component({
  selector: 'guide-country',
  template: `
    <ng-container *ngIf="isGuest; else whenRegistered" i18n="Country restriction guide (for Guests) [VERIFIED]">
      Unfortunately Corite is not available in your country yet.
      You're more than welcome to register and check out the music,
      but you won't be able to back or create a Corite fan campaign.
      If you join, we will let you know as soon as we're up and running
      in your country.
    </ng-container>
    <ng-template #whenRegistered>
      <ng-container i18n="Country restriction guide (for Registered users) [VERIFIED]">
        Unfortunately Corite is not available in your country yet.
        You won't be able to back or create a Corite fan campaign.
        We will let you know as soon as we're up and running in your country.
      </ng-container>
    </ng-template>
  `,
})

export class CountryGuide {
  constructor(private auth: AuthService) { }

  get isGuest(): boolean {
    return !this.auth.isRegistered();
  }
}

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {AppGuidesModule} from '@app/guides/guides.module';
import {DialogModule} from '@app/services/dialog/dialog.module';
import {RestrictAuthDialogComponent} from './components/restrict-auth-dialog/restrict-auth-dialog.component';
import {RestrictCountryDialogComponent} from './components/restrict-country-dialog/restrict-country-dialog.component';
import {ForArtistsLinkDirective} from './directives/for-artists-link.directive';
import {ForArtistsDirective} from './directives/for-artists.directive';
import {RestrictCountryGuard} from './guards/restrict-country.guard';
import {RestrictPermissionGuard} from './guards/restrict-permission.guard';

@NgModule({
  declarations: [
    RestrictCountryDialogComponent,
    RestrictAuthDialogComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    DialogModule,
    AppGuidesModule,
    ForArtistsDirective,
    ForArtistsLinkDirective,
  ],
  providers: [
    RestrictCountryGuard,
    RestrictPermissionGuard,
  ],
  exports: [
    RestrictCountryDialogComponent,
    RestrictAuthDialogComponent,
    ForArtistsDirective,
    ForArtistsLinkDirective,
  ],
})
export class AppRestrictionsModule {}

import {Inject, Injectable, Optional} from '@angular/core';
import {ActivatedRouteSnapshot, Route, Router, UrlTree} from '@angular/router';
import {RESPONSE} from '@nguniversal/express-engine/tokens';
import {Response} from 'express';
import {firstValueFrom, from, Observable, of} from 'rxjs';
import {catchError, first, map, switchMap} from 'rxjs/operators';
import {AuthNextService} from '../services/auth-next.service';
import {AuthService} from '../services/auth.service';

@Injectable({providedIn: 'root'})
export class AuthGuard {
  constructor(private auth: AuthService,
              private authNext: AuthNextService,
              private router: Router,
              @Optional() @Inject(RESPONSE) private res?: Response,
  ) {}

  canActivate(next: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this.checkAuth(next);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this.canActivate(childRoute);
  }

  canMatch(route: Route): Observable<boolean> {
    return this.checkAuth(route, false).pipe(
      map(result => typeof result === 'boolean' ? result : false),
    );
  }

  canLoad(route: Route): Observable<boolean> {
    return this.checkAuth(route)
      .pipe(
        switchMap(res => {
          if (res instanceof UrlTree) {
            return from(this.router.navigateByUrl(res.toString()))
              .pipe(map(() => false));
          }

          return of(res);
        }),

        // Let children routes to load in case of an error to preserve
        // requested URL in address bar. Page protection will be covered
        // by children guards as well.
        catchError(() => of(true)),
      );
  }

  protected checkAuth(next: ActivatedRouteSnapshot | Route, statusCodes = true): Observable<boolean | UrlTree> {
    return this.auth.userData$.pipe(
      first(),
      switchMap(() => this.handleProtectedPage(next, statusCodes)),
    );
  }

  protected async handleProtectedPage(next: ActivatedRouteSnapshot | Route, statusCodes: boolean): Promise<boolean | UrlTree> {
    await firstValueFrom(this.auth.userData$);

    const {require = [], exclude = []} = next.data?.completeness ?? {require: ['standard']};

    const isUser = this.auth.isAuthenticated();
    const isGuest = !isUser;

    const passedRequired = this.auth.hasCompleteness(require);
    const passedExcluded = this.auth.hasCompleteness([], exclude);
    const isGranted = passedRequired && passedExcluded;

    // Guest: Redirect to /user/login if not allowed
    if (isGuest && !isGranted) {
      this.authNext.setNext();

      if (this.res && statusCodes) {
        this.res.status(403);
      }

      return this.router.parseUrl('/user/login');
    }

    // User: Don't render protected routes with SSR, redirect to "Please wait..."
    if (isUser && isGranted && this.res) {
      return this.router.parseUrl('/_wait');
    }

    // User: Redirect to /explore if there are too many permissions
    // (e.g. Login page for authenticated user)
    if (isUser && !passedExcluded) {
      if (this.res) {
        return this.router.parseUrl('/_wait');
      }

      return this.router.parseUrl('/explore');
    }

    if (isUser && !passedRequired) {
      return this.router.parseUrl(this.auth.isRegistered() ? 'profile' : '/user/register');
    }

    // "Access granted" scenarios
    return isGranted;
  }
}

import {AfterViewInit, Component, ElementRef, HostListener, OnDestroy} from '@angular/core';
import {CookieConsentService} from '../../services/cookie-consent.service';
import {SafeAreaService} from '../../services/safe-area.service';

@Component({
  selector: 'app-cookie-consent',
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.scss'],
})
export class CookieConsentComponent implements AfterViewInit, OnDestroy {
  constructor(private service: CookieConsentService,
              private safeArea: SafeAreaService<'cookie'>,
              private el: ElementRef,
  ) {}

  @HostListener('window:resize')
  @HostListener('window:orientationchange')
  ngAfterViewInit() {
    const elCookieConsent = this.el.nativeElement;
    const offsetRight = window.innerWidth - elCookieConsent.getBoundingClientRect().right;

    if (offsetRight < 140) {
      this.safeArea.setSafeBottom('cookie', elCookieConsent.clientHeight);
    } else {
      this.safeArea.resetSafeArea('cookie');
    }
  }

  ngOnDestroy() {
    this.safeArea.resetSafeArea('cookie');
  }

  dismiss() {
    this.service.agree();
  }
}

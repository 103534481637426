import {Component, ContentChild, ContentChildren, QueryList} from '@angular/core';
import {LayoutContentDirective, LayoutMainTitleDirective, LayoutSidebarLeftDirective} from '../../sections/layout-regions.directive';
import {TitleActionDirective} from '../../sections/title.directive';

@Component({
  selector: 'app-layout-simple',
  templateUrl: './layout-simple.component.html',
  styleUrls: ['./layout-simple.component.scss'],
})
export class LayoutSimpleComponent {
  @ContentChild(LayoutMainTitleDirective)
  layoutGridTitle: LayoutMainTitleDirective;

  @ContentChildren(TitleActionDirective)
  layoutGridActions: QueryList<TitleActionDirective>;

  @ContentChild(LayoutContentDirective)
  layoutGridContent: LayoutContentDirective;

  @ContentChild(LayoutSidebarLeftDirective)
  layoutGridSidebarLeft: LayoutSidebarLeftDirective;
}

import {firstValueFrom, Observable, of, throwError} from 'rxjs';
import {switchMap} from 'rxjs/operators';

export class RestrictedAccessError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = 'RestrictedAccessError';

    Object.setPrototypeOf(this, RestrictedAccessError.prototype);
  }
}

export abstract class RestrictServiceBase {
  abstract approved(): Observable<boolean>;

  whenApproved(): Promise<void> {
    return firstValueFrom(this.approved().pipe(
      switchMap(approved => approved
        ? of(null)
        : throwError(() => new RestrictedAccessError('not allowed'))),
    ));
  }
}

import {Injectable} from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {AuthService} from '@app/core/services/auth.service';
import {Observable} from 'rxjs';
import {first, map, tap} from 'rxjs/operators';
import {RestrictServiceBase} from '../abstracts/restrict-service';
import {RestrictCountryDialogComponent} from '../components/restrict-country-dialog/restrict-country-dialog.component';

@Injectable({providedIn: 'root'})
export class RestrictCountryService extends RestrictServiceBase {

  constructor(private dialog: MatDialog, private auth: AuthService) {
    super();
  }

  approved(): Observable<boolean> {
    return this.auth.userData$.pipe(
      first(),
      map(() => this.auth.isSupportedCountry()),
      tap(approved => {
        if (!approved) {
          this.dialog.open(RestrictCountryDialogComponent, {
            width: '375px',
            autoFocus: false,
          });
        }
      }),
    );
  }
}

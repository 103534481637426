import {environment} from '@env/environment';
import {MappedObject} from './types';

export function isPlainObject(x: unknown): x is {[prod: string]: unknown} {
  return typeof x === 'object' && x !== null;
}

function getIndex(subject: string, value: string) {
  return subject.trim().toLowerCase().indexOf(value.toLowerCase());
}

export function filterByRelevance<T extends {title: string}>(value: string, list: T[]): T[] {
  return list
    .filter(option => getIndex(option.title, value) >= 0)
    .sort((a, b) => {
      let diff = 0;

      // Matches on the beginning takes precedence.
      const [aIndex, bIndex] = [getIndex(a.title, value), getIndex(b.title, value)];
      if (aIndex === 0 || bIndex === 0) {
        diff = aIndex - bIndex;
      }

      // Sort alphabetically after all.
      if (diff === 0) {
        if (a.title < b.title) {
          diff = -1;
        }
        if (a.title > b.title) {
          diff = 1;
        }
      }

      return diff;
    });
}

export function mapObject<T, R>(o: T, mapper: (value: T[keyof T]) => R): MappedObject<T, R> {
  const keys = Object.keys(o) as Array<keyof T>;
  const mapped: Partial<MappedObject<T, R>> = {};

  for (const key of keys) {
    mapped[key] = mapper(o[key]);
  }

  return mapped as Required<typeof mapped>;
}

export function cutObject<T, K extends keyof T>(o: Readonly<T>, exclude: K | [K, ...K[]]): Omit<T, K> {
  const clone = {...o};

  if (Array.isArray(exclude)) {
    exclude.forEach(key => delete clone[key]);
  } else {
    delete clone[exclude];
  }

  return clone;
}


export function sortNumericArray(arr: readonly number[], clone: true): number[];
export function sortNumericArray(arr: number[], clone?: false): number[];
export function sortNumericArray(arr: number[] | readonly number[], clone?: boolean): number[] {
  let arrSort: number[];

  if (clone) {
    arrSort = arr.filter(() => true);
  } else {
    // Assert type until TS fix https://github.com/microsoft/TypeScript/issues/22609.
    arrSort = arr as number[];
  }

  return arrSort.sort((a, b) => {
    if (a > b) {
      return 1;
    } else if (a < b) {
      return -1;
    } else {
      return 0;
    }
  });
}

export function fixNumberPrecision(number: number, fractionDigits?: number): number {
  if (number === null || number === undefined) {
    return number;
  }

  if (typeof number !== 'number') {
    return NaN;
  }

  const n14 = parseFloat(number.toPrecision(14));

  if (fractionDigits !== undefined) {
    const pow = Math.pow(10, fractionDigits);

    return Math.round(fixNumberPrecision(n14 * pow)) / pow;
  } else {
    const n13 = parseFloat(number.toPrecision(13));
    const n12 = parseFloat(number.toPrecision(12));

    if (n13 === n12) {
      return n13;
    }
  }

  return n14;
}

export function logDebugMessage(context: string, message?: any, ...optionalParams: any[]) {
  if (environment.debug || typeof process !== 'undefined') {
    console.debug(`[CO][${context}] ${message}`, ...optionalParams);
  }
}

export function logDebugMessageInBrowser(context: string, message?: any, ...optionalParams: any[]) {
  if (typeof window !== 'undefined') {
    logDebugMessage(context, message, ...optionalParams);
  }
}

export function pick<O extends object, K extends keyof O>(o: O, keys: K[]): Pick<O, K> {
  const picked: Partial<O> = {};

  for (const key of keys) {
    if (key in o) {
      picked[key] = o[key];
    }
  }

  return picked as Pick<O, K>;
}

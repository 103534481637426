export interface CampaignAssetsData {
  igShare?: string[];
}

export class CampaignAssets implements CampaignAssetsData {
  igShare: string[] | null = null;

  static fromData(data: CampaignAssetsData): CampaignAssets {
    return Object.assign(new CampaignAssets(), data);
  }
}

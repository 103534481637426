import {isPlatformBrowser} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {logDebugMessageInBrowser} from '@util/misc';
import {defer, EMPTY, iif, Observable, ReplaySubject, throwError} from 'rxjs';
import {catchError, filter, finalize, first, map, switchMap, take, takeWhile, tap} from 'rxjs/operators';
import {AppErrorHandler} from './app-error-handler';
import {PageEventsService} from './page-events.service';

export interface PagedListData {
  page: number;
  pages: number;
}

type PagedListAcceptTransformer<D extends PagedListData, T> = (response: API.Response<D>) => T[] | Promise<T[]>;

export class PagedList<T, D extends PagedListData = PagedListData> {
  public enableInfiniteScroll = false;

  private acceptTransformer: PagedListAcceptTransformer<D, T>;
  private params: {[param: string]: string | string[]} = {};

  private pageLoaded = -1;
  private pageCount = 1;

  private items: T[] = null;
  private itemsSource = new ReplaySubject<T[]>(1);
  private items$ = this.itemsSource.asObservable();

  private isWorking = false;

  constructor(private url: string,
              private http: HttpClient,
              private events: PageEventsService,
              private errHandler: AppErrorHandler) {}

  get isEmpty(): boolean {
    return Array.isArray(this.items) && !this.items.length;
  }

  get isLoading(): boolean {
    return this.isWorking;
  }

  get isLoadedFirst(): boolean {
    return this.pageLoaded >= 0;
  }

  get isLoadedAll(): boolean {
    return this.pageLoaded >= this.pageCount - 1;
  }

  get isActive(): boolean {
    return this.itemsSource.observed;
  }

  setParams(params: PagedList<T>['params']): this {
    this.blockWhenWorking('setParams');

    this.params = params;

    return this;
  }

  setAcceptTransformer(fn: PagedListAcceptTransformer<D, T>): this {
    this.blockWhenWorking('setAcceptTransformer');

    this.acceptTransformer = fn;

    return this;
  }

  resolve(): Observable<T[]> {
    if (this.pageLoaded < 0 && !this.isWorking) {
      return this.next().pipe(
        tap(() => {
          if (!this.enableInfiniteScroll) {
            return;
          }

          this.events.observeCloseToBottom()
            .pipe(
              filter(() => !this.isWorking),

              // Complete when all pages are loaded.
              takeWhile(() => !this.isLoadedAll),
              // Complete when this PagedList got unsubscribed.
              takeWhile(() => this.isActive),

              // Ignore page load errors and give it a chance next time.
              // It's primarily useful for slow/lagging network connection.
              switchMap(() => this.next().pipe(catchError(() => EMPTY))),
            )
            .subscribe({
              error: err => this.errHandler.logErrorAsError(err, 'PageList: Failed to fetch next page', {url: this.url}),
            });
        }),
      ).pipe(switchMap(() => this.items$));
    }

    return this.items$;
  }

  /**
   * Resolve only first page and only if needed. Subsequent calls will
   * fall back to most recent items$ value
   */
  resolveOnce(): Observable<T[]> {
    return iif(
      () => this.pageLoaded < 0 && !this.isWorking,
      defer(() => this.next()),
      this.items$,
    ).pipe(first());
  }

  resolvePrefetch(): Observable<this> {
    return this.resolve().pipe(
      take(1),
      map(() => this),
    );
  }

  next(): Observable<T[]> {
    try {
      this.blockWhenWorking('next');
    } catch (e) {
      return throwError(() => e);
    }

    if (this.isLoadedAll) {
      return throwError(() => new Error(`Page ${this.pageLoaded + 1} is out of scope.`));
    }

    if (!this.acceptTransformer) {
      return throwError(() => new TypeError('acceptTransformer is empty or invalid. You have to call .setAcceptTransformer().'));
    }

    this.isWorking = true;
    this.params.page = (this.pageLoaded + 1).toString();

    return this.http.get<API.Response<D>>(this.url, {params: this.params})
      .pipe(
        switchMap(async response => {
          this.pageLoaded = response.data.page;
          this.pageCount = response.data.pages;

          logDebugMessageInBrowser('PagedList', `Loaded page ${this.pageLoaded} of ${this.pageCount} (${this.url}).`, {response});

          const newItems = await this.acceptTransformer(response);

          // Notify only if something changes. And notify first change anyway
          // so that resolvers can resolve empty items.
          if (newItems.length || !this.items) {
            this.items = this.items || [];
            this.items.push(...newItems);
            this.itemsSource.next(this.items);
          }

          return newItems;
        }),
        finalize(() => this.isWorking = false),
        switchMap(() => this.items$),
        first(),
      );
  }

  protected blockWhenWorking(method: string) {
    if (this.isWorking) {
      throw new Error(`Unable to call ${method} while PagedList is working.`);
    }
  }
}


@Injectable()
export class PagedListFactory<T> {
  constructor(private http: HttpClient,
              private events: PageEventsService,
              private errHandler: AppErrorHandler,
              @Inject(PLATFORM_ID) private pId: object,
  ) {}

  public createPagedList<D extends PagedListData>(url: string): PagedList<T, D> {
    const list = new PagedList<T, D>(url, this.http, this.events, this.errHandler);
    list.enableInfiniteScroll = isPlatformBrowser(this.pId);

    return list;
  }

}

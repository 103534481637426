import {DateConvertor} from '@util/date';
import {Genre} from './genre';
import {ReleaseContributor} from './release/contributor';
import {ReleaseExplicitLevel} from './release/explicit';
import {ReleaseStatus} from './release/status';
import {ModerationStatus} from './status';

export interface ReleaseApiData {
  id: number;
  songName: string | null;
  songFile: string | null;
  songCover: string | null;
  songLanguage: string | null;
  genre: Genre;
  metadataLanguage: string | null;
  label: string | null;
  ISRC: string | null;
  upc: string | null;
  recYear: number | string;
  explicit: ReleaseExplicitLevel | null;
  artists: ReleaseContributor[];
  contributors: ReleaseContributor[];
  releaseDate: string | number | null;
  moderationStatus: ModerationStatus;
  moderationComment: string | null;
}

export interface ReleaseApiPayload extends Partial<ReleaseApiData> {
  id: number | null; // Campaign ID
  publish?: boolean;
  distributionOnly?: boolean;
  messageToModerator?: string;
}

export interface ReleasePayload {
  songName?: string | null;
  songFile?: string | null;
  songCover?: string | null;
  songLanguage?: string | null;
  genre?: Genre;
  metadataLanguage?: string | null;
  label?: string | null;
  ISRC?: string | null;
  upc?: string | null;
  recYear?: number | string | null;
  explicit?: ReleaseExplicitLevel | null;
  artists?: ReleaseContributor[];
  contributors?: ReleaseContributor[];
  releaseDate?: Date | null;
  messageToModerator?: string;

  id?: number;
  publish?: boolean;
  distributionOnly?: boolean;
}

export class Release {
  songName: string | null = null;
  songFile: string | null = null;
  songCover: string | null = null;
  songLanguage: string | null = null;
  genre = new Genre();
  metadataLanguage: string | null = null;
  label: string | null = null;
  ISRC: string | null = null;
  upc: string | null = null;
  recYear: number | null = null;
  explicit: ReleaseExplicitLevel | null = null;
  artists: ReleaseContributor[] = [];
  contributors: ReleaseContributor[] = [];
  releaseDate: Date | null = null;
  messageToModerator: string | null = null;

  constructor(public readonly id?: number,
              public readonly status: ReleaseStatus = ReleaseStatus.Draft,
              public readonly moderationStatus: ModerationStatus = ModerationStatus.None,
              public readonly moderationComment?: string,
  ) {}

  public static fromData(data: ReleaseApiData) {
    const instance: Release = Object.assign(new Release(), data);

    if (data.releaseDate) {
      instance.releaseDate = DateConvertor.parse(data.releaseDate);
    }

    if (data.recYear) {
      instance.recYear = Number(data.recYear);
    }

    if (!instance.artists) {
      instance.artists = [];
    }

    if (!instance.contributors) {
      instance.contributors = [];
    }

    return instance;
  }

  public toFormModel() {
    return {
      songName: this.songName,
      songFile: this.songFile,
      songCover: this.songCover,
      songLanguage: this.songLanguage,
      genre: this.genre,
      metadataLanguage: this.metadataLanguage,
      label: this.label,
      ISRC: this.ISRC,
      upc: this.upc,
      recYear: this.recYear,
      explicit: this.explicit,
      releaseDate: this.releaseDate,

      // processed lazily in ContributorsComponent.
      artists: [] as const,
      contributors: [] as const,
    };
  }
}

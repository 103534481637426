import {Injectable} from '@angular/core';
import {Data, Route, UrlSegment} from '@angular/router';
import {RestrictCountryService} from '../services/restrict-country.service';

export interface RestrictCountryCanLoadData extends Data {
  restrictCountryOnlyFor: string[][];
}

@Injectable()
export class RestrictCountryGuard {
  constructor(private restrictCountry: RestrictCountryService) {}

  canActivate() {
    return this.restrictCountry.approved();
  }

  canActivateChild() {
    return this.restrictCountry.approved();
  }

  canLoad(route: Route, segments: UrlSegment[]) {
    let restrict = false;
    const data = route.data as RestrictCountryCanLoadData;

    if (data && data.restrictCountryOnlyFor) {
      const rules = data.restrictCountryOnlyFor;

      restrict = rules.some(rule => {
        return rule.every((value, index) => {
          return segments[index] && segments[index].path === value;
        });
      });
    }

    return restrict ? this.restrictCountry.approved() : true;
  }
}

import {DOCUMENT, isPlatformBrowser} from '@angular/common';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Inject, Injectable, Optional, PLATFORM_ID} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from '@env/environment';
import {SHA, VERSION} from '@env/version';
import {REQUEST} from '@nguniversal/express-engine/tokens';
import {CoriteEnv} from '@util/env';
import {logDebugMessage} from '@util/misc';
import {getClientInfo} from '@util/ssr';
import {Request} from 'express';
import {firstValueFrom, Observable} from 'rxjs';
import {map, retry, tap} from 'rxjs/operators';
import {AuthService} from './auth.service';

export enum LoggerSeverity {
  Error,
  Debug,
  Warning,
}

export interface LoggerPayload {
  error: string;
  etype: LoggerSeverity;
  ip?: string;
  data: Record<string, unknown>;
}

export type LoggerResponse = {status: 'ok'} | {status: 'error', error: string};

export interface ErrorWithNG extends Error {
  ngType: () => unknown;
  ngDebugContext: unknown;
  ngOriginalError: Error;
  ngErrorLogger: (console: Console, ...values: any[]) => void;
}

type LoggerError = Error & {stackLong?: string, [ext: string]: any};
type PromiseRejectionError = Error & {rejection: Error};

@Injectable({providedIn: 'root'})
export class Logger {
  protected cachedErrors: string[] = [];
  private readonly landing: [string, string];

  constructor(private http: HttpClient,
              private auth: AuthService,
              private router: Router,
              @Inject(PLATFORM_ID) protected pId: object,
              @Inject(DOCUMENT) protected doc: Document,
              @Inject(REQUEST) @Optional() protected req?: Request,
  ) {
    Error.stackTraceLimit = 100; // 100 should be enough

    if (isPlatformBrowser(this.pId)) {
      this.landing = [location.href, new Date().toString()];
    }
  }

  oopsError(message: unknown, ...optionalParams: any[]): void {
    this.logError(message, ...optionalParams).catch(err => console.warn(err));
  }

  async logError(message: unknown, ...optionalParams: any[]): Promise<boolean> {
    const err = this.parseErrorObject(message, optionalParams);

    // First, log to console.
    console.error(err, optionalParams);

    return await this.logErrorObject(err, LoggerSeverity.Error, optionalParams);
  }

  oopsWarn(message: unknown, ...optionalParams: any[]): void {
    this.logWarn(message, ...optionalParams).catch(err => console.warn(err));
  }

  async logWarn(message: unknown, ...optionalParams: any[]): Promise<boolean> {
    const err = this.parseErrorObject(message, optionalParams);
    err.name = 'Warning';

    // First, log to console.
    console.warn(err, optionalParams);

    return await this.logErrorObject(err, LoggerSeverity.Warning, optionalParams);
  }

  oopsDebug(message: string, ...optionalParams: any[]): void {
    this.logDebug(message, ...optionalParams).catch(err => console.warn(err));
  }

  async logDebug(message: string, ...optionalParams: any[]): Promise<boolean> {
    const error = new Error(message);
    error.toString = () => Error.prototype.toString.call(error).replace('Error: ', 'Debug: ');

    return await this.logErrorObject(error, LoggerSeverity.Debug, optionalParams);
  }

  protected async logErrorObject(err: Error, severity: LoggerSeverity, optionalParams: any[]): Promise<boolean> {
    // Try to encode error and send to the applog.php.
    try {
      const errParts = this.prepareError(err, optionalParams);

      if (errParts) {
        return await this.log2db(severity, ...errParts);
      }
    } catch (e) {
      return await this.log2db(LoggerSeverity.Error, 'Unexpected error during logging other error...', [err.name, err.message, e]);
    }

    return false;
  }

  private prepareError(err: LoggerError, optionalParams?: any[]): [string, any] | null {
    const message = err.toString();

    const data = {
      error: {
        name: err.name,
        message: err.message,
        ...err,
        context: optionalParams,
        stack: err.stack,
        stackLong: [err.stackLong],
      },
      version: [VERSION, SHA],
      platform: this.pId,
      location: this.parseLocation(),
      client: this.req ? getClientInfo(this.req) : undefined,
      docTitle: this.doc.title,
      navigation: this.parseCurrentNavigation(),
      userData: this.auth.getUserData(),
      cookie: this.req ? this.req.headers.cookie : this.doc.cookie,
    };

    if (isPlatformBrowser(this.pId)) {
      Object.assign(data, {
        location,
        landing: this.landing,
        navigator: this.parseNavigator(),
      });
    }

    const dataCacheTmp: any[] = [];
    const dataFiltered = JSON.stringify(data, (key, value) => {
      if (typeof value === 'function') {
        return '[[Function]]';
      }

      if (Array.isArray(value) && value.length && value.every(e => typeof e === 'function')) {
        return '[[Array of functions]]';
      }

      if (value instanceof Observable) {
        return `[[${value.constructor.name}]]`;
      }

      // Remove circular references;
      if (typeof value === 'object' && value !== null) {
        if (dataCacheTmp.indexOf(value) !== -1) {
          try {
            return JSON.parse(JSON.stringify(value));
          } catch (e) {
            return '[[Circular reference]]';
          }
        }

        dataCacheTmp.push(value);
      }

      return value;
    });

    const hash = [message, dataFiltered].join();
    if (this.cachedErrors.includes(hash)) {
      return null;
    } else {
      this.cachedErrors.push(hash);
      this.cachedErrors = this.cachedErrors.slice(-3);
    }

    return [message, JSON.parse(dataFiltered)];
  }

  private parseErrorObject(msg: unknown, optionalParams: any[]): LoggerError {
    let err: Error;

    function isObject(v: unknown): v is {[k: string]: any} {
      return typeof msg === 'object' && msg !== null;
    }

    function isPromiseRejection(v: Error): v is PromiseRejectionError {
      return (v as PromiseRejectionError).rejection instanceof Error;
    }

    function isErrorWithNG(error: Error): error is ErrorWithNG {
      return 'ngDebugContext' in error;
    }

    if (msg instanceof Error) {
      err = msg;

      if (isPromiseRejection(msg)) {
        err = msg.rejection;
      }

    } else if (isObject(msg)) {
      const message = msg.message || 'Unknown error';

      err = new Error(message);
      Object.assign(err, msg);
    } else if (typeof msg === 'function') {
      err = new Error('Incorrectly thrown error');
    } else {
      err = new Error(msg as any);
    }

    const originalErr = this.prepareOriginalError(msg);
    if (originalErr !== msg) {
      optionalParams.push(originalErr);
    }

    if (err.stack) {
      Object.defineProperty(err, 'stackLong', {
        writable: true,
        enumerable: false,
      });

      const stackLong = err.stack
        .split('\n')
        .map(l => l.match(/vendor|polyfill/) ? l : l.replace('  at ', '* at '));

      const loggerPoint = stackLong.filter(l => l.includes(`* at ${Logger.name}.`)).pop();
      stackLong.splice(1, stackLong.lastIndexOf(loggerPoint));

      err.stack = stackLong
        .filter(l => /(vendor|polyfill)/.test(l) === false)
        .join('\n');

      err = Object.assign(err, {stackLong: stackLong.join('\n')});
    }

    Object.assign(err, {instanceType: msg.constructor.name});

    // Remove Angular debugging context to prevent recursion hell.
    if (isErrorWithNG(err)) {
      delete err.ngType;
      delete err.ngDebugContext;
      delete err.ngOriginalError;
      delete err.ngErrorLogger;
    }

    return err;
  }

  private prepareOriginalError(err: unknown) {
    if (err instanceof HttpErrorResponse) {
      const errPrepared: LoggerError = Object.assign(new Error(), err);

      if (err.error && err.error.constructor.name === 'ProgressEvent') {
        errPrepared.error = this.parseProgressEvent(err.error as ProgressEvent);
      }

      return errPrepared;
    }

    if (err instanceof Event) {
      return {
        type: err.type,
      };
    }

    if (typeof err === 'function') {
      return {meta: {name: err.name, prototype: err.prototype}};
    }

    return err;
  }

  private parseProgressEvent(err: ProgressEvent) {
    return {
      lengthComputable: err.lengthComputable,
      loaded: err.loaded,
      total: err.total,
    };
  }

  private parseCurrentNavigation() {
    const curr = this.router.getCurrentNavigation();

    if (curr) {
      return {
        id: curr.id,
        initialUrl: curr.initialUrl && curr.initialUrl.toString(),
        extractedUrl: curr.extractedUrl && curr.extractedUrl.toString(),
        finalUrl: curr.finalUrl && curr.finalUrl.toString(),
        extras: curr.extras,
      };
    }

    return undefined;
  }

  private parseNavigator() {
    const connection: any = (() => {
      const n = navigator as any;
      const c = n.connection || n.mozConnection || n.webkitConnection || {};

      return {
        downlink: c.downlink,
        downlinkMax: c.downlinkMax,
        effectiveType: c.effectiveType,
        rtt: c.rtt,
        saveData: c.saveData,
        type: c.type,
      };
    })();

    return {
      ua: navigator.userAgent,
      languages: navigator.languages,
      onLine: navigator.onLine,
      connection,
    };
  }

  protected parseLocation(): Partial<Location> {
    if (this.req) {
      return {
        host: this.req.get('Host'),
        hostname: getClientInfo(this.req).hostname,
        pathname: this.req.path,
        protocol: this.req.protocol,
        search: this.req.originalUrl.replace(this.req.path, ''),
      };
    }

    return this.doc.location;
  }

  protected async log2db(severity: LoggerSeverity, message: string, data: any): Promise<boolean> {
    if (environment.logging) {
      if (typeof this.pId === 'string') {
        message = `[${this.pId}] [${this.parseLocation().hostname}] ${message}`;
      }

      if (CoriteEnv.app().isValid()) {
        message = `[app] ${message}`;
      }

      const payload: LoggerPayload = {
        error: message,
        etype: severity,
        data,
      };

      if (this.req) {
        payload.ip = getClientInfo(this.req).ip;
      }

      const log$ = this.http.post<LoggerResponse>(`${environment.adminUrl}/applog.php`, payload)
        .pipe(tap(response => {
          if (response.status === 'error') {
            throw new Error(response.error);
          }
        }))
        .pipe(retry(1), map(() => true));

      return firstValueFrom(log$).catch(err => {
        console.error('Failed to log error to DB.', new Error(err).message, {err, error: message, data});

        return false;
      });
    } else {
      logDebugMessage('Logger', `${LoggerSeverity[severity]} did not logged to the Database`, {error: message, data});

      return false;
    }
  }
}

import {Injectable} from '@angular/core';
import type Moralis from 'moralis-v1';

@Injectable({providedIn: 'root'})
export class MoralisResolver {
  private sdk?: Moralis;
  private enablingWeb3Promise?: Promise<Moralis.MoralisWeb3Provider>;

  async resolve(): Promise<Moralis> {
    if (!this.sdk) {
      // tricky workarounds for WalletConnect
      if (typeof window !== 'undefined') {
        (window as any).global = window;
        (window as any).process = {env: {}};
        (window as any).Buffer = (await import('buffer')).Buffer;
      }

      this.sdk = await import('moralis-v1').then(m => m.default);
    }

    return this.sdk;
  }

  async resolveWeb3(options: Moralis.EnableOptions): Promise<Moralis.MoralisWeb3Provider> {
    const sdk = await this.resolve();

    if (sdk.isWeb3Enabled()) {
      return sdk.web3;
    }

    this.enablingWeb3Promise ??= sdk.enableWeb3(options);

    try {
      return await this.enablingWeb3Promise;
    } finally {
      delete this.enablingWeb3Promise;
    }
  }
}

import {PortalModule} from '@angular/cdk/portal';
import {NgModule} from '@angular/core';
import {MatRippleModule} from '@angular/material/core';
import {DialogModule} from '@app/services/dialog/dialog.module';
import {AppLoaderModule} from '@app/widgets/loader/loader.module';
import {AppSpacerModule} from '@app/widgets/spacer/spacer.module';
import {AppUserPhotoModule} from '@app/widgets/user-photo/user-photo.module';
import {AppCommonModule} from '../../common/common.module';
import {AppRestrictionsModule} from '../restrictions/restrictions.module';
import {CreateDialogComponent} from './components/create-dialog/create-dialog.component';
import {FooterComponent} from './components/footer/footer.component';
import {HeaderComponent} from './components/header/header.component';

@NgModule({
  imports: [
    AppCommonModule,
    PortalModule,
    MatRippleModule,
    DialogModule,
    AppUserPhotoModule,
    AppRestrictionsModule,
    AppSpacerModule,
    AppLoaderModule,
  ],
  declarations: [
    HeaderComponent,
    FooterComponent,
    CreateDialogComponent,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
  ],
})
export class AppNavigationModule {}

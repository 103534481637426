import {Injectable} from '@angular/core';
import {PRIMARY_OUTLET, Router, UrlSegment} from '@angular/router';
import {LocationHelper} from '@app/core/services/location-helper.service';
import {environment} from '@env/environment';

export type ImgPreviewStrategy = 'thumb' | 'scale' | 'fb';

export interface ImgPreviewOptions {
  strategy: ImgPreviewStrategy;
  width?: number;
  height?: number;
  maxWidth?: number;
  maxHeight?: number;
  quality?: number;
  fmt?: 'webp' | 'jpeg';
}

@Injectable({
  providedIn: 'root',
})
export class ImgPreviewUrlBuilder {

  constructor(private router: Router, private locHelper: LocationHelper) { }

  build(url: string, opts: ImgPreviewOptions = {strategy: 'scale'}) {
    url = this.normalizeUrl(url);

    if (opts.strategy === 'fb') {
      return `${environment.staticUrl}/preview/fb${url}`;
    }

    // No dimensions - no processing: pass through.
    if (!opts.width && !opts.height && !opts.maxWidth && !opts.maxHeight) {
      return environment.staticUrl + url;
    }

    const urlTree = this.router.parseUrl(url);

    // Prepend URL with /preview/
    urlTree.root.children[PRIMARY_OUTLET].segments
      .unshift(new UrlSegment('preview', {}));

    // Setup strategy and related options.
    urlTree.queryParams.crop = opts.strategy;
    urlTree.queryParams.q = opts.quality;
    urlTree.queryParams.fmt = opts.fmt ?? 'webp';

    switch (urlTree.queryParams.crop) {
      case 'thumb':
        urlTree.queryParams.w = opts.width;
        urlTree.queryParams.h = opts.height;
        urlTree.queryParams.max_w = opts.maxWidth;
        urlTree.queryParams.max_h = opts.maxHeight;
        break;
      case 'scale':
        urlTree.queryParams.max_w = opts.maxWidth || opts.width;
        urlTree.queryParams.max_h = opts.maxHeight || opts.height;
        break;
    }

    // Remove empty parameters.
    Object.keys(urlTree.queryParams).forEach((key) => {
      if (!urlTree.queryParams[key]) {
        delete urlTree.queryParams[key];
      }
    });

    return environment.staticUrl + urlTree.toString();
  }

  protected normalizeUrl(url: string): string {
    if (!url) {
      throw new Error(`Error generating preview of image (empty URL provided)`);
    }

    if (url.startsWith(environment.staticUrl)) {
      url = url.slice(environment.staticUrl.length);

      // Fail both external or already generated preview URLs.
    } else if (this.locHelper.isAbsolute(url) || url.startsWith('/preview')) {
      throw new Error(`Error generating preview of image (invalid URL provided: ${url})`);
    }

    return this.locHelper.absolutePath(url);
  }

}

import {ElementRef} from '@angular/core';
import {CoriteEnv} from './env';
import {LinkTarget} from './types';

export class CoriteWVApp {
  static urlAppendLinkTarget(url: string, target: LinkTarget): string {
    if (CoriteEnv.app().isValid() && url.indexOf('_app_link_target') < 0) {
      return `${url};_app_link_target=${target}`;
    }

    return url;
  }

  static elementAppendLinkTarget(el: ElementRef<HTMLAnchorElement>): void {
    const {href, target} = el.nativeElement;

    // Allow custom target names in runtime.
    const hrefModified = this.urlAppendLinkTarget(href, target as LinkTarget);

    // Don't touch DOM if this is unnecessary for the sake of performance.
    if (href !== hrefModified) {
      el.nativeElement.href = hrefModified;
    }
  }
}

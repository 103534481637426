import {OnBoardingGuide} from '@app/services/on-boarding/services/on-boarding-guide.service';
import {FiatCurrency, MoneyAmount} from '@app/widgets/money/shared';
import {defaultPermissions, UserPermissions} from './permissions';
import {UserExtra} from './user-extra';

export type UserCompleteness = 'basic' | 'standard' | 'fulfilled' | 'verified';
export type UserSocialProvider = 'facebook' | 'google' | 'apple';

export interface UserSocialProfile {
  title: string;
  type: string;
  urlPrefix: string;
  url: string;
}

export interface UserSocialProfilePayload {
  type: string;
  url: string;
}

export interface UserBrief {
  id: number;
  name: string;
  userImage?: string;
}

export interface UserCryptoWallet {
  ethAddress: string;
}

export interface UserPartialData {
  firstName: string;
  lastName: string;
  provider: UserSocialProvider | 'email';
  completeness?: UserCompleteness[];
  emptypwd: boolean;
  userImage?: string;
  country?: string;
  currency?: FiatCurrency;
  email?: string | null;

  id?: number;
  token?: string;
  extra?: UserExtra;
}

export interface UserFullData extends UserPartialData {
  id: number;
  country: string;
  currency: FiatCurrency;
  completeness: UserCompleteness[];
  admin: boolean;
  allow: UserPermissions;
  savedCard: string;
  email: string | null;
  newEmail?: string;
  balance: MoneyAmount<FiatCurrency>;
  phone: string;
  website: string;
  address: string;
  city: string;
  postNumber: string;
  socials: UserSocialProfile[];
  dateCreated: number;
  notifications?: {
    email: Record<string, boolean>,
    [extra: string]: Record<string, boolean>,
  };
  onBoarding?: {
    role: string,
    state: {
      [section in OnBoardingGuide]?: boolean;
    },
  };
  crypto?: UserCryptoWallet;
}

export interface UserRegisterContext {
  captcha?: string;
  // This is userId currently, but it can be any string in the future, really.
  referral?: string;
}

export type UserPasswordPayload = {
  password: string;
  currentPassword: string;
} | {
  password: string;
  token: string;
};

export interface UserPayload extends Partial<UserPartialData> {
  newEmail?: string;
  phone?: string;
  website?: string;
  socials?: UserSocialProfilePayload[];
}

export interface UserPayloadRegister extends UserRegisterContext {
  country: string;
  currency: FiatCurrency;
  email: string;
  firstName: string;
  lastName: string;
  password?: string;
  phone?: string;
  termsAccepted?: 1,
}


export class UserData implements UserPartialData {
  firstName: string = null;
  lastName: string = null;
  provider: User['provider'] = null;
  emptypwd: boolean = null;
  userImage?: string;
  completeness?: UserCompleteness[];
  country?: string;
  currency?: FiatCurrency;

  id?: number;
  admin?: boolean;
  allow?: UserPermissions;
  token?: string;
  extra?: UserExtra;

  constructor(public email?: string) {}

  static fromResponseData(data: UserPartialData) {
    const instance = new UserData();

    // Skip props validation for Guest.
    if (data.id !== 0) {
      if (!('completeness' in data)) {
        data.completeness = ['basic', 'standard'];
      }

      if (data.completeness.includes('standard') && !data.email) {
        throw new Error(`Bad UserData: missing "email" property`);
      }

      Object.keys(instance).forEach(prop => {
        if (!(prop in data)) {
          throw new Error(`Bad UserData: missing "${prop}" property`);
        }
      });
    }

    Object.assign(instance, data);

    if (instance.provider === 'email' && instance.emptypwd) {
      throw new Error('Bad UserData: Provider `email` cannot be with empty password.');
    }

    // if (instance.isFullData()) {
    //   instance.balance = [3.47, 'USD'];
    //   console.log(`DEBUG: Simulate user balance = ${instance.balance.join('')}`, instance);
    // }

    // if (instance.isFullData()) {
    //   instance.crypto = {ethAddress: '0x0002'};
    //   console.log('DEBUG: Simulate No Wallet linked', instance);
    // }

    return instance;
  }

  isFullData(): this is UserFullData {
    return !!(this.id && 'completeness' in this);
  }
}

export enum VeriffSessionStatus {
  NotStarted = 'not_start',
  Created = 'created',
  Started = 'started',
  Abandoned = 'abandoned',
  Expired = 'expired',
  Submitted = 'submitted',
  Approved = 'approved',
  ResubmissionRequested = 'resubmission_requested',
  Declined = 'declined',
  Review = 'review',
}

export class VeriffSession {
  status?: VeriffSessionStatus;
  name?: string;
  reason: string;
}

export class User implements UserFullData {
  id: number = null;
  savedCard: string = null;
  firstName: string = null;
  lastName: string = null;
  email: string = null;
  newEmail?: string;
  userImage?: string;
  token?: string;
  completeness: UserCompleteness[] = [];
  admin: boolean = null;
  allow = defaultPermissions;
  provider: UserSocialProvider | 'email' = null;
  emptypwd: boolean = null;
  balance: MoneyAmount<FiatCurrency> = [null, undefined];
  phone: string = null;
  country: string = null;
  currency: FiatCurrency = null;
  website: string = null;
  socials: UserSocialProfile[] = [];
  address: string;
  city: string;
  postNumber: string;
  dateCreated: number = null;
  notifications?: UserFullData['notifications'];
  onBoarding?: UserFullData['onBoarding'];
  crypto?: UserCryptoWallet;
  veriffSession?: VeriffSession;
}

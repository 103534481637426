import {CommonModule, registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule, HttpClientXsrfModule} from '@angular/common/http';
import locale_enSE from '@angular/common/locales/en-SE';
import {ErrorHandler, Injector, LOCALE_ID, NgModule, Optional, SkipSelf} from '@angular/core';
import {MatNativeDateModule} from '@angular/material/core';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {RouterModule} from '@angular/router';
import {AppLayoutModule} from '@app/lib/layout/layout.module';
import {FeatureFlagsLoader} from '@app/services/version/services/ff-loader';
import {AppLoaderModule} from '@app/widgets/loader/loader.module';

// To best leverage Stripe’s advanced fraud functionality, ensure that Stripe.js
// is loaded on every page, not just your checkout page.
// import '@stripe/stripe-js';
import {ResponsiveModule} from '../common/modules/responsive/responsive.module';
import {ChatModule} from '../services/chat/chat.module';
import {AppVersionModule} from '../services/version/version.module';
import {CookieConsentComponent} from './components/cookie/cookie-consent.component';
import {Err403Page} from './pages/err403/err403.page';
import {Err404Page} from './pages/err404/err404.page';
import {Err500Page} from './pages/err500/err500.page';
import {Err503Page} from './pages/err503/err503.page';
import {WaitPage} from './pages/wait/wait.page';
import {ApiInterceptor} from './services/api-interceptor.service';
import {AppErrorHandler} from './services/app-error-handler';
import {appBreakpoints} from './services/app-state.service';
import {IconsService} from './services/icons.service';
import {PagedListFactory} from './services/paged-list-factory.service';

// Temporary hard-coded number format: 100[NBSP]000.00
if (Array.isArray(locale_enSE[13]) && locale_enSE[13].length === 12) {
  locale_enSE[13][0] = '.';
  locale_enSE[13][1] = ' ';
}

registerLocaleData(locale_enSE);

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatButtonModule,
    AppLayoutModule,
    AppVersionModule,
    HttpClientModule,
    HttpClientXsrfModule,
    MatNativeDateModule,
    ResponsiveModule.forRoot(appBreakpoints),
    ChatModule,
    AppLoaderModule,
  ],
  declarations: [
    CookieConsentComponent,
    Err403Page,
    Err404Page,
    Err500Page,
    Err503Page,
    WaitPage,
  ],
  exports: [
    CookieConsentComponent,
  ],
  providers: [
    IconsService,
    PagedListFactory,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: 'en-SE',
    },
    {
      provide: ErrorHandler,
      useExisting: AppErrorHandler,
    },
  ],
})
export class CoreModule {
  constructor(
    injector: Injector,
    @Optional() @SkipSelf() parentModule?: CoreModule,
  ) {
    if (parentModule) {
      throw new Error('CoreModule has already been loaded. Import Core modules in the AppModule only.');
    }

    // Bootstrap early any critical stateful services that meant to be used
    // throughout the whole app.
    injector.get(FeatureFlagsLoader);
  }
}

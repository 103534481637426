import {Component, Input} from '@angular/core';
import {LinkTarget} from '@util/types';
import {site} from '../../../app.info';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
})
export class LogoComponent {
  @Input() href = '/';
  @Input() target: LinkTarget = '_self';
  site = site;
}

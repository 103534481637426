import {Location} from '@angular/common';
import {Injectable} from '@angular/core';
import {NavigationEnd, NavigationExtras, Router} from '@angular/router';
import {UserRegisterContext} from '../models/user';
import {AuthService} from './auth.service';
import {CookieService} from './cookie.service';

export class AuthNextAction {
  public readonly path?: string;
  private readonly _reload?: boolean;

  constructor(private router: Router) {
    const record = CookieService.isStorageAvailable
      ? localStorage.getItem('auth.next')
      : undefined;

    if (record) {
      this._reload = record.startsWith('reload:/');
      this.path = record
        .replace('reload:/', '/')
        .replace(/\/+/g, '/');
    }
  }

  go(fallbackUrl = '/explore', extras?: NavigationExtras): Promise<boolean> {
    const url = this.path ?? fallbackUrl;

    if (this._reload) {
      location.assign(this.path);

      return new Promise<never>(undefined);
    } else {
      return this.router.navigateByUrl(url, extras);
    }
  }
}

@Injectable({providedIn: 'root'})
export class AuthNextService {

  constructor(private auth: AuthService,
              private location: Location,
              private router: Router,
  ) { }

  initialize() {
    this.cleanupNextUrl();
  }

  setNext(path?: string): void {
    if (CookieService.isStorageAvailable) {
      if (!path) {
        const currentNavigation = this.router.getCurrentNavigation();
        if (currentNavigation && currentNavigation.extractedUrl) {
          path = currentNavigation.extractedUrl.toString();
        } else {
          path = this.location.path();
        }
      }

      localStorage.setItem('auth.next', this.location.prepareExternalUrl(path));
    }
  }

  getNext(): AuthNextAction {
    return new AuthNextAction(this.router);
  }

  setNextAndSignIn(path?: string): Promise<boolean> {
    this.setNext(path);

    return this.router.navigate(['/user', 'login']);
  }

  setNextAndSignUp(path?: string): Promise<boolean> {
    this.setNext(path);

    return this.router.navigate(['/user', 'sign-up']);
  }

  setRegistrationContext(context: UserRegisterContext) {
    if (CookieService.isStorageAvailable) {
      localStorage.setItem('auth.context', JSON.stringify(context));
    }
  }

  getRegistrationContext(): UserRegisterContext | null {
    if (CookieService.isStorageAvailable) {
      const context = localStorage.getItem('auth.context');

      if (context) {
        return JSON.parse(context);
      }
    }

    return null;
  }

  removeRegistrationContext() {
    if (CookieService.isStorageAvailable) {
      localStorage.removeItem('auth.context');
    }
  }

  navigateToSavedUrl(fallbackUrl = '/explore', extras?: NavigationExtras): Promise<boolean> {
    return this.getNext().go(fallbackUrl, extras);
  }

  private cleanupNextUrl(): void {
    if (!CookieService.isStorageAvailable) {
      return;
    }

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd && !event.url.startsWith('/user/')) {
        localStorage.removeItem('auth.next');
      }
    });
  }

}

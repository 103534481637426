import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SpacerComponent} from './spacer/spacer.component';

@NgModule({
  imports: [CommonModule],
  declarations: [SpacerComponent],
  exports: [SpacerComponent],
})
export class AppSpacerModule {}

import {isPlatformBrowser, ViewportScroller} from '@angular/common';
import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {ExtraOptions, Router, ROUTER_CONFIGURATION, Scroll} from '@angular/router';
import {animationFrameScheduler} from 'rxjs';
import {filter, observeOn, skip} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class AppRouterScroller {
  constructor(private router: Router,
              private viewportScroller: ViewportScroller,
              @Inject(PLATFORM_ID) private pId: object,
              @Inject(ROUTER_CONFIGURATION) private options: ExtraOptions,
  ) {}

  initialize() {
    if (!isPlatformBrowser(this.pId)) {
      return;
    }

    if (this.options.scrollPositionRestoration !== 'disabled') {
      throw new Error(`Can‘t initialize AppRouterScroller as scrollPositionRestoration is '${this.options.scrollPositionRestoration}'`);
    }

    // Copy-paste from RouterScroller but on animationFrameScheduler so that
    // actual scrolling will be performed after change detector and upon repaint.
    this.viewportScroller.setHistoryScrollRestoration('manual');
    this.router.events
      .pipe(
        filter((e): e is Scroll => e instanceof Scroll),
        // skip initial navigation to prevent SSR initial scrolling
        skip(1),
        observeOn(animationFrameScheduler),
      )
      .subscribe(e => {
        // a popstate event. The pop state event will always ignore anchor scrolling.
        if (e.position) {
          this.viewportScroller.scrollToPosition(e.position);

          // imperative navigation "forward"
        } else {

          // delegate anchor scrolling to the RouterScroller.
          if (!(e.anchor && this.options.anchorScrolling === 'enabled')) {
            this.viewportScroller.scrollToPosition([0, 0]);
          }
        }
      });
  }
}

import {NgModule} from '@angular/core';
import {RouterModule, Routes, UrlMatcher} from '@angular/router';
import {AuthGuard} from '@app/core/guards/auth.guard';
import {ExperimentalGuard, ExperimentalGuardData} from '@app/core/guards/experimental.guard';
import {ShortUrlGuard} from '@app/core/guards/short-url.guard';
import {Err403Page} from '@app/core/pages/err403/err403.page';
import {Err404Page} from '@app/core/pages/err404/err404.page';
import {Err500Page} from '@app/core/pages/err500/err500.page';
import {Err503Page} from '@app/core/pages/err503/err503.page';
import {WaitPage} from '@app/core/pages/wait/wait.page';
import {AppStateService} from '@app/core/services/app-state.service';
import {AppRouterScroller} from '@app/core/services/router-scroller';
import {ReleasesGuard} from '@app/lib/navigation/guards/releases.guard';
import {VersionGuard} from '@app/services/version/guards/version.guard';

export function rootPathMatcher(paths: string[]): UrlMatcher {
  return (segments) => {
    const path = segments.map(s => s.path).join('/');

    if (path && paths.some(p => path.startsWith(p))) {
      return {consumed: []};
    }

    return null;
  };
}

export const urlMatcherShortURL: UrlMatcher = (segments) => {
  // As there is no possibility to inject PLATFORM_ID use NodeJS `process`
  // to workaround SSR detection.
  if (typeof process !== 'undefined' && segments.length === 1) {
    const slug = segments[0];

    if ('explore' !== slug.path && slug.path.match(/\w+/)) {
      return {consumed: segments, posParams: {slug}};
    }
  }

  return null;
};

const appRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () => import('./features/landings/landings.module').then(m => m.LandingsModule),
  },
  {
    path: 'releases',
    loadChildren: () => import('./features/releases/releases.module').then(m => m.ReleasesModule),
    canLoad: [ReleasesGuard, AuthGuard],
    canActivateChild: [ReleasesGuard, AuthGuard],
  },
  {
    path: 'activity',
    loadChildren: () => import('./features/activity/activity.module').then(m => m.ActivityModule),
    canLoad: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'profile',
    loadChildren: () => import('./features/profile/profile.module').then(m => m.ProfileModule),
    canLoad: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'notifications',
    loadChildren: () => import('./features/notifications/notifications.module').then(m => m.NotificationsModule),
    canLoad: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'staking',
    loadChildren: () => import('./features/stake/stake.module').then(m => m.StakeModule),
    canActivate: [ExperimentalGuard],
    data: {
      flag: 'blockchain.stake',
    } satisfies ExperimentalGuardData<'blockchain.stake'>,
  },
  {
    path: '1001',
    canActivate: [ExperimentalGuard],
    data: {
      flag: 'campaigns.1001',
    } satisfies ExperimentalGuardData<'campaigns.1001'>,
    loadChildren: () => import('./features/top101/top101.module').then(m => m.Top101Module),
  },
  {
    path: 'user/sign-up',
    loadChildren: () => import('./features/sign-up/sign-up.module').then(m => m.SignUpModule),
  },
  {
    path: 'user',
    loadChildren: () => import('./features/account/account.module').then(m => m.AccountModule),
  },
  {
    path: 'news',
    loadChildren: () => import('./features/news/news.module').then(m => m.NewsModule),
  },
  {
    path: 'mission',
    loadChildren: () => import('./lib/mission-pages/mission-pages.module').then(m => m.MissionPagesModule),
  },
  {
    path: 'browse',
    loadChildren: () => import('./features/browse/browse.module').then(m => m.BrowseModule),
  },
  {
    path: 'dev',
    loadChildren: () => import('./features/dev/dev.module').then(m => m.DevModule),
  },
  {path: 'payouts', redirectTo: 'wallets/usd'},
  {path: 'wallet', redirectTo: 'wallets/usd'},
  {path: 'marketplace', redirectTo: 'market'},
  {
    matcher: rootPathMatcher(['wallets', 'market']),
    loadChildren: () => import('./features/wallet/wallet.module').then(m => m.WalletModule),
  },
  {
    matcher: rootPathMatcher(['drops', 'emotions', 'origins', 'co-origin/claim', 'original-artists/claim']),
    loadChildren: () => import('./features/drops/drops.module').then(m => m.DropsModule),
  },
  {path: 'artist-originals', redirectTo: '/original-artists'},
  {
    matcher: rootPathMatcher([
      'terms-of-use', 'previous-terms-of-use', 'privacy-policy', 'start',
      'beta-test', 'm1n7', 'wow-redeem',
      'co-origin', 'original-artists', 'origins/details',
    ]),
    loadChildren: () => import('./features/landings/landings.module').then(m => m.LandingsModule),
  },
  {
    matcher: urlMatcherShortURL,
    canActivate: [ShortUrlGuard],
    component: WaitPage,
  },
  {
    path: '',
    loadChildren: () => import('./features/explore/explore.module').then(m => m.ExploreModule),
  },
  {
    path: '**',
    component: Err404Page,
  },
];

appRoutes.forEach(route => {
  route.canLoad = route.canLoad || [];
  route.canLoad.push(VersionGuard);
});

const allRoutes: Routes = [
  {
    path: '503',
    component: Err503Page,
  },
  {
    path: '500',
    component: Err500Page,
  },
  {
    path: '404',
    component: Err404Page,
  },
  {
    path: '403',
    component: Err403Page,
  },
  {
    path: '_wait',
    component: WaitPage,
  },
  {
    path: '',
    canActivateChild: [VersionGuard],
    children: appRoutes,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(allRoutes, {
      initialNavigation: 'enabledBlocking',
      onSameUrlNavigation: 'reload',
      paramsInheritanceStrategy: 'always',
      // Overridden by AppRouterScroller
      scrollPositionRestoration: 'disabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor(state: AppStateService, scroller: AppRouterScroller) {
    scroller.initialize();
    state.handleInitialNavigation(['/explore'])
      .subscribe({
        error: err => console.warn(err),
      });
  }
}

import {isPlatformServer, Location} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {inject, Injectable, PLATFORM_ID} from '@angular/core';
import {ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment, UrlSegmentGroup} from '@angular/router';
import {AppErrorHandler} from '@app/core/services/app-error-handler';
import {Logger} from '@app/core/services/logger.service';
import {VersionLoader} from '../services/version-loader';

@Injectable({providedIn: 'root'})
export class VersionGuard {
  private pId = inject(PLATFORM_ID);

  initializedChecksum?: string;

  constructor(private router: Router,
              private http: HttpClient,
              private versionLoader: VersionLoader,
              private location: Location,
              private logger: Logger,
              private errHandler: AppErrorHandler,
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.checkVersionUpdate(next, state);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.checkVersionUpdate(childRoute, state);
  }

  canLoad(route: Route, segments: UrlSegment[]) {
    return this.checkVersionUpdate(route, segments);
  }

  private async checkVersionUpdate(route: Pick<Route, 'data'>, state: RouterStateSnapshot | UrlSegment[]): Promise<boolean> {
    if (route.data && route.data.skipVersionCheck || isPlatformServer(this.pId)) {
      return true;
    }

    const requestedUrl = this.getRequestedUrl(state);

    // Skip invest pages to prevent bad UX (reload to campaign page)
    // TODO for Den: Investigate more generic approach.
    if (requestedUrl.includes('/invest')) {
      return true;
    }

    // Fetch version info.
    const version = await this.versionLoader.loadVersionInfo();

    if (!version) {
      // Failed version requests should NOT break the site.
      // User will browse possibly outdated version but will eventually
      // refresh once version info is fetched successfully.
      return true;
    }

    // Save initial checksum so it can be compared with future version requests.
    if (!this.initializedChecksum) {
      this.initializedChecksum = version.checksum;
    }

    // Skip initial navigation to prevent redirect loop fundamentally.
    const currentNavigation = this.router.getCurrentNavigation();
    if (!(currentNavigation && currentNavigation.previousNavigation)) {
      return true;
    }

    // Compare and reload app if mismatch.
    if (version.checksum !== this.initializedChecksum) {
      this.logger.oopsDebug('VersionGuard: checksum changed', {
        fetched: version.checksum,
        initialized: this.initializedChecksum,
      });

      this.navigateWithReload(requestedUrl);
    }

    return true;
  }

  private getRequestedUrl(state: RouterStateSnapshot | UrlSegment[]): string {
    let url: string;

    if (state instanceof RouterStateSnapshot) {
      url = state.url;
    } else {
      const group = new UrlSegmentGroup(state, {});
      url = this.location.prepareExternalUrl(group.toString());
    }

    if (!url) {
      const error = new Error(`RouterStateSnapshot.url is empty (${url})`);
      this.logger.oopsError(error, 'VersionGuard: unable to detect correct URL when reloading.', {state});
    }

    return url || '/';
  }

  private navigateWithReload(url: string): void {
    this.location.replaceState(url);
    this.router.navigate(['/_wait'], {skipLocationChange: true})
      .then(() => this.logger.logDebug('VersionGuard: reload attempt', {url}))
      .catch(err => this.errHandler.logErrorAsError(err, 'VersionGuard: failed to show /_wait page.'))
      .finally(() => {
        // Redirect anyway as this is definitely better than nothing.
        location.assign(url);
      });
  }
}

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatLegacyProgressBarModule as MatProgressBarModule} from '@angular/material/legacy-progress-bar';
import {RouterModule} from '@angular/router';
import {LinkTargetDirective} from '@app/shared/directives/link-target.directive';
import {DividerComponent} from './components/divider/divider.component';
import {DspComponent} from './components/dsp/dsp.component';
import {LogoComponent} from './components/logo/logo.component';
import {MessageComponent, MessageContentDirective, MessageTitleDirective} from './components/message/message.component';
import {ResponsiveModule} from './modules/responsive/responsive.module';

@NgModule({
  declarations: [
    DividerComponent,
    DspComponent,
    LogoComponent,
    MessageComponent,
    MessageTitleDirective,
    MessageContentDirective,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatProgressBarModule,
    ResponsiveModule,
    LinkTargetDirective,
  ],
  exports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatProgressBarModule,
    ResponsiveModule,

    // Components
    DividerComponent,
    DspComponent,
    LogoComponent,
    MessageComponent,

    // Directives
    LinkTargetDirective,
    MessageTitleDirective,
    MessageContentDirective,
  ],
})
export class AppCommonModule {}

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ImgComponent} from './img/img.component';


@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [ImgComponent],
  exports: [ImgComponent],
})
export class AppImgModule { }

import {Location} from '@angular/common';
import {Inject, Injectable, Optional} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {REQUEST, RESPONSE} from '@nguniversal/express-engine/tokens';
import {Request, Response} from 'express';
import {RedirectSlugApiData, SlugRedirectType, SlugService, SlugType} from '../services/slug.service';

@Injectable({providedIn: 'root'})
export class ShortUrlGuard {
  constructor(private slug: SlugService,
              private router: Router,
              private location: Location,
              @Optional() @Inject(REQUEST) private req?: Request,
              @Optional() @Inject(RESPONSE) private res?: Response,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    const slug = route.paramMap.get('slug');
    const slugData = await this.slug.fetchSlugData(slug);

    switch (slugData.resType) {
      case SlugType.Redirect:
        return await this.handleRedirect(slugData);

      case SlugType.Campaign:
      case SlugType.CampaignList:
        return this.router.createUrlTree(['/explore', route.url.toString()]);

      default:
        this.router.navigate(['/404'], {skipLocationChange: true})
          .catch(console.warn);

        Promise.resolve().then(() => {
          this.location.replaceState(state.url);
        });

        return false;
    }
  }

  private async handleRedirect(slugData: RedirectSlugApiData): Promise<false> {
    if (!this.res || !this.req) {
      throw new Error('Unable to handle redirect without RESPONSE object.');
    }

    if (slugData.redirect.type === SlugRedirectType.Invite) {
      const target = encodeURIComponent(slugData.redirect.url);
      const queryStringStartIndex = this.req.originalUrl.indexOf('?');

      let url = `/user/sign-up?target=${target}`;
      if (queryStringStartIndex >= 0) {
        url += '&' + this.req.originalUrl.substring(queryStringStartIndex + 1);
      }

      this.res.redirect(302, url);
    } else {
      this.res.redirect(301, slugData.redirect.url);
    }

    return new Promise(resolve => {
      this.res.end(() => setTimeout(() => resolve(false), 100));
    });
  }
}

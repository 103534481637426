import {isPlatformBrowser} from '@angular/common';
import {ChangeDetectorRef, Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {Router} from '@angular/router';
import {isPageWithOptions, PageWithOptions} from '@app/core/abstracts/page';
import {AppStateService} from '@app/core/services/app-state.service';
import {PageMetaService} from '@app/core/services/page-meta.service';
import {PlayerService} from '@app/lib/campaign-player/services/player.service';
import {environment} from '@env/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  pageOptions: Required<PageWithOptions>;
  playerPortal$ = this.player.portal$;

  get isBusy(): boolean {
    return this.state.isNavigating;
  }

  get isCookieConsentRequired(): boolean {
    return this.state.isCookieConsentRequired();
  }

  constructor(private state: AppStateService,
              private pageMeta: PageMetaService,
              private router: Router,
              private player: PlayerService,
              private cd: ChangeDetectorRef,
              @Inject(PLATFORM_ID) private pId: object,
  ) {}

  ngOnInit(): void {
    this.pageMeta.initialize();
    this.resetPageOptions();

    if (environment.debug) {
      this.initDebugTricks();
    }
  }

  onActivate(page: unknown) {
    if (isPageWithOptions(page)) {
      this.resetPageOptions(page);
    } else {
      this.resetPageOptions();
    }
  }

  private initDebugTricks() {
    if (!isPlatformBrowser(this.pId)) {
      return;
    }

    try {
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = `${environment.adminUrl}/robots.txt`;
      document.body.appendChild(iframe);
    } catch (e) {
      console.warn(new Error(`Unable to create debug iFrame: ${e.message}`), e);
    }
  }

  private resetPageOptions(page: PageWithOptions = {}) {
    this.pageOptions = {
      withHeader: typeof page.withHeader === 'boolean' ? page.withHeader : true,
      withFooter: typeof page.withFooter === 'boolean' ? page.withFooter : true,
      withPlayer: typeof page.withPlayer === 'boolean' ? page.withPlayer : true,
      withCookieConsent: typeof page.withCookieConsent === 'boolean' ? page.withCookieConsent : true,
    };
  }
}

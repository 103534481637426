import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {LoaderComponent} from './components/loader/loader.component';
import {OnlyBrowserComponent} from './components/only-browser/only-browser.component';
import {WaitComponent} from './components/wait/wait.component';


@NgModule({
  declarations: [LoaderComponent, OnlyBrowserComponent, WaitComponent],
  imports: [CommonModule],
  exports: [LoaderComponent, OnlyBrowserComponent, WaitComponent],
})
export class AppLoaderModule {}

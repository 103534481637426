import {isPlatformBrowser} from '@angular/common';
import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MatLegacyDialogState as MatDialogState,
} from '@angular/material/legacy-dialog';
import {Router} from '@angular/router';
import {CreateDialogComponent} from '../components/create-dialog/create-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class ReleasesGuard {
  private dialogRef?: MatDialogRef<CreateDialogComponent>;

  constructor(private matDialog: MatDialog,
              private router: Router,
              @Inject(PLATFORM_ID) private pId: object,
  ) {}

  canActivateChild(): boolean {
    return this.handle();
  }

  canLoad(): boolean {
    return this.handle();
  }

  private handle(): boolean {
    const navigation = this.router.getCurrentNavigation();
    const path = navigation?.extractedUrl.toString();

    if (!isPlatformBrowser(this.pId)) {
      // SSR: Pass through and delegate to other guards.
      return true;
    }

    if (path.match(/\?.*dialog=create/)) {
      if (!this.dialogRef || this.dialogRef.getState() === MatDialogState.CLOSED) {
        this.dialogRef = this.matDialog.open(CreateDialogComponent, {
          width: '375px',
          autoFocus: false,
        });
      }

      // For the initial navigation we can allow loading.
      // For subsequent navigations ONLY popup should be opened.
      return !navigation.previousNavigation;
    }

    return true;
  }
}

import {isPlatformBrowser} from '@angular/common';
import {inject, Injectable, OnDestroy, PLATFORM_ID} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {User} from '@app/core/models/user';
import {AppErrorHandler} from '@app/core/services/app-error-handler';
import {AuthService} from '@app/core/services/auth.service';
import {AppModuleLoader} from '@app/core/services/module-loader';
import {asyncScheduler, EMPTY, Observable, Subscription} from 'rxjs';
import {catchError, delayWhen, filter, first, map, observeOn, switchMap} from 'rxjs/operators';
import {OnBoardingDialogRef} from '../dialog/on-boarding-dialog.component';
import {ON_BOARDING_GUIDES} from '../tokens';
import {OnBoardingGuide} from './on-boarding-guide.service';

@Injectable({providedIn: 'root'})
export class OnBoardingService implements OnDestroy {
  private pId = inject(PLATFORM_ID);
  private subscriptions = new Subscription();

  constructor(private auth: AuthService,
              private router: Router,
              private errHandler: AppErrorHandler,
              private loader: AppModuleLoader,
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  initialize(): void {
    if (isPlatformBrowser(this.pId)) {
      this.subscriptions.add(this.initSignUpGuide());
    }
  }

  requestGuide(guide: OnBoardingGuide): Observable<OnBoardingDialogRef> {
    const loadCallback = () => import('../on-boarding.module').then(m => m.OnBoardingModule);

    return this.ifNeeded(guide).pipe(
      switchMap(() => this.loader.loadModule(loadCallback)),
      map(moduleRef => moduleRef.injector.get(ON_BOARDING_GUIDES).openDialog(guide)),
      catchError(err => {
        this.errHandler.logErrorAsError(err, `OnBoarding: failed to request guide ${guide}`);

        return EMPTY;
      }),
    );
  }

  private ifAuthenticated(): Observable<User> {
    return this.auth.authorizedUser$.pipe(
      observeOn(asyncScheduler),
      filter(() => this.auth.isRegistered()),
      filter(() => !this.auth.resetPassToken),
      first(),
    );
  }

  private ifNeeded(guide: OnBoardingGuide): Observable<User> {
    return this.ifAuthenticated()
      .pipe(
        filter(() => false === /\?\w+=/g.test(this.router.url)),
        // DEBUG: Comment out this line for viewing OnBoarding popup permanently
        filter(user => !(user.onBoarding && user.onBoarding.state[guide])),
      );
  }

  private initSignUpGuide(): Subscription {
    return this.auth.authorizedUser$
      .pipe(
        first(),
        delayWhen(() => this.router.events.pipe(first(e => e instanceof NavigationEnd))),
        switchMap(() => this.requestGuide('signUp')),
      )
      .subscribe({
        error: err => this.errHandler.logErrorAsError(err, 'OnBoarding: failed to initialize service'),
      });
  }
}

export interface PageWithOptions {
  readonly withHeader?: boolean;
  readonly withFooter?: boolean;
  readonly withPlayer?: boolean;
  readonly withCookieConsent?: boolean;
}

export function isPageWithOptions(page: unknown): page is PageWithOptions {
  if (page && typeof page === 'object') {
    return 'withHeader' in page ||
      'withFooter' in page ||
      'withPlayer' in page ||
      'withCookieConsent' in page;
  }

  return false;
}

export enum CampaignSelfStatus {
  Active = 0,
  Funded = 1,
  Failed = -1,
  Blocked = -2,
}

export enum CampaignVisibility {
  Private = 0,
  Unlisted = 1,
  Public = 2,
  DistributionOnly = 3,
  Auction = 4,
  NFT = 5,
}

export enum CampaignPurgeStatus {
  Deleted = 1,
  // Archived = 2, // not in use. Uncomment if needed
}

export enum CampaignPublishStatus {
  Draft = 0,
  Published = 1,
}


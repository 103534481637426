import {isPlatformBrowser} from '@angular/common';
import {Inject, Injectable, Optional, PLATFORM_ID} from '@angular/core';
import {REQUEST} from '@nguniversal/express-engine/tokens';
import {Request} from 'express';

@Injectable({providedIn: 'root'})
export class CookieService<T extends string = string> {
  isAvailable = this.checkCookie();
  static isStorageAvailable = checkLocalStorage();
  isWritable = isPlatformBrowser(this.pId);

  static parseCookieValues(name: string, cookie: string): string[] | undefined {
    const pattern = `${name}=([^;]*)(;|$)`;
    const matches = cookie.match(new RegExp(pattern, 'g'));

    if (matches && matches.length) {
      return matches.map(match => match.replace(new RegExp(pattern), '$1'));
    }

    return undefined;
  }

  constructor(@Inject(PLATFORM_ID) private pId: object,
              @Optional() @Inject(REQUEST) private req: Request,
  ) {}

  has(name: T) {
    return this.getCookie().includes(`${name}=`);
  }

  get(name: T): string[] | undefined {
    return CookieService.parseCookieValues(name, this.getCookie());
  }

  getOne(name: T): string | undefined {
    const values = CookieService.parseCookieValues(name, this.getCookie());

    if (values && values.length) {
      if (values.length > 1) {
        throw new Error(`Expected only one cookie '${name}', found ${values.join()}`);
      }

      return values[0];
    }

    return undefined;
  }

  set(name: T, value: string | number, expires?: Date, domain?: string, path = '/') {
    this.setCookie(name, value, expires, domain, path);
  }

  remove(name: T, domain?: string, path = '/') {
    this.setCookie(name, '', new Date(0), domain, path);

    // Those cookies which was set to the current domain should be removed
    // either for "current domain" and for "no domain" as we don't know for sure
    // how it was set.
    if (isPlatformBrowser(this.pId)) {
      if (!domain) {
        this.setCookie(name, '', new Date(0), location.hostname, path);
      } else if (domain === location.hostname) {
        this.setCookie(name, '', new Date(0), undefined, path);
      }
    }
  }

  getCookie(): string {
    if (isPlatformBrowser(this.pId)) {
      return document.cookie;
    } else if (this.req && this.req.headers) {
      return this.req.headers.cookie || '';
    }

    return '';
  }

  private setCookie(name: T, value: string | number, expires?: Date, domain?: string, path?: string) {
    if (isPlatformBrowser(this.pId)) {
      let expr = `${name}=${value}`;

      if (expires) {
        expr += `; expires=${expires.toUTCString()}`;
      }

      if (domain) {
        // Remove leading dot as it is not necessary as per FRC
        // AND `domain=.localhost` is ignored entirely by the browsers.
        // See https://tools.ietf.org/html/rfc6265#section-5.2.3
        expr += `; domain=${domain.replace(/^\.+/, '')}`;
      }

      if (path) {
        expr += `; path=${path}`;
      }

      document.cookie = expr;
    } else {
      throw new Error('Setting cookie on the server side is not supported.');
    }
  }

  private checkCookie(): boolean {
    if (!isPlatformBrowser(this.pId)) {
      // Cannot test, fallback to usual app behavior.
      return true;
    }

    try {
      // Create cookie
      document.cookie = 'cookieTest=1';
      const ret = document.cookie.indexOf('cookieTest=') !== -1;
      // Delete cookie
      document.cookie = 'cookieTest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT';

      return ret;
    } catch (e) {
      return false;
    }
  }
}

function checkLocalStorage(): boolean {
  if (typeof localStorage === 'undefined') {
    return false;
  }

  try {
    localStorage.setItem('__corite_test', '1');
    localStorage.getItem('__corite_test');
    localStorage.removeItem('__corite_test');

    return true;
  } catch (e) {
    return false;
  }
}

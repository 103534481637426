import {Injectable} from '@angular/core';
import {ApiResponse} from '@app/core/services/api-interceptor.service';
import {DateConvertor} from '@util/date';
import {cutObject, fixNumberPrecision, logDebugMessageInBrowser} from '@util/misc';
import {Campaign, CampaignApiData, CampaignApiPayload, CampaignDataChunk, CampaignPayload, CampaignRevId} from '../models/campaign';
import {CampaignAssets} from '../models/campaign/assets';
import {CampaignAuctionSettings} from '../models/campaign/auction';
import {CampaignAudioSnippet} from '../models/campaign/audio';
import {CampaignSkills} from '../models/campaign/skills';
import {CampaignFacebook, CampaignInstagramFeed, CampaignSocialActivity} from '../models/campaign/socials';
import {CampaignSpendOn} from '../models/campaign/spend-on';
import {CampaignSelfStatus} from '../models/campaign/status';
import {Genre} from '../models/genre';
import {Release, ReleaseApiPayload, ReleasePayload} from '../models/release';
import {ReleaseStatus} from '../models/release/status';

@Injectable({providedIn: 'root'})
export class CampaignNormalizer {
  public importCampaignData(data: CampaignApiData, revRequested: CampaignRevId): Campaign {
    // Assign Campaign class itself.
    const campaign: Campaign = Object.assign(new Campaign(), data);

    // Normalize all bool-like fields.
    const boolFields = ['bcUnsync', 'recoupmentFirst'] as const;
    for (const key of boolFields.filter(k => k in data)) {
      campaign[key] = !!data[key];
    }

    // Trim all the text fields.
    const textFields = [
      'title',
      'description', 'musicDescription', 'artistDescription',
    ] as const;
    for (const key of textFields.filter(k => 'string' === typeof data[k])) {
      campaign[key] = data[key].trim();
    }

    // Normalize dates
    const dateFields = ['dateDeadline', 'expiryDate'] as const;
    for (const key of dateFields.filter(k => 'number' === typeof data[k])) {
      campaign[key] = new Date(data[key] * 1000);
    }

    this.applyDataChunk(campaign, data, Genre, 'genre');
    this.applyDataChunk(campaign, data, CampaignAudioSnippet, 'audioSnippet');
    this.applyDataChunk(campaign, data, Release, 'releaseInfo', 'status');
    this.applyDataChunk(campaign, data, CampaignFacebook, 'facebook', 'pageId');
    this.applyDataChunk(campaign, data, CampaignInstagramFeed, 'instagram', 'user');
    this.applyDataChunk(campaign, data, CampaignAssets, 'assets');
    this.applyDataChunk(campaign, data, CampaignSpendOn, 'spendOn', 'fundsDestinations');
    this.applyDataChunk(campaign, data, CampaignSkills, 'skills', 'skills');
    this.applyDataChunk(campaign, data, CampaignAuctionSettings, 'auctionSettings', 'id');

    // Assign SocialActivity
    if (data.socialActivity && Array.isArray(data.socialActivity)) {
      campaign.socialActivity = data.socialActivity
        .map(obj => Object.assign(new CampaignSocialActivity(obj.type), obj));
    }

    // Adjust invest limits
    if (typeof campaign.investMax !== 'number') {
      campaign.investMax = campaign.fundAvailable;
    }
    campaign.investMin = fixNumberPrecision(campaign.investMin, 2);
    campaign.investMax = fixNumberPrecision(campaign.investMax, 2);
    campaign.investSuggested = fixNumberPrecision(campaign.investSuggested, 2);


    // Remember revRequested.
    campaign.revRequested = revRequested;

    this.validateCampaign(campaign);

    // {
    //   campaign.bcCampaignId = 666;
    //   console.log('DEBUG: Simulate Campaign is on chain', campaign);
    // }

    // {
    //   campaign.myShares = {backed: 100, allotment: 0, owner: 0};
    //   campaign.myTokens = {share: Date.now()};
    //   console.log('DEBUG: Simulate campaign.myTokens', campaign);
    // }

    logDebugMessageInBrowser('Campaign', `Imported fan campaign ${campaign.title}`, {campaign, data, revRequested});

    return campaign;
  }

  private validateCampaign(campaign: Campaign): void {
    if (campaign.audio && !campaign.audioSnippet && !campaign.isDistributionOnly) {
      campaign.audioSnippet = new CampaignAudioSnippet();
      campaign.errors.push(`This fan campaign lacks audio snippet to play!`);
    }

    if (campaign.isPublished && !campaign.genre.title) {
      campaign.errors.push('This fan campaign lacks Genre definition.');
    }

    if (!campaign.isDistributionOnly && campaign.releaseInfo.status === ReleaseStatus.Released && !campaign.expiryDate) {
      campaign.errors.push(`This Released fan campaign lacks expiry date`);
    }

    if (campaign.campaignStatus === CampaignSelfStatus.Active && campaign.dateDeadline && campaign.dateDeadline.getTime() < Date.now()) {
      campaign.errors.push(`This fan campaign cannot be Active due to passed deadline: ${campaign.dateDeadline.toLocaleDateString()}`);
    }

    if (campaign.campaignStatus === CampaignSelfStatus.Funded) {
      if (!campaign.isFundedEnough) {
        campaign.errors.push(`This fan campaign cannot be Funded due to funds available: ${campaign.currency}${campaign.fundAvailable}`);
      }
    }

    if (campaign.status !== CampaignSelfStatus.Active) {
      const pending = campaign.fundLimit - campaign.funded - campaign.fundAvailable;

      // Treat <1 cent as floating point precision error...
      if (pending >= 0.01) {
        // ...otherwise, tell Huston about our problems :)
        campaign.errors.push(`This fan campaign cannot have pending orders (${campaign.currency}${pending}) as it is not Active`);
      }
    }
  }

  public exportCampaignData(campaign: Campaign, values: CampaignPayload): CampaignApiPayload {
    const data: CampaignApiPayload = {id: campaign.id, ...values};

    if (values.genre) {
      data.genre = Object.assign({}, values.genre);
    }

    if (values.facebook) {
      data.facebook = CampaignFacebook.fromData(values.facebook).toData();
    }

    if (values.socialActivity) {
      data.socialActivity = values.socialActivity
        .filter(activity => activity.url || activity.count)
        .map(activity => Object.assign({}, activity));
    }

    return data;
  }

  exportReleaseData(campaign: Campaign, values: ReleasePayload): ReleaseApiPayload {
    if (!campaign.id && !values.distributionOnly) {
      throw new Error('Unable to update release info of unsaved fan campaign');
    }

    const data: ReleaseApiPayload = {id: campaign.id, ...cutObject(values, 'releaseDate')};

    if ('releaseDate' in values) {
      data.releaseDate = values.releaseDate instanceof Date
        ? DateConvertor.toDateISOString(values.releaseDate)
        : values.releaseDate;
    }

    return data;
  }

  protected applyDataChunk<T extends Campaign[K], K extends keyof CampaignApiData>(
    campaign: Campaign,
    data: CampaignApiData,
    type: CampaignDataChunk<T>,
    key: K,
    fieldValidate?: keyof T,
  ): void {
    const chunk = data[key];

    if (chunk) {
      if (typeof chunk === 'object' && (!fieldValidate || fieldValidate in chunk)) {
        campaign[key] = type.fromData(chunk);
      } else if (typeof chunk === 'object' && 'errors' in chunk) {
        campaign[key] = new type();
        try {
          campaign.errors.push((chunk as any as ApiResponse<never>).errors[0].message);
        } catch (e) {
          campaign.errors.push(e.toString());
        }
      } else {
        campaign.errors.push(`Invalid data for '${key}' field.`);
      }
    }
  }
}

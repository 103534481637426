import {fixNumberPrecision} from '@util/misc';
import {CampaignAssets, CampaignAssetsData} from './campaign/assets';
import {CampaignAuctionSettings, CampaignAuctionSettingsData} from './campaign/auction';
import {CampaignAudioSnippet, CampaignAudioSnippetData} from './campaign/audio';
import {CampaignExperience} from './campaign/risks';
import {CampaignSkills, CampaignSkillsData} from './campaign/skills';
import {
  CampaignFacebook,
  CampaignFacebookData,
  CampaignInstagramFeed,
  CampaignInstagramFeedData,
  CampaignSocialActivity,
  CampaignSocialActivityData,
} from './campaign/socials';
import {CampaignSpendOn, CampaignSpendOnData} from './campaign/spend-on';
import {CampaignPublishStatus, CampaignSelfStatus, CampaignVisibility} from './campaign/status';
import {CampaignUser} from './campaign/users';
import {Genre} from './genre';
import {Release, ReleaseApiData} from './release';
import {ReleaseStatus} from './release/status';
import {ModerationStatus} from './status';

export interface CampaignPayload {
  bcCampaignId?: string;
  currency?: CampaignCurrency;
  visibility?: CampaignVisibility;
  title?: string;
  picture?: string;
  pictures?: string[];
  description?: string;
  musicDescription?: string;
  artistDescription?: string;
  genre?: Genre;
  artist?: string;
  price?: number;
  priceShare?: number;
  priceShareMin?: number;
  audio?: string;
  audioSource?: string;
  audioSnippet?: CampaignAudioSnippetData;
  skills?: CampaignSkills;
  spendOn?: CampaignSpendOn;
  video?: string;
  spotify?: string;
  facebook?: CampaignFacebookData;
  socialActivity?: CampaignSocialActivityData[];

  // Actions
  publish?: true;
  unpublish?: true;
}

export interface CampaignApiPayload extends CampaignPayload {
  id: number | null;
}

export interface CampaignApiData {
  id: number;
  revId: number;
  bcCampaignId?: string; // hex string
  currency: CampaignCurrency;
  visibility: CampaignVisibility;
  user: CampaignUser;
  title?: string | null;
  picture?: string | null;
  description?: string | null;
  musicDescription?: string | null;
  artistDescription?: string | null;
  genre?: Genre | null;
  artist?: string | null;
  price?: number | null;
  priceShare?: number | null;
  priceShareMin?: number | null;
  priceShareMax?: number | null;
  funded?: number | null;
  fundLimit?: number | null;
  fundAvailable?: number | null;
  audio?: string | null;
  audioSnippet?: CampaignAudioSnippetData | null;
  video?: string | null;
  spotify?: string | null;
  facebook?: CampaignFacebookData | null;
  instagram?: CampaignInstagramFeedData | null;
  socialActivity?: CampaignSocialActivityData[] | null;
  spendOn?: CampaignSpendOnData | null;
  skills?: CampaignSkillsData | null;
  pictures?: string[] | null;
  artistURL?: string | null;
  titleURL?: string | null;
  campaignURL?: string | null;
  spotifyUrl?: string | null;

  releaseInfo?: ReleaseApiData;
  assets?: CampaignAssetsData | null;
  auctionSettings?: CampaignAuctionSettingsData;

  // Virtual fields
  readonly my: boolean;
  readonly myShares: {
    owner?: number, // EUR/USD
    backed: number, // EUR/USD
    allotment: number, // EUR/USD
  };
  readonly myTokens: {
    share: number, // µCS ($100 = 100_000000µCS)
  };
  readonly bcUnsync?: 1;
  readonly dateDeadline?: number | null;
  readonly expiryDate?: number | null;
  readonly campaignStatus: CampaignSelfStatus;
  readonly publishStatus: CampaignPublishStatus;
  readonly moderationStatus: ModerationStatus;
  readonly allow: CampaignPermissions;

  readonly investMin: number; // EUR/USD
  readonly investMax: number; // EUR/USD
  readonly investLimit: number; // EUR/USD
  readonly investSuggested: number; // EUR/USD
  readonly recoupmentFirst: 0 | 1 | null;

  readonly onModerate?: {id: number, time: number};
  readonly published?: {id: number, time: number, comment?: string, suspended?: boolean};
  readonly declined?: {id: number, time: number, comment: string};
  readonly last?: {
    id: number,
    time: number,
    comment: string,
    publishStatus: CampaignPublishStatus,
    moderationStatus: ModerationStatus,
  };

  readonly cGroup: 'upcoming' | 'to_be_released' | 'released' | 'failed' | 'other';
}

export class Campaign {
  // DB fields.
  id: number | null = null;
  revId: number | null = null;
  currency: CampaignCurrency | null = null;
  visibility: CampaignVisibility = CampaignVisibility.Public;
  title: string | null = null;
  picture: string | null = null;
  description: string | null = null;
  musicDescription: string | null = null;
  artistDescription: string | null = null;
  genre = new Genre();
  artist: string | null = null;
  price: number | null = null;
  priceShare: number | null = null;
  priceShareMin = 0;
  priceShareMax: number | null = null;
  priceShareCampaign = 90; // Hard-coded 10% reserve for Corite.
  funded: number | null = null;
  fundLimit: number | null = null;
  fundAvailable: number | null = null;
  audio: string | null = null;
  audioSource: string | null = null;
  audioSnippet = new CampaignAudioSnippet();
  video: string | null = null;
  spotify: string | null = null;
  experience = new CampaignExperience();
  facebook = new CampaignFacebook();
  instagram = new CampaignInstagramFeed();
  socialActivity: CampaignSocialActivity[] = [];
  spendOn = new CampaignSpendOn();
  skills = new CampaignSkills();
  user: CampaignUser | null = null;
  pictures: string[] = [];
  artistURL: string | null = null;
  titleURL: string | null = null;
  campaignURL: string | null = null;
  spotifyUrl: string | null = null;

  releaseInfo = new Release();
  assets = new CampaignAssets();
  auctionSettings?: CampaignAuctionSettings;

  // Virtual fields
  my: boolean;
  myShares = {
    owner: 0,
    backed: 0,
    allotment: 0,
  };

  myTokens = {
    share: 0,
  };

  bcCampaignId?: string; // hex string
  bcUnsync = false;
  dateDeadline: Date | null = null;
  expiryDate: Date | null = null;
  campaignStatus: CampaignSelfStatus = CampaignSelfStatus.Active;
  publishStatus: CampaignPublishStatus = CampaignPublishStatus.Draft;
  moderationStatus: ModerationStatus = ModerationStatus.None;
  moderationComment?: string;
  allow?: CampaignPermissions;
  errors: string[] = [];

  investMin: number | null = 10; // EUR/USD
  investMax: number | null = null; // EUR/USD
  investLimit: number | null = null; // EUR/USD
  investSuggested: number | null = 50; // EUR/USD
  recoupmentFirst = true;

  revRequested: CampaignRevId;
  onModerate: {id: number, time: number};
  published: {id: number, time: number, comment?: string, suspended?: boolean};
  declined: {id: number, time: number, comment: string};
  last: {
    id: number,
    time: number,
    comment: string,
    publishStatus: CampaignPublishStatus,
    moderationStatus: ModerationStatus,
  };

  cGroup: CampaignApiData['cGroup'] = 'upcoming';

  constructor(campaignData?: Partial<Campaign>) {
    if (campaignData) {
      Object.assign(this, campaignData);
    }
  }

  get routerLinkArtist(): [string] | undefined {
    if (this.artistURL) {
      return [`/${this.artistURL}`];
    }

    return undefined;
  }

  get routerLinkTitle(): [string, ...any[]] {
    if (this.isDistributionOnly) {
      return ['/releases', this.id];
    } else if (this.campaignURL) {
      return [`/${this.campaignURL}`];
    } else {
      return ['/explore', this.id];
    }
  }

  get status(): CampaignStatus {
    switch (this.campaignStatus) {
      case CampaignSelfStatus.Active:
        return this.isVisible
          ? CampaignSelfStatus.Active
          : CampaignVirtualStatus.Draft;

      case CampaignSelfStatus.Funded:
        if (this.isDistributionOnly || this.releaseInfo.status > ReleaseStatus.Draft) {
          return this.releaseInfo.status;
        }

        return this.campaignStatus;

      default:
        return this.campaignStatus;
    }
  }

  // noinspection JSUnusedGlobalSymbols (used in templates)
  get statusName(): string {
    return CampaignSelfStatus[this.status]
      || CampaignVirtualStatus[this.status]
      || ReleaseStatus[this.status];
  }

  get isVisible(): boolean {
    if (this.campaignStatus === CampaignSelfStatus.Blocked) {
      return false;
    }

    return !!(this.publishStatus === CampaignPublishStatus.Published || this.published);
  }

  get isFundedEnough(): boolean {
    if (!this.price) {
      // Draft campaign: treat as no funds anyway.
      return false;
    }

    const shareFunded = fixNumberPrecision(Number(this.funded) * 100 / this.price);

    return shareFunded >= this.priceShareMin;
  }

  /**
   * Whether Creator have requested campaign to publish.
   * Regardless of current moderation status.
   */
  get isPublished(): boolean {
    if (this.isVisible) {
      return true;
    } else if (this.moderationStatus === ModerationStatus.Declined || this.declined) {
      return false;
    } else if (this.moderationStatus === ModerationStatus.NeedsModeration || this.onModerate) {
      return true;
    }

    return false;
  }

  /**
   * Like .isPublished but TRUE only if there is some new revision created
   * and hasn't sent to moderation yet.
   */
  get isPublishedAndChanged(): boolean {
    return this.isPublished && this.moderationStatus === ModerationStatus.None;
  }

  get isFunded(): boolean {
    return this.campaignStatus === CampaignSelfStatus.Funded;
  }

  get isSuspended(): boolean {
    return this.publishStatus === CampaignPublishStatus.Published &&
      this.moderationStatus === ModerationStatus.Declined;
  }

  get isDistributionOnly(): boolean {
    return this.visibility === CampaignVisibility.DistributionOnly;
  }
}

// Virtual status, front-end ONLY!
export enum CampaignVirtualStatus {
  Draft = -99,
}

export type CampaignStatus = CampaignVirtualStatus | CampaignSelfStatus | ReleaseStatus;

export interface CampaignPermissions {
  edit: boolean;
  delete: boolean;
  unpublish: boolean;
}

export type CampaignRevId = number | 'last' | 'published' | 'moderate';
export type CampaignCurrency = 'USD' | 'EUR';

export interface CampaignDataChunk<T> {
  new(...args: any[]): T;

  fromData(data: any): T;
}

import {firstValueFrom, fromEvent, MonoTypeOperatorFunction, Observable, repeat} from 'rxjs';
import {filter, retry} from 'rxjs/operators';

export function repeatWhenDocumentVisible<T>(): MonoTypeOperatorFunction<T> {
  return (source: Observable<T>) => source.pipe(
    repeat({delay: whenDocumentVisible()}),
  );
}

export function retryWhenDocumentVisible<T>(): MonoTypeOperatorFunction<T> {
  return (source: Observable<T>) => source.pipe(
    retry({delay: whenDocumentVisible()}),
  );
}

function whenDocumentVisible(): () => Promise<unknown> {
  return async () => {
    if (typeof document === 'undefined' || document.visibilityState === 'visible') {
      return null;
    }

    return await firstValueFrom(fromEvent(document, 'visibilitychange').pipe(
      filter(() => document.visibilityState === 'visible'),
    ));
  };
}

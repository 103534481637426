export interface CampaignAudioSnippetData {
  file: string | null;
  start: number;
  duration: number;
}

export class CampaignAudioSnippet {
  file: string | null = null;

  constructor(
    public start = 0,
    public duration = 30,
  ) {}

  static fromData(data: Partial<CampaignAudioSnippetData>) {
    const dataNormalized: Partial<CampaignAudioSnippet> = data;

    return Object.assign(new CampaignAudioSnippet(), dataNormalized);
  }
}

<app-layout-simple>
  <app-title *appLayoutMainTitle>Sorry...</app-title>

  <main *appLayoutContent>
    <p i18n="Error page 500: text [VERIFIED]">There was an unexpected error while displaying this page.
      Try to refresh in a couple of minutes.</p>

    <div class="app-buttons">
      <a mat-flat-button color="accent" routerLink="/explore"
         i18n="Error page 500: /explore link [VERIFIED]">Explore</a>

      <!-- [href] is intentional here to force page reload. -->
      <a href="{{ path }}" mat-flat-button color="primary"
              i18n="Error page 500: Reload button [VERIFIED]">Refresh this page</a>
    </div>
  </main>
</app-layout-simple>

import {isPlatformBrowser} from '@angular/common';
import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {environment} from '@env/environment';
import {logDebugMessage} from '@util/misc';
import {merge, Subject, Subscription, timer} from 'rxjs';
import {defaultIfEmpty, first, takeUntil} from 'rxjs/operators';
import {Logger} from './logger.service';

@Injectable({providedIn: 'root'})
export class PageMetaValidator {
  static readonly VALIDATION_TIMEOUT = 5000;
  private options: {provideCommandsQueue: () => Array<() => void>};
  private validationSubscription: Subscription;
  private flushResolutionSubject = new Subject<void>();
  private oldCommandsQueue: Array<() => void> = [];

  constructor(private router: Router,
              private logger: Logger,
              private meta: Meta,
              private title: Title,
              @Inject(PLATFORM_ID) protected pId: object,
  ) {
  }

  initialize(options: PageMetaValidator['options']) {
    this.options = options;

    this.router.events.subscribe(event => {
      // Store OLD commands from previous page.
      if (event instanceof NavigationStart) {
        this.oldCommandsQueue = this.options.provideCommandsQueue();
      }

      // Perform check after NavigationEnd to compare old commands with
      // those after 1s and there is no need to wait more than 1s
      // (no networks calls, only JS ideally)
      if (event instanceof NavigationEnd) {
        // this.commandsQueues.new = this.options.provideCommandsQueue();
        this.routerValidatePage(event);
      }
    });
  }

  registerFlushResolution() {
    this.flushResolutionSubject.next();
  }

  /**
   * Checks meta tags are set correctly after next page initialization.
   */
  private routerValidatePage(routerEvent: NavigationEnd): void {
    if (!isPlatformBrowser(this.pId)) {
      return;
    }

    // Skip internal routes.
    switch (true) {
      case routerEvent.url.startsWith('/_'):
      case routerEvent.url.startsWith('/dev/'):
      case routerEvent.url.startsWith('/503'):
        return;
    }

    // Skip navigations that do nothing
    // (e.g. /explore -> /activity (quick, cancelled) -> /explore).
    // (e.g. /profile -> /profile?open=change-email).
    const currentNav = this.router.getCurrentNavigation();
    if (currentNav && currentNav.previousNavigation) {
      const urlNew = currentNav.finalUrl.toString()
        .replace(/\?[^?]+$/g, '');
      const urlOld = currentNav.previousNavigation.finalUrl.toString()
        .replace(/\?[^?]+$/g, '');

      if (urlNew === urlOld) {
        return;
      }
    }

    const loggerContext: any = {
      event: {
        id: routerEvent.id,
        url: routerEvent.url,
      },
      commandsCount: this.oldCommandsQueue.length,
    };

    let page = 'Current page';
    if (currentNav) {
      loggerContext.currentNavigation = {
        initialUrl: currentNav.initialUrl.toString(),
        extractedUrl: currentNav.extractedUrl.toString(),
        finalUrl: currentNav.finalUrl.toString(),
      };
      page += ` (${currentNav.finalUrl.toString()})`;
    }

    if (this.validationSubscription) {
      if (!this.validationSubscription.closed) {
        this.validationSubscription.unsubscribe();
        this.logger.oopsWarn('PageMeta: skipped SEO tags verification', loggerContext);
      }

      delete this.validationSubscription;
    }

    // Wait for flush resolution at most VALIDATION_TIMEOUT ms.
    const delay$ = timer(PageMetaValidator.VALIDATION_TIMEOUT);
    this.validationSubscription = merge(delay$, this.flushResolutionSubject)
      .pipe(first(), takeUntil(delay$), defaultIfEmpty(null))
      .subscribe(() => {
        if (this.oldCommandsQueue === this.options.provideCommandsQueue()) {
          throw new Error(`PageMeta: ${page} did not flushed meta tags`);
        }

        if (!this.title.getTitle().includes(' | ')) {
          throw new Error(`PageMeta: ${page} lacks correct Page Title`);
        }

        // Skip protected and "non-shareable" areas.
        if (this.router.url.match(environment.pageMetaExclude)) { return; }

        const tags = this.meta.getTags('property*="og"').map(tag => ({
          property: tag.getAttribute('property'),
          content: tag.content,
        }));

        logDebugMessage('PageMeta', `${page} meta tags has been set.`, {tags});

        if (this.meta.getTag('name="robots"')?.content === 'noindex') {
          return;
        }

        // Ensure some mandatory meta tags.
        const hasOgTitle = !!this.meta.getTag('property="og:title"');
        const hasOgDescription = !!this.meta.getTag('property="og:description"');
        if (!hasOgTitle || !hasOgDescription) {
          throw new Error('PageMeta: Current page lacks some "og:*" meta tag.');
        }
      });
  }
}

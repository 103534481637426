import {Location} from '@angular/common';
import {Component, Inject, OnInit, Optional} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {RESPONSE} from '@nguniversal/express-engine/tokens';
import {Response} from 'express-serve-static-core';
import {PageMetaService} from '../../services/page-meta.service';

@Component({
  selector: 'page-err500',
  templateUrl: './err500.page.html',
})
export class Err500Page implements OnInit {
  get path(): string {
    return this.res?.req?.url ?? this.location.path();
  }

  constructor(private meta: PageMetaService,
              private location: Location,
              private route: ActivatedRoute,
              @Optional() @Inject(RESPONSE) private res: Response,
  ) {
    // The thing that should not be, but...
    // ...if user ends up to /500 URL show root page after reload.
    if (this.location.path() === '/500') {
      this.location.replaceState('/');
    }
  }

  ngOnInit() {
    this.meta
      .setTitles('Internal Server Error')
      .setDescription('Something went wrong on our end. We are sorry for the trouble. Please refresh the page or come back later. We are working hard to fix the issue as soon as possible.')
      .flush();

    if (this.res) {
      this.res.status(500);
    }
  }
}

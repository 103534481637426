import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Data, Route} from '@angular/router';
import {FeatureFlagsService} from '@app/services/version/services/feature-flags.service';
import {FeatureFlagName, FeatureFlagSettings} from '@app/services/version/types';
import {environment} from '@env/environment';

export interface ExperimentalGuardData<F extends FeatureFlagName> extends Data {
  flag?: F;
  flagProp?: keyof FeatureFlagSettings[F];
}

@Injectable({
  providedIn: 'root',
})
export class ExperimentalGuard {
  constructor(private ff: FeatureFlagsService) {}

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    return this.doCheck(route.data);
  }

  canMatch(route: Route): Promise<boolean> {
    return this.doCheck(route.data);
  }

  private async doCheck(data: ExperimentalGuardData<FeatureFlagName>) {
    if (data.flag) {
      const flag = await this.ff.resolveFeature(data.flag);

      if (data.flagProp) {
        return this.ff.isFeatureSupported(data.flag, data.flagProp);
      }

      return flag.isSupported;
    }

    return environment.experimental;
  }
}

<div class="header__placeholder"></div>
<div class="header" [style.height.px]="heightStyle" [class.closed]="isClosed" [class.animation]="isAnimation">
  <div class="top app-padding">
    <div class="top__left flex">
      <button type="button" class="back-button" mat-icon-button
              *ngIf="backButton.isApplicable; else logo"
              [disabled]="backButton.isNavigating"
              (click)="backButton.goBack()"><mat-icon fontIcon="back" /></button>
      <ng-template #logo>
        <app-logo class="logo"></app-logo>
      </ng-template>
    </div>

    <div class="top__right flex">
      <span *ngIf="shouldShowWallet">
         <a class="mat-icon-button" [routerLink]="['/wallets']" [queryParams]="{connect:'crypto'}" rel="nofollow"
            data-e2e="wallet" i18n="Header: Connect Wallet" aria-label="Connect Wallet">
           <mat-icon fontIcon="payment" />
         </a>
      </span>

      <span class="menu" *ngIf="menuPortal$ | async as menuPortal; else noMenuTpl">
        <ng-container [cdkPortalOutlet]="menuPortal"></ng-container>
      </span>

      <ng-template #noMenuTpl>
        <ng-container *ngIf="shouldShowSignIn">
          <a routerLink="/user/login" class="link app-color-inherit sign-in" data-e2e="button-start"
             i18n="Header: Sign in button [VERIFIED]@@signInLink">Sign in</a>
        </ng-container>

        <app-loader class="right-item" *ngIf="!shouldShowSignIn"></app-loader>
      </ng-template>
    </div>
  </div>

  <div class="main-menu-line bg-surface">
    <div class="app-layout-center app-padding">
      <nav class="app-padding__out--mob-tab main-menu">
        <a class="main-menu-link" mat-ripple routerLink="/explore" routerLinkActive
           #rla1="routerLinkActive" [class.active]="rla1.isActive" data-e2e="tab-explore">
          <mat-icon class="main-menu-icon" fontIcon="header_explore" />
          <ng-container i18n="Header: Explore link [VERIFIED]@@exploreLink">Explore</ng-container>
        </a>
        <a *ngIf="shouldShowActivity" class="main-menu-link" mat-ripple routerLink="/activity"
           rel="nofollow" routerLinkActive
           #rla2="routerLinkActive" [class.active]="rla2.isActive" data-e2e="tab-activity">
          <mat-icon class="main-menu-icon" fontIcon="header_activity" />
          <ng-container i18n="Header: /activity link [VERIFIED]@@activityLinkHeader">Activity</ng-container>
        </a>
        <a class="main-menu-link" mat-ripple href="{{environment.betaUrl}}/missions" data-e2e="tab-missions">
          <mat-icon class="main-menu-icon" fontIcon="header_missions" />
          <ng-container i18n="Header: Create link [VERIFIED]@@createLink">Missions</ng-container>
        </a>
        <a *appForArtists="!shouldShowActivity" appForArtistsLink
           class="main-menu-link" mat-ripple>
          <mat-icon class="main-menu-icon" fontIcon="header_create_campaign" />
          <ng-container>For Artists</ng-container>
        </a>
      </nav>
    </div>
  </div>
</div>

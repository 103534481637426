import {Location} from '@angular/common';
import {Injectable} from '@angular/core';
import {Event, NavigationCancel, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {CoriteEnv} from '@util/env';
import {routePath} from '@util/ng/router';
import {filter, tap} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class BackButtonService {
  isApplicable = false;
  isNavigating = false;

  // Hard-core campaign page here as only single page is supported currently.
  // If back button will be requested for more pages then most of service's
  // code will have to be rewritten anyway.
  private readonly supportedPaths = [
    ':artist/:title', // for backers
    'releases/:id/campaign', // for artists
  ];

  // When navigating from internal subpages of this.supportedPaths
  // back button should be hidden as it should go "up" through hierarchy
  // instead of "back to inner page".
  private readonly ignoredReferrers: RegExp[] = [
    /^\/explore\/\d+/,
    /^\/releases\/\d+/,
  ];

  private navigationStart?: NavigationStart;

  constructor(private location: Location,
              private router: Router,
  ) {
    if (CoriteEnv.touchMobile().andPlatform('ios').isValid()) {
      this.consumeRouterEvents();
    }
  }

  goBack(): void {
    if (!this.isApplicable) {
      throw new Error('Back button is not applicable. Please check `.isApplicable` first.');
    }

    this.location.back();
  }

  private consumeRouterEvents() {
    this.router.events.pipe(
      tap(e => {
        if (e instanceof NavigationStart && !this.navigationStart) {
          this.navigationStart = e;
        }
      }),
      tap(e => this.maintainIsNavigating(e)),
      filter(e => e instanceof NavigationEnd),
      tap(() => this.maintainIsApplicable()),
      tap(() => {
        delete this.navigationStart;
      }),
    ).subscribe();
  }

  private maintainIsNavigating(e: Event): boolean {
    if (e instanceof NavigationStart) {
      return this.isNavigating = true;
    } else if (e instanceof NavigationCancel || e instanceof NavigationEnd) {
      return this.isNavigating = false;
    }

    return this.isNavigating;
  }

  private maintainIsApplicable(): boolean {
    const navigation = this.router.getCurrentNavigation();
    const currUrl = navigation.extractedUrl.toString();
    const prevUrl = navigation.previousNavigation?.extractedUrl.toString();

    // Cut off browser's back navigation.
    if (this.navigationStart?.navigationTrigger !== 'imperative') {
      return this.isApplicable = false;
    }

    // Skip ghost navigations (no record in history - no changes to back button).
    if (navigation.extras.skipLocationChange) {
      return this.isApplicable;
    }

    // Cut off same URL navigations.
    if (!prevUrl || prevUrl === currUrl) {
      return this.isApplicable = false;
    }

    // Cut off ignored referrers.
    if (this.ignoredReferrers.some(pattern => pattern.test(prevUrl))) {
      return this.isApplicable = false;
    }

    // Enable, only on supported pages.
    return this.isApplicable = this.supportedPaths.includes(routePath(this.router.routerState.root));
  }
}

import {UserBrief} from '@app/core/models/user';

export class CampaignUser implements UserBrief {
  id: number | null = null;
  name: string | null = null;
  userImage: string | null = null;
}

export class CampaignBacker extends CampaignUser {
  sums: number;
  share: number; // 0..100
  percent: number; // 0..1
  label: Array<'backer' | 'creative' | 'creator'>;
}

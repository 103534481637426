import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {distinctUntilKeyChanged} from 'rxjs/operators';

export interface SafeAreaCoords {
  top: number;
  left: number;
  bottom: number;
  right: number;
}

export interface SafeAreaLayer {
  id: string;
  coords: SafeAreaCoords;
}

@Injectable({providedIn: 'root'})
export class SafeAreaService<LayerName extends string = never> {
  private readonly layers: SafeAreaLayer[] = [];
  private readonly areaSubject: BehaviorSubject<SafeAreaCoords>;

  constructor() {
    this.areaSubject = new BehaviorSubject(this.getInitialCoords());
  }

  resetSafeArea(id: LayerName): this {
    return this.setSafeArea(id, this.getInitialCoords());
  }

  setSafeArea(id: LayerName, coords: Partial<SafeAreaCoords>): this {
    const existingLayer = this.layers.find(l => l.id === id);
    const thisLayer = existingLayer ?? {id, coords: this.getInitialCoords()};

    if (!existingLayer) {
      this.layers.push(thisLayer);
    }

    Object.assign(thisLayer.coords, coords);

    const oldArea = this.areaSubject.getValue();
    const newArea = this.layers.reduce((previousLayer, currentLayer) => {
      return {
        id,
        coords: {
          top: Math.max(previousLayer.coords.top, currentLayer.coords.top),
          bottom: Math.max(previousLayer.coords.bottom, currentLayer.coords.bottom),
          left: Math.max(previousLayer.coords.left, currentLayer.coords.left),
          right: Math.max(previousLayer.coords.right, currentLayer.coords.right),
        },
      };
    }, thisLayer).coords;

    // Notify only if something has really changed.
    if (JSON.stringify(newArea) !== JSON.stringify(oldArea)) {
      this.areaSubject.next(newArea);
    }

    return this;
  }

  setSafeTop(id: LayerName, value: number) { return this.setSafeArea(id, {top: value}); }
  setSafeBottom(id: LayerName, value: number) { return this.setSafeArea(id, {bottom: value}); }
  setSafeLeft(id: LayerName, value: number) { return this.setSafeArea(id, {left: value}); }
  setSafeRight(id: LayerName, value: number) { return this.setSafeArea(id, {right: value}); }

  observeSafeArea(): Observable<SafeAreaCoords> {
    return this.areaSubject.asObservable();
  }

  observeSafeTop(): Observable<SafeAreaCoords> {
    return this.observeSafeArea().pipe(distinctUntilKeyChanged('top'));
  }

  observeSafeBottom(): Observable<SafeAreaCoords> {
    return this.observeSafeArea().pipe(distinctUntilKeyChanged('bottom'));
  }

  observeSafeLeft(): Observable<SafeAreaCoords> {
    return this.observeSafeArea().pipe(distinctUntilKeyChanged('left'));
  }

  observeSafeRight(): Observable<SafeAreaCoords> {
    return this.observeSafeArea().pipe(distinctUntilKeyChanged('right'));
  }

  getCurrentSafeArea(): SafeAreaCoords {
    return this.areaSubject.getValue();
  }

  private getInitialCoords(): SafeAreaCoords {
    return {
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
    };
  }
}

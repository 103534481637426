<div class="bg-inverse-surface wrapper mat-elevation-z6">
  <div class="text" i18n="Cookie consent: Description [VERIFIED]">
    Corite uses cookies to provide necessary site functionality and improve your experience. By using
    our website, you agree to our privacy policy.
  </div>

  <div class="buttons">
    <a [routerLink]="['/privacy-policy']" data-e2e="link-pp" mat-button class="button">More details</a>
    <button mat-button class="button" type="button" color="primary" (click)="dismiss()"
            aria-label="Agree" i18n-aria-label="Cookie consent: A11y: Close button label [VERIFIED]"
            data-e2e="action-close">OK, got it</button>
  </div>
</div>

import {ComponentPortal} from '@angular/cdk/portal';
import {Injectable} from '@angular/core';
import {AuthService} from '@app/core/services/auth.service';
import {AppModuleLoader} from '@app/core/services/module-loader';
import {UserNavPopupComponent} from '@app/widgets/user-menu/components/nav-popup/user-nav-popup.component';
import {mergeWith, Observable} from 'rxjs';
import {first, map, shareReplay, switchMap} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class AppHeaderMenuLoader {
  private readonly portal$: Observable<ComponentPortal<UserNavPopupComponent>>;

  constructor(private auth: AuthService,
              private moduleLoader: AppModuleLoader,
  ) {
    this.portal$ = this.loadPortal();
  }

  private loadPortal() {
    return this.auth.authorizedUser$.pipe(
      first(),
      switchMap(async () => {
        const {
          AppUserMenuModule: ngModule,
          UserNavPopupComponent: navComponent,
        } = await import('@app/widgets/user-menu/user-menu.module');
        const {injector} = await this.moduleLoader.resolveModuleRef(ngModule);

        return new ComponentPortal(navComponent, null, injector);
      }),
      shareReplay(1),
    );
  }

  observeMenuPortal() {
    return this.portal$.pipe(
      mergeWith(this.auth.userData$.pipe(switchMap(() => this.portal$))),
      map(portal => this.auth.isAuthenticated() ? portal : null),
    );
  }
}

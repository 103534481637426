import {Location} from '@angular/common';
import {HttpErrorResponse} from '@angular/common/http';
import {ErrorHandler, inject, Injectable, Injector, NgZone} from '@angular/core';
import {Router} from '@angular/router';
import {FeatureSupportError} from '@app/services/version/models';
import {firstValueFrom} from 'rxjs';
import {ApiError} from './api-interceptor.service';
import {AppStateService} from './app-state.service';
import {AuthNextService} from './auth-next.service';
import {AuthService} from './auth.service';
import {KnownErrorsService} from './known-errors.service';
import {Logger} from './logger.service';

export function isPromiseRejection(err: unknown): err is {rejection: unknown} {
  if (err && typeof err === 'object') {
    return 'rejection' in err;
  }

  return false;
}

export abstract class AbstractAppErrorHandler {
  // Dependency injection may not be fully available at this moment
  // so user Injector manually
  protected readonly injector = inject(Injector);

  protected get knownErrors(): KnownErrorsService {
    return this.injector.get(KnownErrorsService);
  }

  protected get router(): Router {
    return this.injector.get(Router);
  }

  protected get logger(): Logger {
    return this.injector.get(Logger);
  }

  async fallbackNavigation(error: unknown): Promise<boolean> {
    let routerCommands: any[] | undefined;

    if (isPromiseRejection(error)) {
      error = error.rejection;
    }

    const info = this.knownErrors.handleIfKnown(error);
    if (info.isKnown) {
      routerCommands = info.routerCommands;

      // Known error without router commands should be ignored.
      if (!routerCommands) {
        return false;
      }
    }

    if (!routerCommands) {
      if (error instanceof FeatureSupportError) {
        routerCommands = ['/404'];
      }

      if (error instanceof HttpErrorResponse) {
        switch (error.status) {
          case 404:
            routerCommands = ['/404'];
            break;
          case 403:
            routerCommands = ['/403'];
            break;
          case 503:
            routerCommands = ['/503'];
            break;
        }
      }

      if (error instanceof ApiError) {
        switch (error.response.getMainError().name) {
          case 'wrong_value':
          case 'not_available':
          case 'not_found':
            routerCommands = ['/404'];
            break;
          case 'not_auth':
          case 'access_denied':
            routerCommands = ['/403'];
            break;
        }
      }
    }

    // Unknown error, fallback to 500 and log it.
    if (!routerCommands) {
      routerCommands = ['/500'];
      this.logger.oopsError(error, 'Fallback navigation: Unknown error, assume /500');
    }

    // Redirect unauthorized users to login page for 403 errors
    if (routerCommands[0] === '/403') {
      const auth = this.injector.get(AuthService);
      const authNext = this.injector.get(AuthNextService);
      await firstValueFrom(auth.userData$);

      if (!auth.isAuthenticated()) {
        return await authNext.setNextAndSignIn();
      }

      if (!auth.isRegistered()) {
        return await authNext.setNextAndSignUp();
      }
    }

    try {
      await Promise.resolve();

      return await this.router.navigate(routerCommands, {skipLocationChange: true});
    } catch (err) {
      this.logger.oopsError(err, `Fallback navigation: Failed to router.navigate(${routerCommands.join('/')})`);

      return false;
    }
  }
}


@Injectable({providedIn: 'root'})
export class AppErrorHandler extends AbstractAppErrorHandler implements ErrorHandler {
  private get zone(): NgZone {
    return this.injector.get(NgZone);
  }

  private get location(): Location {
    return this.injector.get(Location);
  }

  handleError(error: unknown): void {
    const currentPath = AppStateService.lastStableLocation ?? this.location.path();
    const replacePath = currentPath.includes('/500') ? '/' : currentPath;

    if (this.router.url.match('/500')) {
      console.warn('Error on 500 page itself. Possible recursion prevented.');

      // Don't leave /500 in browser URL as it's confusing for the user
      // (especially on page refresh behavior)
      this.injector.get(Location).replaceState(replacePath);

      return;
    }

    // Try to display 500 error page; catch any errors in ngOnDestroy,
    // log to console and prevent infinite recursion.
    //
    // handleError will run outside angular zone, this is by design.
    // However, Router must be executed INSIDE the angular zone
    this.zone
      .run(() => this.fallbackNavigation(error).then(isNavigated => {
        if (isNavigated) {
          this.location.replaceState(replacePath);
        }

        return isNavigated;
      }))
      .catch((err: unknown) => this.logger.logError(err, 'Unhandled error: Fallback navigation failed to run'));
  }

  logErrorAsError(err: unknown, context?: string, ...optionalParams: any[]): void {
    if (this.knownErrors.handleIfKnown(err).isKnown) {
      return;
    }

    return this.logger.oopsError(err, context, ...optionalParams);
  }

  logErrorAsWarn(err: unknown, context?: string, ...optionalParams: any[]): void {
    if (this.knownErrors.handleIfKnown(err).isKnown) {
      return;
    }

    return this.logger.oopsWarn(err, context, ...optionalParams);
  }
}

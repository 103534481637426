import {envLocal} from './_local';
import {envConstOpts} from './common';

/**
 * Use this helper function to prevent minifier from wiping '<%...%>' string.
 */
function toBool(prop: `<%{{${string}}}%>`) {
  return !!prop;
}

export const environment: Environment = {
  production: true,
  experimental: toBool('<%{{ui.experimental}}%>'),
  debug: toBool('<%{{ui.debug}}%>'),
  logging: toBool('<%{{ui.logging}}%>'),
  sentryDsn: 'https://2e34eeccdd84488c921cc719c2eceba6@o388996.ingest.sentry.io/5226607',
  sentryEnv: '<%{{domain}}%>',
  uiUrl: 'https://<%{{domain}}%>',
  betaUrl: 'https://beta.<%{{domain}}%>',
  adminUrl: 'https://admin.<%{{domain}}%>',
  apiUrl: `https://<%{{domain}}%>/api`,
  oauthUrl: `https://<%{{domain}}%>/api/user/oauth`,
  staticUrl: 'https://static.<%{{domain}}%>',
  cdnUrl: 'https://cstatic.corite.com',
  money: {
    fallbackCurrency: 'USD',
    spacedSymbol: 'always',
  },
  socialProviders: {
    allowedPorts: [''],
    wcProjectId: '<%{{sn.wc}}%>',
    facebook: {
      // Facebook app. Owner: emil@corite.com
      clientId: '<%{{sn.facebook}}%>',
      apiVersion: 'v13.0',
      opts: new Set('<%{{sn.facebookOpts}}%>'.split(' ') as Array<'fans' | 'ig'>),
    },
    zenDesk: {
      key: '0624dcaa-9b00-4c93-9d48-313077b0fff8',
    },
  },
  payments: {
    enableVippsPayments: toBool('<%{{payments.enableVippsPayments}}%>'),
    enablePayPalPayout: toBool('<%{{payments.enablePayPalPayout}}%>'),
    enableStripePayout: toBool('<%{{payments.enableStripePayout}}%>'),
  },
  constOpts: envConstOpts,
  enableCampaignFixedGoal: false,
  ...envLocal,
};
